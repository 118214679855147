import Close from "components/icons/Close";
import { AnimatePresence, motion } from "framer-motion";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import {
  createContext,
  MutableRefObject,
  useContext,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react";

export type ShowModalSignature = (options: {
  content: JSX.Element | string;
  title: string;
  closeModalCallback?: Function;
}) => void;

const Modal = () => {
  let { closeModal, modal } = useContext(ModalContext);
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref, () => closeModal(true));
  const element = document.querySelector("#modal-root");
  if (!element) return null;
  return createPortal(
    <AnimatePresence>
      {modal.content && (
        <motion.div
          className={"c-modal-backdrop"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FocusTrap>
            <motion.div
              ref={ref as MutableRefObject<HTMLDivElement>}
              tabIndex={-1}
              aria-labelledby={modal.title}
              aria-modal="true"
              role="dialog"
              className={"c-modal"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onKeyDown={(e) => {
                switch (e.key) {
                  case "Escape":
                    closeModal(true);
                    break;
                }
              }}
            >
              <motion.div
                className={"c-modal-cont"}
                initial={{ y: 50 }}
                animate={{ y: 0 }}
              >
                <div className={"c-modal-header"}>
                  {modal.title !== "" && <h1>{modal.title}</h1>}
                  <button
                    className={
                      "c-modal-close btn btn-sm btn-white text-muted rounded-circle p-1 m-1 me-n1"
                    }
                    style={{ height: 30, width: 30 }}
                    onClick={() => closeModal(true)}
                  >
                    <Close />
                  </button>
                </div>
                <div className={"c-modal-body"}>{modal.content}</div>
              </motion.div>
            </motion.div>
          </FocusTrap>
        </motion.div>
      )}
    </AnimatePresence>,
    element
  );
};

type ModalState = {
  show: boolean;
  title: string;
  content: JSX.Element | string;
  closeModalCallback?: Function;
};
export const ModalContext = createContext<{
  modal: ModalState;
  showModal: ShowModalSignature;
  closeModal: (withCallback: boolean) => void;
}>({
  modal: {
    show: false,
    title: "",
    content: "",
  },
  showModal: () => {},
  closeModal: () => {},
});
function useModal() {
  const [modal, setModal] = useState<{
    show: boolean;
    title: string;
    content: JSX.Element | string;
    closeModalCallback?: Function;
  }>({ show: false, content: "", title: "" });

  const showModal = (options: {
    content: JSX.Element | string;
    title: string;
    closeModalCallback?: Function;
  }) => {
    const { content, closeModalCallback, title } = options;
    setModal({
      show: true,
      title: title,
      content: content,
      closeModalCallback,
    });
  };
  const closeModal = (withCallback: boolean) => {
    if (withCallback && modal.closeModalCallback) modal.closeModalCallback();
    setModal({ show: true, title: "", content: "" });
  };

  return { modal, showModal, closeModal };
}

export const ModalProvider = ({ children }) => {
  let { modal, showModal, closeModal } = useModal();
  return (
    <ModalContext.Provider value={{ modal, showModal, closeModal }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};
export default Modal;
