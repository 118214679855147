import { useLayoutEffect, useRef } from "react";
import { loginUrl } from "api";

export default function LoaTooLow(props: LoaTooLowProps) {
  const loginA = document.createElement("a");
  const titelRef = useRef<HTMLHeadingElement>(null);
  const { path } = props;
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div className="mt-5">
      <h1 tabIndex={-1} ref={titelRef}>
        Dit login opfylder ikke det nødvendige sikringsniveau.
      </h1>
      <span>
        <p></p>
        <button
          type="button"
          className="c-login-button btn btn-sm btn-primary text-white rounded-0"
          onClick={() => {
            loginA.setAttribute(
              "href",
              loginUrl + "?forceAuthn=true&returnUrl=" + path
            );
            loginA.click();
          }}
          aria-label="Log Ind"
          title="Log Ind"
        >
          Login med et højere sikringsniveau
        </button>
      </span>
    </div>
  );
}
export function FipLoaTooLow() {
  return <LoaTooLow path={"/kort"} />;
}
export function StednavnLoaTooLow() {
  return <LoaTooLow path={"/stednavn"} />;
}
interface LoaTooLowProps {
  path: string;
}
