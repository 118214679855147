export default function DrawPoint(props: { class?: string }) {
  const classNames = props.class ? `${props.class} bi` : "bi bi-draw-point";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      className={classNames}
    >
      <g
        id="Draw_point"
        data-name="Draw point"
        transform="translate(-0.261 0.065)"
      >
        <circle
          id="Ellipse_327"
          data-name="Ellipse 327"
          cx="8"
          cy="8"
          r="8"
          transform="translate(0.261 -0.065)"
          opacity="0.15"
        />
        <circle
          id="Ellipse_327-2"
          data-name="Ellipse 327"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(4.761 4.435)"
        />
      </g>
    </svg>
  );
}
