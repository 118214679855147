import { Link } from "react-router-dom";
import ChevronLeft from "components/icons/ChevronLeftSmall";
import { routes } from "routes/routesConfig";
export default function Tilbage() {
  return (
    <div className="l-section mt-4">
      <Link
        to={routes.stednavnIndberet.path}
        className="d-flex align-items-center mb-4"
      >
        <ChevronLeft class="me-2 text-dark" />
        Tilbage til type indberetning
      </Link>
    </div>
  );
}
