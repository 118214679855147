import {
  Hoeringssag,
  Ressource,
  RessourceRolleIdEnum,
  statusIdEnum,
} from "interfaces/IIndberetning";
import Section from "../../Section";
import { useContext } from "react";
import { Bruger } from "context/AuthProvider";
import { isIndberetningMine } from "routes/Fip/FipIndberetning";
import { ModalContext } from "../../Modal/Modal";
import PromptModal from "../PromptModal";
import { baseUrl } from "api";
import { getDownloadGeometryLink } from "routes/Fip/fipUtil";
// import AfslutIndberetning from "./AfslutIndberetningModal";
import AfslutIndberetningModal from "../AfslutIndberetningModal";
import { SagsbehandlingApi } from "api/interfaces";
import { putIndberetningDSSYS } from "api/stednavn";
import VideresendIndberetningModal from "../Fip/VideresendIndberetningModal";

interface IStednavnSagsbehandlerfunktionerProps {
  currentUser: Bruger;
  indberetning: Hoeringssag;
  setIndberetning: Function;
  cancelDrawingAndUploadFile: () => void;
  sagsbehandlingApi: SagsbehandlingApi;
}

export default function StednavnSagsbehandlerfunktioner(
  props: IStednavnSagsbehandlerfunktionerProps
) {
  const {
    currentUser,
    indberetning,
    setIndberetning,
    cancelDrawingAndUploadFile,
    sagsbehandlingApi,
  } = props;
  const { showModal } = useContext(ModalContext);
  const { statusId } = indberetning;
  const {
    afslutIndberetning,
    getIndberetning,
    tagIndberetning,
    tagIndberetningFraSagsbehandler,
  } = sagsbehandlingApi;
  const indberetningIsAfsluttet =
    statusId === statusIdEnum.afsluttet ? true : false;
  //const indberetningIsHoeringssag =
  //  statusId === statusIdEnum.iHoering ? true : false;
  return (
    <Section className="mt-5">
      <div className="card card--sdfe mt-3 mb-4">
        <div className="card-body">
          <span className="card-title">Sagsbehandlerfunktioner</span>
          <p>Foretag ændringer eller tilføjelser til indberetningen.</p>
          <ul className="list-arrows">
            <li>
              <button
                className="btn btn-link"
                disabled={
                  isIndberetningMine(currentUser, indberetning, false) ||
                  indberetningIsAfsluttet
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Tag indberetning",
                    content: (
                      <PromptModal
                        message={"Ønsker du at påtage dig indberetningen?"}
                        acceptPrompt={() => {
                          // Do stuff that makes you take the indberetning

                          const daværendeRessource =
                            indberetning.ressource?.find(
                              (x) =>
                                x.rolleId === RessourceRolleIdEnum.sagsbehandler
                            );
                          if (daværendeRessource) {
                            daværendeRessource.personId = currentUser.personId;
                            // Prøv kun at sende personId som payload
                            tagIndberetningFraSagsbehandler(
                              currentUser,
                              daværendeRessource
                            ).then((nyRessource: Ressource) => {
                              // Ny sagsbehandler
                              const index = indberetning.ressource.findIndex(
                                (x) =>
                                  x.rolleId ===
                                  RessourceRolleIdEnum.sagsbehandler
                              );
                              let nyIndberetning = indberetning;
                              nyIndberetning.ressource[index] = nyRessource;
                              setIndberetning(nyIndberetning);
                            });
                          } else {
                            tagIndberetning(
                              currentUser,
                              indberetning,
                              statusIdEnum.underBehandling
                            ).then((nyRessource: Ressource) => {
                              // Ny sagsbehandler
                              let nyIndberetning = indberetning;
                              nyIndberetning.ressource.push(nyRessource);
                              setIndberetning(nyIndberetning);
                            });
                          }
                        }}
                      />
                    ),
                  });
                }}
              >
                Tag indberetning
              </button>
            </li>
            {/* Overfør til DSSYS */}
            {indberetning.statusId === 10 && (
              <li>
                <button
                  className="btn btn-link"
                  disabled={
                    !isIndberetningMine(currentUser, indberetning, false) ||
                    indberetningIsAfsluttet
                  }
                  onClick={() => {
                    cancelDrawingAndUploadFile();
                    showModal({
                      title: "Aktiver sag i DSSYS",
                      content: (
                        <PromptModal
                          message={"Ønsker du at gøre sagen aktiv i DSSYS"}
                          acceptPrompt={() => {
                            putIndberetningDSSYS(
                              currentUser.personId,
                              statusIdEnum.klarmeldtTilDSSYS,
                              indberetning
                            ).then((nyIndberetning: Hoeringssag) => {
                              setIndberetning(nyIndberetning);
                            });
                          }}
                        />
                      ),
                    });
                  }}
                >
                  Synliggør sag overfor DSSYS
                </button>
              </li>
            )}
            {/* Annuler DSSYS */}
            {indberetning.statusId === 20 && (
              <li>
                <button
                  className="btn btn-link"
                  disabled={
                    !isIndberetningMine(currentUser, indberetning, false) ||
                    indberetningIsAfsluttet
                  }
                  onClick={() => {
                    cancelDrawingAndUploadFile();
                    showModal({
                      title: "Aktiver sag i Stednavne Indberetningsportal",
                      content: (
                        <PromptModal
                          message={
                            "Ønsker du at gøre sagen aktiv i Stednavne Indberetningsportal?"
                          }
                          acceptPrompt={() => {
                            putIndberetningDSSYS(
                              currentUser.personId,
                              statusIdEnum.oprettet,
                              indberetning
                            ).then((nyIndberetning: Hoeringssag) => {
                              setIndberetning(nyIndberetning);
                            });
                          }}
                        />
                      ),
                    });
                  }}
                >
                  Usynliggør sag overfor DSSYS
                </button>
              </li>
            )}
            {/* Er allerede overført til DSSYS */}
            {indberetning.statusId === 21 && (
              <li>
                <button
                  className="btn btn-link"
                  disabled={true}
                  onClick={() => {}}
                >
                  Synliggør sag overfor DSSYS (ER OVERFØRT)
                </button>
              </li>
            )}
            <li>
              {
                //TODO: Fix modal callback
              }
              <button
                className="btn btn-link"
                disabled={
                  !isIndberetningMine(currentUser, indberetning, false) ||
                  indberetningIsAfsluttet
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Videresend sag",
                    content: (
                      <VideresendIndberetningModal
                        message={"Videresend sag"}
                        indberetning={indberetning}
                        setIndberetning={setIndberetning}
                        sagsbehandlingApi={sagsbehandlingApi}
                      />
                    ),
                  });
                }}
              >
                Videresend indberetning
              </button>
            </li>
            <li>
              <a
                href={getDownloadGeometryLink(indberetning.id, 0)}
                target="_blank"
                rel="noreferrer"
                className="btn btn-link"
              >
                Download geometrier
              </a>
            </li>

            {/*
                <button
                  className="btn btn-link"
                  onClick={() => {
                    downloadFiles(currentUser, indberetning).then((data) => {
                      const downloadUrl = window.URL.createObjectURL(
                        new Blob([data])
                      );
                      const link = document.createElement("a");
                      link.href = downloadUrl;
                      link.setAttribute("download", "FIP.zip"); //any other extension
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    });
                  }}
                >
                  Download filer
                </button> */}
            <li>
              <a
                href={
                  baseUrl +
                  `download/zip/?sagId=${indberetning.id}&personId=${currentUser.personId}`
                }
                target="_blank"
                rel="noreferrer"
                className="btn btn-link"
              >
                Download filer
              </a>
            </li>
            <li>
              <button
                className="btn btn-link"
                disabled={
                  !isIndberetningMine(currentUser, indberetning, false) ||
                  indberetningIsAfsluttet
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Afslut indberetning",
                    content: (
                      <AfslutIndberetningModal
                        afslutIndberetning={(kommentar) => {
                          afslutIndberetning(
                            kommentar,
                            currentUser,
                            indberetning.id,
                            indberetning
                          ).then(() => {
                            // After vi afslutter indberetningen, er det nemmere at hente den ned igen.
                            getIndberetning(
                              indberetning.id,
                              currentUser.personId
                            ).then((nyIndberetning) => {
                              setIndberetning(nyIndberetning);
                            });
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                Afslut indberetning
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
}
