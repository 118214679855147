export default function LoadingSpinner() {
  return (
    <div>
      <div className="d-flex justify-content-center py-3 text-primary">
        <div className="spinner-border" role="progressbar" aria-valuetext="Henter information.." aria-busy="true">
        </div>
      </div>
    </div>
  );
}
