import { Route, Redirect } from "react-router-dom";
import { AuthState } from "context/AuthProvider";
import { authenticationMode, loginUrl } from "api";
import Forbidden from "./Forbidden/Forbidden";
import { AuthenticationMode } from "api/auth";
//import Forbidden from "./components/Error/Forbidden";

// A wrapper for <Route> that redirects to the home
// screen if you're not yet authenticated.

export default function PrivateRoute(props: PrivateRouteProps) {
  const { path, component, authState } = props;

  if (authState.currentUser === undefined) {
    return <div></div>;
  }
  // If user is not logged in
  if (
    authState.currentUser.personId === 0 &&
    (authState.currentUser.autentifikationId === null ||
      authState.currentUser.autentifikationId === "") &&
    authenticationMode === AuthenticationMode.forms
  ) {
    return (
      <Route
        exact
        path={path}
        render={({ location }) => {
          const { pathname, search } = location;
          window.location.href = `${loginUrl}?returnurl=${encodeURIComponent(
            pathname
          )}${encodeURIComponent(search)}`;
          return (
            <div style={{ marginTop: 100, marginLeft: 100 }}>
              Sender dig til SSO for autenticering ...
            </div>
          );
        }}
      />
    );
    // User is logged in, but has too low LOA
  } else if (
    authenticationMode === AuthenticationMode.forms &&
    authState.currentUser.loaTooLow === true
  ) {
    return (
      <Redirect
        to={{ pathname: "/kort/forlavloa", state: { referrer: path } }}
      />
    );
  }
  // If the user is logged in, but personId === 0 or state is already forbidden
  else if (
    authenticationMode === AuthenticationMode.none &&
    authState.currentUser.personId === 0
  ) {
    return <Route path={"/"} component={Forbidden} />;
  } else {
    // Else everything is good
    return <Route path={path} component={component} />;
  }
}

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
  component: any;
  authState: AuthState;
}
