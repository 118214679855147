import { sagsbehandlingApi } from "api/stednavn";
import Chat from "components/common/Indberetning/Chat/Chat";
import Detaljevisning from "components/common/Indberetning/Detaljevisning";
import IndberetningNav from "components/common/Indberetning/IndberetningNav";
import ChevronLeft from "components/icons/ChevronLeft";
import { finishedDrawingSource } from "config/interactions";
import { statusStore } from "config/StednavnConfig";
import { AuthContext } from "context/AuthProvider";
import { MapContext } from "context/MapProvider";
import {
  Hoeringssag,
  IndberetningState,
  RessourceRolleIdEnum,
} from "interfaces/IIndberetning";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useErrorHandler } from "react-error-boundary";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { NewFileState } from "routes/Fip/FipIndberet";
import { isIndberetningMine } from "routes/Fip/FipIndberetning";
import { getSagsaktFeaturesFromHoeringssag } from "routes/Fip/fipUtil";
import { paths } from "routes/routesConfig";
import { convertWktToFeature, zoomToFeature } from "util/ol";
import StednavnSagsbehandlerfunktioner from "components/common/Indberetning/Stednavn/StednavnSagsbehandlerfunktioner";
import { SessionTimerContext } from "context/SessionTimerProvider";

export default function StednavnIndberetning() {
  const { getIndberetning } = sagsbehandlingApi;
  let { id } = useParams<any>();
  const { authState } = useContext(AuthContext);
  const { resetSessionTimer } = useContext(SessionTimerContext);
  const { dispatchToMap, mapState } = useContext(MapContext);
  const { map } = mapState;
  const handleError = useErrorHandler();
  const { currentUser } = authState;
  const [indberetningState, setIndberetningState] = useState<IndberetningState>(
    {
      state: "LOADING",
      indberetning: undefined,
    }
  );
  const [newFile, setNewFile] = useState<NewFileState>({
    state: "IDLE",
    file: undefined,
  });
  const cancelDrawingAndUploadFile = () => {
    setNewFile({ state: "IDLE", file: undefined });
    dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
    dispatchToMap({
      type: "SET_DRAWTOOL",
      drawTool: undefined,
    });
  };
  const indberetningStateHandler = (indberetning: Hoeringssag) => {
    setIndberetningState({
      ...indberetningState,
      indberetning: indberetning,
    });
  };

  const hash = window.location.hash.replace("#", "");
  const [activePaneState, setActivePaneState] = useState<
    "sagsdetaljer" | "sagsbehandling" | "hoeringssag"
  >(
    hash === "sagsbehandling" || hash === "hoeringssag" ? hash : "sagsdetaljer"
  );

  useLayoutEffect(() => {
    window.location.hash = activePaneState;
  }, [activePaneState]);

  const [sagsbehandlingChatScrollPos, setSagsbehandlingChatScrollPos] =
    useState<number | undefined>(undefined);
  useEffect(() => {
    setIndberetningState({ indberetning: undefined, state: "LOADING" });
    const testApi = async () => {
      const response = await getIndberetning(id, currentUser.personId);
      resetSessionTimer();
      try {
        const features = getSagsaktFeaturesFromHoeringssag(response);

        features.forEach((feature) => {
          finishedDrawingSource.addFeature(feature);
        });

        setIndberetningState({
          indberetning: response,
          state: "IDLE",
        });
      } catch (error) {
        handleError(error);
      }
    };
    testApi();
  }, [
    setIndberetningState,
    id,
    currentUser,
    handleError,
    getIndberetning,
    resetSessionTimer,
  ]);

  const didZoomAlready = useRef(false);
  useEffect(() => {
    const { indberetning } = indberetningState;
    if (map && indberetning && indberetning.bbox && !didZoomAlready.current) {
      zoomToFeature(map, convertWktToFeature(indberetning.bbox));
      didZoomAlready.current = true;
    }
  }, [indberetningState, map, activePaneState]);

  useEffect(() => {
    const { indberetning } = indberetningState;
    if (map && indberetningState.state === "IDLE" && indberetning) {
      finishedDrawingSource.clear();
      const features = getSagsaktFeaturesFromHoeringssag(indberetning);
      features.forEach((feature) => {
        finishedDrawingSource.addFeature(feature);
      });
    }
    return () => {
      finishedDrawingSource.clear();
    };
  }, [map, indberetningState, dispatchToMap]);

  const titelRef = useRef<HTMLHeadingElement>(null);
  const loadingRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    if (indberetningState.state === "IDLE") titelRef.current?.focus();
    if (indberetningState.state === "LOADING") loadingRef.current?.focus();
  }, [indberetningState.state]);

  if (indberetningState.state === "LOADING")
    return (
      <div className="mt-5 d-flex text-center align-items-center justify-content-center">
        <div className="text-center">
          <div
            className="spinner-border text-primary"
            style={{ width: "1.5rem", height: "1.5rem" }}
            role="status"
          ></div>
        </div>
        <h1 tabIndex={-1} ref={loadingRef} className="h4 ms-2 mb-0">
          Henter sag ...
        </h1>
      </div>
    );
  if (indberetningState.state === "IDLE" && indberetningState.indberetning) {
    const { indberetning } = indberetningState;
    const {
      ressource,
      indberetningsmappe: indberetningsmappeListe,
      sagsbehandlermappe: sagsbehandlermappeListe,
    } = indberetning;

    const indberettere = ressource?.filter((r) => r.rolleId === 1);
    const indberetter = indberettere?.length > 0 ? indberettere[0] : undefined;

    const sagsbehandlere = ressource?.filter((r) => r.rolleId === 2);
    const sagsbehandler =
      sagsbehandlere?.length > 0 ? sagsbehandlere[0] : undefined;

    return (
      <div className="l-case mt-5">
        <div className="l-case__header">
          <Link
            to={paths.stednavnIndberetninger}
            className="d-flex align-items-center mb-4"
          >
            <ChevronLeft class="me-2 text-dark" />
            Tilbage til indberetninger
          </Link>
          <h1 tabIndex={-1} ref={titelRef}>
            {indberetning.emne}
          </h1>
          <div className="d-flex gap-1 mb-4">
            <span className="visually-hidden">Status: </span>
            {isIndberetningMine(currentUser, indberetning, false) && (
              <span className="badge bg-primary">
                {currentUser.rolleId === RessourceRolleIdEnum.indberetter
                  ? "Indberettet af dig"
                  : "Din indberetning"}
                <span className="visually-hidden">, </span>
              </span>
            )}
            <span
              className={`badge ${
                statusStore.find((s) => s.id === indberetning.statusId)
                  ?.className
              }`}
            >
              {statusStore.find((s) => s.id === indberetning.statusId)?.name}
            </span>
            {/*<span className="badge bg-horing">I høring</span>
            <span className="badge bg-warning">Afventer sagsbehandling</span> */}
          </div>
        </div>
        {/* Fane Nav */}
        <IndberetningNav
          indberetning={indberetning}
          setActivePaneState={setActivePaneState}
          activePaneState={activePaneState}
        />
        {/* Fane indhold */}
        <div className="tab-content" id="sags-tabContent">
          {/* Detaljevisning */}
          {activePaneState === "sagsdetaljer" &&
            indberetningsmappeListe &&
            indberetningsmappeListe.length > 0 && (
              <Detaljevisning
                indberetter={indberetter}
                sagsbehandler={sagsbehandler}
                indberetning={indberetning}
                zoomToIndberetning={() => {
                  if (map) {
                    zoomToFeature(map, convertWktToFeature(indberetning.bbox));
                  }
                }}
              />
            )}
          {/* Sagsbehandling / Chat mellem sagsbehandler og indberetter */}
          {activePaneState === "sagsbehandling" &&
            ((sagsbehandlermappeListe && sagsbehandlermappeListe.length > 0) ||
              (indberetningsmappeListe &&
                indberetningsmappeListe.length > 0)) && (
              /*<SagsbehandlingChat
                indberetning={indberetning}
                setIndberetning={indberetningStateHandler}
              />*/
              <>
                <Chat
                  currentUser={currentUser}
                  indberetning={indberetning}
                  setIndberetning={indberetningStateHandler}
                  isHoeringssagChat={false}
                  isStednavn={true}
                  newFile={newFile}
                  setNewFile={setNewFile}
                  cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                  scrollPos={sagsbehandlingChatScrollPos}
                  setScrollPos={setSagsbehandlingChatScrollPos}
                  sagsbehandlingApi={sagsbehandlingApi}
                />
              </>
            )}
          {sagsbehandlermappeListe &&
            sagsbehandlermappeListe.length > 0 &&
            ![
              RessourceRolleIdEnum.indberetter,
              RessourceRolleIdEnum.høringspart,
            ].includes(currentUser.rolleId) && (
              <StednavnSagsbehandlerfunktioner
                currentUser={currentUser}
                indberetning={indberetning}
                setIndberetning={indberetningStateHandler}
                cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                sagsbehandlingApi={sagsbehandlingApi}
              />
            )}
        </div>
      </div>
    );
  }
  return (
    <div className="mt-5">
      <h1>Noget gik galt ...</h1>
    </div>
  );
}
