export default function PlusSquare(props: { class?: string }) {
  const classNames = props.class
    ? `${props.class} bi`
    : "bi bi-plus-square-fill";
  return (
    <svg
      className={classNames}
      fill="currentColor"
      data-name="Group 8"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="16"
            height="16"
            fill="none"
          />
        </clipPath>
      </defs>
      <g data-name="Group 7" clipPath="url(#clip-path)">
        <path
          id="Path_10"
          data-name="Path 10"
          d="M14,0H2A2.006,2.006,0,0,0,0,2V14a2,2,0,0,0,2,2H14a1.993,1.993,0,0,0,2-2V2a2,2,0,0,0-2-2m0,14H2V2H14Z"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M4.5,9H7v2.5a1,1,0,0,0,2,0V9h2.5a1,1,0,0,0,1-1,1.013,1.013,0,0,0-1-1H9V4.5a1.013,1.013,0,0,0-1-1,1,1,0,0,0-1,1V7H4.5a1,1,0,0,0,0,2"
        />
      </g>
    </svg>
  );
}
