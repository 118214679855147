export const makeBreadCrumbs = (
  pathname: string,
  routes: {
    path: string;
    name: string;
  }[]
): {
  name: string;
  path: string;
}[] => {
  const pathnameParts = pathname.split("/");
  return routes
    .filter(({ path }) => {
      // path = path.replace(":id", "");
      const routePathParts = path.split("/");
      if (pathnameParts.length < routePathParts.length) return false;
      if (
        Number.isInteger(parseInt(pathnameParts[pathnameParts.length - 1])) &&
        routePathParts[routePathParts.length - 1] === ":id"
      )
        routePathParts[routePathParts.length - 1] =
          pathnameParts[pathnameParts.length - 1];
      const a = pathnameParts.slice(0, routePathParts.length).join();
      const b = routePathParts.join();
      return a === b;
      // console.log({ routePathParts, pathnameParts });
      // return pathname.includes(path);
    })
    .map((route) => {
      const { name, path } = route;
      return {
        name: path.includes(":id") // Visningsnavn skal være id dersom vi er inde på en enkel indberetning
          ? pathnameParts[pathnameParts.length - 1]
          : name,
        path,
      };
    });
};
