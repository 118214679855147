import { prioritetStore, domaeneStore } from "config/FipConfig";
import { AuthContext, Bruger } from "context/AuthProvider";
import { format } from "date-fns";
import {
  Hoeringssag,
  RedigerIndberetningDTO,
  Ressource,
} from "interfaces/IIndberetning";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { ModalContext } from "../../Modal/Modal";
import Select from "../../FormElements/Select";

export default function RedigerIndberetningModal(props: {
  message: string;
  indberetning: Hoeringssag;
  setIndberetning: Function;
  redigerIndberetning: (
    user: Bruger,
    indberetning: Hoeringssag
  ) => Promise<Ressource>;
}) {
  const firstFocusRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    firstFocusRef.current?.focus();
  }, []);
  const { closeModal } = useContext(ModalContext);
  const { authState } = useContext(AuthContext);
  const { currentUser } = authState;
  const { indberetning, setIndberetning, redigerIndberetning } = props;
  const [values, setValues] = useState({
    frist: indberetning.frist
      ? format(new Date(indberetning.frist), "yyyy-MM-dd")
      : "",
    prioritet: indberetning.prioritet,
    domaene: indberetning.domaeneTypeId,
  });

  function handleChange(e) {
    let value;
    switch (e.target.name) {
      case "frist":
        value = e.target.value;
        break;
      default:
        value = parseInt(e.target.value);
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  }
  const acceptPrompt = () => {
    // Do stuff that makes you take the indberetning

    const domaeneType = domaeneStore.find((x) => x.id === values.domaene)?.name;

    const frist = values.frist
      ? format(new Date(values.frist), "yyyy-MM-dd'T00:00:00'") // TODO: Is this correct?
      : null;
    if (domaeneType !== undefined) {
      let dto: RedigerIndberetningDTO = {
        domaeneTypeId: values.domaene,
        domaeneType: domaeneType,
        prioritet: prioritetStore[values.prioritet].id,
        frist: frist,
      };
      let nyIndberetning = { ...indberetning, ...dto };

      redigerIndberetning(currentUser, nyIndberetning).then((e) => {
        setIndberetning(e);
      });
    } else {
      console.error("fejl i rediger indberetning");
    }
  };
  return (
    <div>
      <div className="gap-2 d-flex flex-column">
        <div className="mt-0 gap-2">
          <label className={"form-label"}>Frist</label>
          <input
            ref={firstFocusRef}
            className={"form-select"}
            onChange={handleChange}
            type="date"
            id="start"
            name="frist"
            value={values.frist}
          />
        </div>
        <div className="mt-0 gap-2">
          <Select
            labelText="Prioritet"
            mandatory={false}
            name={"prioritet"}
            ariaLabel="Vælg objekttype"
            value={values.prioritet.toString()}
            onChange={handleChange}
            options={prioritetStore.map((e) => {
              return { label: e.name, value: e.id.toString() };
            })}
          />
        </div>
        <div className="mt-0 gap-2">
          <Select
            labelText="Domæne"
            mandatory={false}
            name={"domaene"}
            ariaLabel="Vælg objekttype"
            value={values.domaene.toString()}
            onChange={handleChange}
            options={domaeneStore.map((e) => {
              return { label: e.name, value: e.id.toString() };
            })}
          />
        </div>
      </div>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            acceptPrompt();
            closeModal(false);
          }}
        >
          OK
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
