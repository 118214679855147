import { FipLayer } from "interfaces/FipLayer";
// import ChevronUp from "components/icons/ChevronUp";
import MinusSquare from "components/icons/MinusSquare";
import PlusSquare from "components/icons/PlusSquare";
import { uniqueId } from "lodash";
import LayerToggleTemaselect from "./LayerToggleTemaselect";
import { useState } from "react";
import { FipLayerFolder, isFipLayer } from "interfaces/IIndberetning";
import HandPointing from "components/icons/HandPointing";

export default function LayerToggle(props: {
  baselayers: FipLayer[];
  layers: (FipLayer | FipLayerFolder)[];
  setLayerVisibility: (layer: FipLayer, visible: boolean) => void;
  setVisibleBaselayer: (layer: FipLayer) => void;
  viewState: {
    center: number[];
    resolution: number;
    zoom: number;
  };
}) {
  const {
    baselayers,
    layers,
    setLayerVisibility,
    setVisibleBaselayer,
    viewState,
  } = props;
  const [expanded, setExpanded] = useState(true);
  return (
    <div className={`c-layers shadow${expanded ? " is-active" : ""}`}>
      <button
        className="c-layers__head btn btn-primary border-0 text-dark bg-white w-100 py-2 shadow-sm"
        type="button"
        aria-expanded={expanded}
        aria-controls="layerCollapse"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <div className="row p-0 g-0">
          <div className="col d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="text-dark me-2 bi"
              viewBox="0 0 16 16"
            >
              <path d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882l7.5-4z"></path>
              <path d="m2.125 8.567-1.86.992a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882l-1.86-.992-5.17 2.756a1.5 1.5 0 0 1-1.41 0l-5.17-2.756z"></path>
            </svg>
            <span className="mb-0 fw-semibold pe-3">Lagvælger</span>
          </div>
          <div className="col-auto">
            <span className="rounded-circle bg-grey text-black d-flex align-items-center justify-content-center p-1">
              <svg
                style={{
                  transform: expanded ? undefined : "rotate(180deg)",
                }}
                version="1.1"
                className="bi"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 140 140"
                width="12"
                height="12"
                fill="currentColor"
              >
                <path d="M69.9,54.3L111,95.4c3.5,3.5,9.2,3.5,12.7,0s3.5-9.2,0-12.7L78.5,37.5l0,0l0,0c-4.7-4.7-12.4-4.7-17.1,0L16.2,82.7 c-1.8,1.8-2.6,4-2.6,6.3s0.9,4.6,2.6,6.3c3.5,3.5,9.2,3.5,12.7,0L69.9,54.3z"></path>
              </svg>
            </span>
          </div>
        </div>
      </button>
      {expanded && (
        <div id="layerCollapse" className="c-layers__content pt-3">
          <LayerToggleTemaselect />
          <div className="c-layers__section">
            <span className="c-layers__title">Baggrundslag</span>
            {baselayers.map((l) => {
              const className = l.loading
                ? "form-check is-loading"
                : "form-check";
              const id = uniqueId();
              return (
                <div key={l.title} className={className}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id={id}
                    checked={l.visible}
                    onChange={(e) => {
                      if (e.target.checked) setVisibleBaselayer(l);
                    }}
                  />
                  <label className="form-check-label" htmlFor={id}>
                    {l.title}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="c-layers__section">
            <span className="c-layers__title">Kortlag</span>
            {layers.map((l) => {
              if (isFipLayer(l)) {
                return (
                  <LayerItem
                    key={l.title}
                    layer={l}
                    currResolution={viewState.resolution}
                    setLayerVisibility={setLayerVisibility}
                  />
                );
              } else {
                return (
                  <FolderItem
                    key={l.title}
                    folder={l}
                    currResolution={viewState.resolution}
                    setLayerVisibility={setLayerVisibility}
                  />
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const FolderItem = (props: {
  folder: FipLayerFolder;
  currResolution: number;
  setLayerVisibility: (layer: FipLayer, visible: boolean) => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { folder, currResolution, setLayerVisibility } = props;
  const id = uniqueId("layer-folder");
  return (
    <div className="form-expand">
      <button
        onClick={() => {
          setExpanded(!expanded);
        }}
        className="btn btn-sm btn-primary bg-white text-dark fw-semibold w-100 py-0 my-1"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#${id}`}
        aria-expanded={expanded}
        aria-controls={id}
      >
        <span className="text-primary">
          {expanded && <MinusSquare />}
          {!expanded && <PlusSquare />}
        </span>
        {folder.title}
      </button>
      <div
        className="collapse rounded bg-light collapse-horizontal py-1 ps-2"
        id={id}
      >
        {folder.layers.map((layer) => {
          return (
            <LayerItem
              layer={layer}
              currResolution={currResolution}
              setLayerVisibility={setLayerVisibility}
              key={layer.title}
            />
          );
        })}
      </div>
    </div>
  );
};
const LayerItem = (props: {
  layer: FipLayer;
  currResolution: number;
  setLayerVisibility: (layer: FipLayer, visible: boolean) => void;
}) => {
  const { layer, currResolution, setLayerVisibility } = props;
  const id = uniqueId();
  const className = layer.loading ? "form-check is-loading" : "form-check";

  return (
    <div className={className} key={layer.title}>
      <input
        disabled={layer.maplayer.getMaxResolution() < currResolution}
        className="form-check-input"
        type="checkbox"
        value=""
        id={id}
        checked={layer.visible}
        onChange={(e) => {
          setLayerVisibility(layer, e.target.checked);
        }}
      />
      <label className="form-check-label" htmlFor={id}>
        {layer.title}
        {layer.featuresSelectable === true && (
          <HandPointing
            class="ms-2 text-muted"
            ariaLabel="Objekter kan udpeges på dette lag"
          />
        )}
      </label>
    </div>
  );
};
