export function getFilterValueUrlString(
  searchEmne: string | undefined,
  searchBeskrivelse: string | undefined
) {
  searchEmne = searchEmne ? `Emne_like_${searchEmne}` : "";
  searchBeskrivelse = searchBeskrivelse
    ? `Beskrivelse_like_${searchBeskrivelse}`
    : "";
  const response = [searchEmne, searchBeskrivelse]
    .filter((a) => a !== "")
    .join("|");
  return response;
}

export function makeExtentWKT(extent: number[]) {
  if (extent.length !== 4) return undefined;
  return `POLYGON((${extent[0]}  ${extent[1]}, ${extent[2]}  ${extent[1]}, ${extent[2]}  ${extent[3]}, ${extent[0]}  ${extent[3]}, ${extent[0]}  ${extent[1]}))`;
}
export function getFilterValues(
  extent: number[] | undefined,
  searchEmne: string | undefined,
  searchBeskrivelse: string | undefined
) {
  return extent
    ? makeExtentWKT(extent)
    : getFilterValueUrlString(searchEmne, searchBeskrivelse);
}
