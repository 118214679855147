import Select from "components/common/FormElements/Select";
import { Tema } from "interfaces/IIndberetning";
import { ForwardedRef, forwardRef } from "react";
const TemaSelect = forwardRef(
  (
    props: {
      isInvalid: boolean;
      tema: Tema | undefined;
      setTema: (id: string) => void;
      options: {
        value: string | number;
        label: string;
        disabled?: boolean | undefined;
      }[];
    },
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    const { isInvalid, tema, setTema, options } = props;
    return (
      <Select
        labelText="Vælg korttema"
        mandatory={true}
        ref={ref}
        className={isInvalid ? "form-control is-invalid" : "form-control"}
        ariaLabel="Vælg korttema"
        onChange={(e) => {
          setTema(e.target.value);
        }}
        value={tema?.id.toString() ?? ""}
        options={options}
      />
    );
  }
);
export default TemaSelect;
