export default function DrawSelect(props: { class?: string }) {
  const classNames = props.class ? `${props.class} bi` : "bi bi-draw-select";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.266"
      height="15.264"
      viewBox="0 0 15.266 15.264"
      fill="currentColor"
      stroke="currentColor"
      className={classNames}
    >
      <g
        id="Draw_select"
        data-name="Draw select"
        transform="translate(-2.113 -3.513)"
      >
        <path
          id="Path_19"
          data-name="Path 19"
          d="M10.269,10.886l5.425,12.277a.483.483,0,0,0,.908-.072l1.213-4.608a.966.966,0,0,1,.687-.688l4.608-1.213a.483.483,0,0,0,.073-.908L10.906,10.251a.483.483,0,0,0-.637.635Z"
          transform="translate(-7.094 -5.675)"
          fill="currentColor"
          opacity="0.15"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M10.269,10.886l5.425,12.277a.483.483,0,0,0,.908-.072l1.213-4.608a.966.966,0,0,1,.687-.688l4.608-1.213a.483.483,0,0,0,.073-.908L10.906,10.251a.483.483,0,0,0-.637.635Z"
          transform="translate(-7.094 -5.675)"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
