import { useHistory } from "react-router";
import ChevronRight from "components/icons/ChevronRight";
import { Indberetning } from "interfaces/IIndberetning";
import { getDomeneTypeById, statusStore } from "config/FipConfig";
import { formatDashedDate, formatDottedDate } from "util/date";
import { bboxLayer } from "config/layers";
import { addFeatureToSource } from "util/ol";
import { useEffect } from "react";

interface IIndberetningListItemProps {
  indberetning: Indberetning;
  showDomain: boolean;
}
export default function IndberetningListItem(
  props: IIndberetningListItemProps
) {
  const { indberetning, showDomain } = props;
  const bboxSource = bboxLayer.getSource();
  const history = useHistory();
  useEffect(() => {
    return () => {
      bboxSource.clear();
    };
  }, [bboxSource]);
  return (
    <button
      type="button"
      className="list-group-item list-group-item-action py-2 px-2 lh-tight d-flex align-items-center"
      onClick={() => history.push(`indberetninger/${indberetning.id}`)}
      onMouseEnter={() => {
        if (indberetning.bbox)
          addFeatureToSource(indberetning.bbox, bboxSource);
      }}
      onMouseLeave={() => {
        bboxSource.clear();
      }}
    >
      <div className="col mb-1">
        <div className="d-flex w-100 align-items-center justify-content-between">
          <strong className="mb-1">{indberetning.emne}</strong>
          <span className="visually-hidden">, </span>
        </div>
        <div className="small">
          {/* typeGeometri */}
          {/* <strong>{indberetning.bbox?.getGeometry().getType()}</strong><span className="px-1" role="separator">•</span><span className="visually-hidden">, </span>*/}
          <div className="d-block d-md-inline" style={{ whiteSpace: "nowrap" }}>
            <strong className="fw-semibold">ID:</strong> {indberetning.id}
          </div>
          <span className="px-2 d-none d-md-inline" role="separator">
            •
          </span>
          <span className="visually-hidden">, </span>
          {/* Domæne - Vises kun for sagsbehandlere */}
          {showDomain && (
            <>
              <div
                className="d-block d-md-inline"
                style={{ whiteSpace: "nowrap" }}
              >
                <strong className="fw-semibold">Domæne: </strong>
                {getDomeneTypeById(indberetning.domaeneTypeId).name}
              </div>
              <span className="px-2 d-none d-md-inline" role="separator">
                •
              </span>
              <span className="visually-hidden">, </span>
            </>
          )}
          {/* dato */}
          <div className="d-block d-md-inline" style={{ whiteSpace: "nowrap" }}>
            <strong className="fw-semibold">Dato: </strong>
            <span aria-hidden="true">
              {formatDottedDate(indberetning.oprettetTs.toISOString())}
            </span>
            <span className="visually-hidden">
              {formatDashedDate(indberetning.oprettetTs.toISOString())}
            </span>
          </div>
          <span className="visually-hidden">, </span>
        </div>
      </div>
      <div className="col-auto ms-auto">
        <span className="visually-hidden">Status: </span>
        <span
          className={`badge ${
            statusStore.find((s) => s.id === indberetning.statusId)?.className
          } me-3`}
        >
          {statusStore.find((s) => s.id === indberetning.statusId)?.name}
        </span>
        <span className="visually-hidden">, </span>
        <ChevronRight ariaLabel="Gå til indberetning"/>
      </div>
    </button>
  );
}
