import ValidationErrorMessage from "components/common/FormElements/ValidationErrorMessage";
import { IndlaegTypeIdEnum } from "interfaces/IIndberetning";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { ModalContext } from "../../Modal/Modal";

const beskedTypeMap = (type: IndlaegTypeIdEnum) => {
  let map = {
    [IndlaegTypeIdEnum.intern]: "intern note",
    [IndlaegTypeIdEnum.overdragelseskommentar]: "overdragelseskommentar",
    [IndlaegTypeIdEnum.tilIndberetter]: "Til sagsbehandler", // Denne her er mærkelig men rigtig
    [IndlaegTypeIdEnum.fraIndberetter]: "",
    [IndlaegTypeIdEnum.afslutningsKommentar]: "afslutningskommentar",
    [IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig]: "Besked til høringspart",
    [IndlaegTypeIdEnum.beskedTiLHoeringsAnsvarlig]:
      "Besked til høringsansvarlig",
    [IndlaegTypeIdEnum.beskedFraHoeringspart]: "Besked til høringsansvarlig",
    [IndlaegTypeIdEnum.beskedTilHoeringspart]: "Besked til høringspart",
    [IndlaegTypeIdEnum.fraSagsbehandler]: "Til indberetter",
    [IndlaegTypeIdEnum.tilSagsbehandler]: "Besked til sagsbehandler",
  };
  return map[type];
};

export default function SkrivBeskedModal(props: {
  skrivBesked: (beskrivelse: string, type: IndlaegTypeIdEnum) => void;
  type: IndlaegTypeIdEnum;
}) {
  const firstFocusRef = useRef<HTMLTextAreaElement>(null);
  useLayoutEffect(() => {
    firstFocusRef.current?.focus();
  }, []);
  const { closeModal } = useContext(ModalContext);
  const { type, skrivBesked } = props;
  const [besked, setBesked] = useState({
    touched: false,
    value: "",
  });
  const [notifyBesked, setNotifyBesked] = useState(false);
  const showValidationError = besked.touched && besked.value === "";
  return (
    <div>
      {/* <h1>Skriv {type === BeskedType.besked ? "besked" : "intern note"}</h1> */}
      <label htmlFor="beskrivelse" className="form-label">
        {beskedTypeMap(type)}
        <span
          className="text-danger ms-1"
          aria-label="(Skal udfyldes)"
          title="Skal udfyldes"
        >
          <svg
            className="mt-n1"
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            aria-hidden="true"
            focusable="false"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
          </svg>
        </span>
      </label>
      <textarea
        aria-required="true"
        ref={firstFocusRef}
        className={
          showValidationError ? "form-control is-invalid" : "form-control"
        }
        id="beskrivelse"
        value={besked.value}
        onChange={(e) => setBesked({ ...besked, value: e.target.value })}
      ></textarea>
      <ValidationErrorMessage
        message={"Skriv ind en besked"}
        show={showValidationError}
        notify={notifyBesked}
        resetNotify={() => setNotifyBesked(false)}
      />
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (besked.value) {
              skrivBesked(besked.value, type);
              closeModal(false);
            } else {
              setBesked({ ...besked, touched: true });
              setNotifyBesked(true);
            }
          }}
        >
          Gem
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
