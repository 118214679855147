import {
  getGeoDkVectorFeaturesForExtent,
  getStednavnFeaturesForExtent,
} from "api/datafordeleren";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { WMTS, Vector as VectorSource, ImageWMS } from "ol/source";
import { Stroke, Style, Circle as CircleStyle, Fill, Text } from "ol/style";
import { extent, tileGrid } from "./map";
import { bbox } from "ol/loadingstrategy";
import ImageLayer from "ol/layer/Image";
import Polygon from "ol/geom/Polygon";
import Point from "ol/geom/Point";
import { getFilterValues } from "api/util";

export const bboxLayer = new VectorLayer({
  properties: { title: "indberetninger bbox" },
  zIndex: 99,
  source: new VectorSource(),
  style: function (feature, res) {
    return new Style({
      geometry: function (feature) {
        if (feature.getGeometry().getType() === "Polygon") {
          const poly = feature.getGeometry() as Polygon;
          const e = poly.getExtent();
          const pixWidth = (e[2] - e[0]) / res;
          const pixHeight = (e[3] - e[1]) / res;
          if (pixHeight < 30 || pixWidth < 30)
            return new Point([(e[2] + e[0]) / 2, (e[3] + e[1]) / 2]);
        }
        return feature.getGeometry();
      },
      fill: new Fill({
        color: "#ff8d2740",
      }),
      stroke: new Stroke({
        color: "#ff8d27",
        width: 4,
      }),
      image: new CircleStyle({
        radius: 30,
        fill: new Fill({
          color: "#ff8d2740",
        }),
        stroke: new Stroke({
          color: "#ff8d27",
          width: 4,
        }),
      }),
    });
  },
});

export const geodanmark = new ImageLayer({
  properties: { title: "GeoDanmark" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://api.dataforsyningen.dk/forvaltning2?",
    params: {
      LAYERS: ["basis_kort", "Vejnavne_basiskort", "Stednavne_basiskort"],
      STYLES: "default",
      TOKEN: process.env.REACT_APP_KFTOKEN,
      VERSION: "1.1.1",
    },
  }),
});

export const topoSkærmkort = new TileLayer({
  properties: { title: "Skærmkort" },
  visible: false,
  extent,
  zIndex: 0,
  maxResolution: 1640,
  source: new WMTS({
    url: `https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort_daempet/1.0.0/wmts?username=${process.env.REACT_APP_DAFUSERNAME}&password=${process.env.REACT_APP_DAFPASSWORD}`,
    tileGrid,
    layer: "topo_skaermkort_daempet",
    matrixSet: "View1",
    style: "default",
  }),
});

export const topoSkærmkortGraa = new TileLayer({
  properties: { title: "Skærmkort gråtone" },
  visible: false,
  extent,
  zIndex: 0,
  maxResolution: 1640,
  source: new WMTS({
    url: `https://services.datafordeler.dk/Dkskaermkort/topo_skaermkort_graa/1.0.0/wmts?username=${process.env.REACT_APP_DAFUSERNAME}&password=${process.env.REACT_APP_DAFPASSWORD}`,
    tileGrid,
    layer: "topo_skaermkort_graa",
    matrixSet: "View1",
    style: "default",
  }),
});

export const kommunegrænser = new ImageLayer({
  properties: { title: "Kommunegrænser" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/DAGIM/dagi/1.0.0/WMS",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["Kommuneinddeling"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "TRUE",
    },
  }),
});
export const adresser = new ImageLayer({
  properties: { title: "Husnumre" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://kort.aws.dk/geoserver/aws4/wms",
    params: {
      LAYERS: ["adgangsadresser"],
      VERSION: "1.3.0",
      TRANSPARENT: "TRUE",
    },
  }),
});

export const matrikler = new ImageLayer({
  properties: { title: "Matrikler" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["Jordstykke_Gaeldende"],
      STYLES: "Jordstykke_Gaeldende_Roed",
      VERSION: "1.3.0",
      TRANSPARENT: "TRUE",
    },
  }),
});

export const stednavneWMS = new ImageLayer({
  properties: { title: "Stednavne" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/STEDNAVN/Danske_Stednavne/1.0.0/WMS",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["danske_stednavne"],
      // STYLES: "Jordstykke_Gaeldende_Roed",
      VERSION: "1.3.0",
      TRANSPARENT: "TRUE",
    },
  }),
});

export const fireCmHistorisk = new ImageLayer({
  properties: { title: "4cm historisk" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/DKTopografiskeKortvaerk/topo4cm_1980_2001/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["dtk_4cm_1980_2001"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "FALSE",
      FORMAT: "image/jpeg",
    },
  }),
});

export const højeMålestoksblade = new ImageLayer({
  properties: { title: "Høje målebordsblade" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/HoejeMaalebordsblade/topo20_hoeje_maalebordsblade/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["dtk_hoeje_maalebordsblade"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "FALSE",
      FORMAT: "image/jpeg",
    },
  }),
});

export const laveMålestoksblade = new ImageLayer({
  properties: { title: "Lave målebordsblade" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/LaveMaalebordsblade/topo20_lave_maalebordsblade/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["dtk_lave_maalebordsblade"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "FALSE",
      FORMAT: "image/jpeg",
    },
  }),
});

export const kort25 = new ImageLayer({
  properties: { title: "Kort25" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/DKtopokort/dtk_25/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["dtk_25"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "FALSE",
      FORMAT: "image/jpeg",
    },
  }),
});

export const kort50 = new ImageLayer({
  properties: { title: "Kort50" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/DKtopokort/dtk_50/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["dtk_50"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "FALSE",
      FORMAT: "image/jpeg",
    },
  }),
});

export const kort100 = new ImageLayer({
  properties: { title: "Kort100" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/DKtopokort/dtk_100/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["dtk_100"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "FALSE",
      FORMAT: "image/jpeg",
    },
  }),
});

export const kort200 = new ImageLayer({
  properties: { title: "Kort200 - Færdselskort" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/DKtopokort/dtk_200/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: ["dtk_200"],
      STYLES: "default",
      VERSION: "1.3.0",
      TRANSPARENT: "FALSE",
      FORMAT: "image/jpeg",
    },
  }),
});

export const udvalgteGeoDKTemaer = new ImageLayer({
  properties: { title: "Udvalgte GeoDanmark-temaer" },
  visible: false,
  extent,
  zIndex: 0,
  source: new ImageWMS({
    url: "https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark_60_NOHIST/1.0.0/wms",
    params: {
      USERNAME: process.env.REACT_APP_DAFUSERNAME,
      PASSWORD: process.env.REACT_APP_DAFPASSWORD,
      LAYERS: [
        "SKOV",
        "VEJMIDTE",
        "JERNBANE",
        "BYGNING",
        "AFVANDINGSGROEFT",
        "BADE_BAADEBRO",
        "HAVN",
        "KYST",
        "SOE",
        "VAADOMRAADE",
        "VANDLOEBSMIDTE",
      ],
      STYLES: "",
      VERSION: "1.3.0",
      TRANSPARENT: "TRUE",
      FORMAT: "image/png",
    },
  }),
});

export const ortofoto = new TileLayer({
  properties: { title: "Ortofoto" },
  visible: false,
  extent,
  zIndex: 0,
  maxResolution: 1640,
  source: new WMTS({
    url: `https://services.datafordeler.dk/GeoDanmarkOrto/orto_foraar_wmts/1.0.0/wmts?username=${process.env.REACT_APP_DAFUSERNAME}&password=${process.env.REACT_APP_DAFPASSWORD}`,
    tileGrid,
    format: "image/jpeg",
    layer: "orto_foraar_wmts",
    matrixSet: "KortforsyningTilingDK",
    style: "default",
  }),
});

export const dhmOverflade = new TileLayer({
  properties: { title: "DHM overflade" },
  visible: false,
  extent,
  zIndex: 0,
  maxResolution: 1640,
  source: new WMTS({
    url: `https://services.datafordeler.dk/DHMSkyggekort/dhm_overflade_skyggekort/1.0.0/wmts?username=${process.env.REACT_APP_DAFUSERNAME}&password=${process.env.REACT_APP_DAFPASSWORD}`,
    tileGrid,
    format: "image/jpeg",
    layer: "dhm_overflade_skyggekort",
    matrixSet: "View1",
    style: "default",
  }),
});

export const dhmTerræn = new TileLayer({
  properties: { title: "DHM terræn" },
  visible: false,
  extent,
  zIndex: 0,
  maxResolution: 1640,
  source: new WMTS({
    url: `https://services.datafordeler.dk/DHMSkyggekort/dhm_terraen_skyggekort/1.0.0/wmts?username=${process.env.REACT_APP_DAFUSERNAME}&password=${process.env.REACT_APP_DAFPASSWORD}`,
    tileGrid,
    format: "image/jpeg",
    layer: "dhm_terraen_skyggekort",
    matrixSet: "View1",
    style: "default",
  }),
});

export const updateFipSagWmsParams = (options: {
  personId: number;
  rolleId: number;
  filterId: number;
  searchEmne?: string;
  searchBeskrivelse?: string;
  extent?: number[];
}) => {
  const { filterId, personId, rolleId, searchBeskrivelse, searchEmne, extent } =
    options;
  const filterValues = extent
    ? ""
    : getFilterValues(extent, searchEmne, searchBeskrivelse);
  fipSagWmsSource.updateParams({
    LAYERS: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":Sag2",
    STYLES: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":fip2prod",
    VIEWPARAMS: `applikationId:1;domaeneTypeIds:;filterId:${
      filterId === 6 ? 4 : filterId
    };personId:${personId};rolleId:${rolleId};filterValues:${filterValues}`,
  });
};
const fipSagWmsSource = new ImageWMS({
  url: `${process.env.REACT_APP_API_BASEURL}proxy.ashx`,
  params: {
    LAYERS: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":Sag2",
    STYLES: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":fip2prod",
    VIEWPARAMS:
      "applikationId:1;domaeneTypeIds:;filterId:0;personId:0;rolleId:0;filterValues:",
  },
});

export const fipSagWMSLayer = new ImageLayer({
  properties: { title: "Indberetninger" },
  source: fipSagWmsSource,
});

export const updateStednavnSagWmsParams = (options: {
  personId: number;
  rolleId: number;
  filterId: number;
  searchEmne?: string;
  searchBeskrivelse?: string;
  extent?: number[];
}) => {
  const { filterId, personId, rolleId, searchBeskrivelse, searchEmne, extent } =
    options;
  const filterValues = extent
    ? ""
    : getFilterValues(extent, searchEmne, searchBeskrivelse);
  stednavnSagWmsSource.updateParams({
    LAYERS: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":Sag2",
    STYLES: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":fip2prod",
    VIEWPARAMS: `applikationId:4;domaeneTypeIds:;filterId:${
      filterId === 6 ? 4 : filterId
    };personId:${personId};rolleId:${rolleId};filterValues:${filterValues}`,
  });
};

export const stednavnSagWmsSource = new ImageWMS({
  url: `${process.env.REACT_APP_API_BASEURL}proxy.ashx`,
  params: {
    LAYERS: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":Sag2",
    STYLES: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":fip2prod",
    VIEWPARAMS:
      "applikationId:4;domaeneTypeIds:5;filterId:3;personId:33;rolleId:1;filterValues:",
  },
});
export const stednavnSagWMSLayer = new ImageLayer({
  properties: { title: "Indberettede stednavne" },
  source: stednavnSagWmsSource,
});

export const bygningerWFSSource = new VectorSource({
  strategy: bbox,
  loader: function (extent, resolution, projection, success, failure) {
    getGeoDkVectorFeaturesForExtent(extent, "Bygning")
      .then(function (features) {
        bygningerWFSSource.addFeatures(features);
        if (success) {
          success(features);
        }
      })
      .catch(function (err) {
        if (failure) {
          failure();
        }
      });
  },
});
export const bygningerWFSLayer = new VectorLayer({
  properties: { title: "Bygninger" },
  visible: true,
  source: bygningerWFSSource,
  maxResolution: 1.5,
  style: new Style({
    stroke: new Stroke({
      color: "hsla(10, 70%, 70%, 1)",
      width: 2,
    }),
    fill: new Fill({
      color: `hsla(10, 70%, 70%, 0.4)`,
    }),
    image: new CircleStyle({
      stroke: new Stroke({
        color: "hsla(10, 70%, 70%, 1)",
        width: 2,
      }),
      fill: new Fill({
        color: "hsla(10, 70%, 70%, 0.4)",
      }),
      radius: 5,
    }),
  }),
});

export const vejogstimidteWFSSource = new VectorSource({
  strategy: bbox,
  loader: function (extent, resolution, projection, success, failure) {
    getGeoDkVectorFeaturesForExtent(extent, "Vejmidte")
      .then(function (features) {
        vejogstimidteWFSSource.addFeatures(features);
        if (success) {
          success(features);
        }
      })
      .catch(function (err) {
        if (failure) {
          failure();
        }
      });
  },
});
export const vejogstimidteWFSLayer = new VectorLayer({
  properties: { title: "Vej- og stimidter" },
  visible: true,
  source: vejogstimidteWFSSource,
  maxResolution: 1.5,
  style: new Style({
    stroke: new Stroke({
      color: "hsla(110, 70%, 70%, 1)",
      width: 3,
    }),
  }),
});

export const vejkantWFSSource = new VectorSource({
  strategy: bbox,
  loader: function (extent, resolution, projection, success, failure) {
    getGeoDkVectorFeaturesForExtent(extent, "Vejkant")
      .then(function (features) {
        vejkantWFSSource.addFeatures(features);
        if (success) {
          success(features);
        }
      })
      .catch(function (err) {
        if (failure) {
          failure();
        }
      });
  },
});
export const vejkantWFSLayer = new VectorLayer({
  properties: { title: "Vejkant" },
  visible: true,
  source: vejkantWFSSource,
  maxResolution: 1.5,
  style: new Style({
    stroke: new Stroke({
      color: "hsla(140, 70%, 70%, 1)",
      width: 3,
    }),
  }),
});

export const søWFSSource = new VectorSource({
  strategy: bbox,
  loader: function (extent, resolution, projection, success, failure) {
    getGeoDkVectorFeaturesForExtent(extent, "Soe")
      .then(function (features) {
        søWFSSource.addFeatures(features);
        if (success) {
          success(features);
        }
      })
      .catch(function (err) {
        if (failure) {
          failure();
        }
      });
  },
});
export const søWFSLayer = new VectorLayer({
  properties: { title: "Sø" },
  visible: true,
  source: søWFSSource,
  maxResolution: 1.5,
  style: new Style({
    stroke: new Stroke({
      color: "hsla(190, 70%, 70%, 1)",
      width: 2,
    }),
    fill: new Fill({
      color: `hsla(190, 70%, 70%, 0.4)`,
    }),
  }),
});
export const vandløbWFSSource = new VectorSource({
  strategy: bbox,
  loader: function (extent, resolution, projection, success, failure) {
    getGeoDkVectorFeaturesForExtent(extent, "Vandloebsmidte")
      .then(function (features) {
        vandløbWFSSource.addFeatures(features);
        if (success) {
          success(features);
        }
      })
      .catch(function (err) {
        if (failure) {
          failure();
        }
      });
  },
});
export const vandløbWFSLayer = new VectorLayer({
  properties: { title: "Vandløb" },
  visible: true,
  source: vandløbWFSSource,
  maxResolution: 1.5,
  style: new Style({
    stroke: new Stroke({
      color: "hsla(190, 70%, 70%, 1)",
      width: 2,
    }),
    fill: new Fill({
      color: `hsla(190, 70%, 70%, 0.4)`,
    }),
  }),
});

export const selectableLayers = [
  bygningerWFSLayer,
  vejogstimidteWFSLayer,
  vejkantWFSLayer,
  søWFSLayer,
  vandløbWFSLayer,
];

export const stednavneWFSSource = new VectorSource({
  strategy: bbox,
  loader: function (extent, resolution, projection, success, failure) {
    getStednavnFeaturesForExtent(extent)
      .then(function (features) {
        stednavneWFSSource.addFeatures(features);
        if (success) {
          success(features);
        }
      })
      .catch(function (err) {
        if (failure) {
          failure();
        }
      });
  },
});
export const stednavneWFSLayer = new VectorLayer({
  properties: { title: "Stednavne WFS" },
  visible: true,
  source: stednavneWFSSource,
  maxResolution: 3.2,
  style: function (feature) {
    return new Style({
      stroke: new Stroke({
        color: "hsla(216, 89%, 50%, 1)",
        width: 2,
      }),
      fill: new Fill({
        color: `hsla(216, 89%, 50%, 0.2)`,
      }),
      image: new CircleStyle({
        stroke: new Stroke({
          color: "hsla(216, 89%, 50%, 1)",
          width: 2,
        }),
        fill: new Fill({
          color: "hsla(216, 89%, 50%, 0.4)",
        }),
        radius: 4,
      }),
      text: new Text({
        text: feature.get("navn_1.skrivemaade"),
        font: "500 14px sans-serif",
        stroke: new Stroke({ color: "#FFFFFFAA", width: 3 }),
        overflow: true,
      }),
    });
  },
});
