import { createContext, useContext, useEffect } from "react";
import { MapAction, MapContext } from "./MapProvider";
import { tema } from "../config/FipConfig";
import {
  baselayers as stednavnBaselayers,
  layers as stednavnLayers,
} from "config/StednavnConfig";
import { FipLayer } from "interfaces/FipLayer";
import BaseTileLayer from "ol/layer/BaseTile";
import { ImageWMS, TileImage } from "ol/source";
import ImageLayer from "ol/layer/Image";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import { useRouter } from "hooks/useRouter";
import { isFipLayer } from "interfaces/IIndberetning";

export const ConfigContext = createContext(undefined);

const ConfigProvider = ({ children }) => {
  const { dispatchToMap } = useContext(MapContext);
  const { isFipGenerel, isStednavn } = useRouter();
  useEffect(() => {
    if (isFipGenerel) {
      dispatchToMap({
        type: "SET_LAYERS",
        baselayers: tema[0].baseLayers,
        layers: tema[0].layers,
      });
      tema.forEach((t) => {
        const allTemaLayers = [...t.baseLayers, ...t.layers];
        allTemaLayers.forEach((l) => {
          if (isFipLayer(l)) {
            setLoadingCallback(l, dispatchToMap);
          } else {
            l.layers.forEach((folderLayer) => {
              setLoadingCallback(folderLayer, dispatchToMap);
            });
          }
        });
      });
    } else if (isStednavn) {
      dispatchToMap({
        type: "SET_LAYERS",
        baselayers: stednavnBaselayers,
        layers: stednavnLayers,
      });
      stednavnBaselayers.forEach((l) => {
        setLoadingCallback(l, dispatchToMap);
      });
      stednavnLayers.forEach((l) => {
        setLoadingCallback(l, dispatchToMap);
      });
    }
  }, [dispatchToMap, isFipGenerel, isStednavn]);

  return (
    <ConfigContext.Provider value={undefined}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;

/**
 * Setup loading callbacks for layers shown in layer selector
 * @param l
 * @param dispatchToMap
 */
function setLoadingCallback(
  l: FipLayer,
  dispatchToMap: React.Dispatch<MapAction>
) {
  const tilelayer = l.maplayer as BaseTileLayer<TileImage>;
  if (tilelayer) {
    const progress = { loading: 0, loaded: 0 };
    tilelayer.getSource().on("tileloadstart", (e) => {
      progress.loading += 1;
      if (progress.loading > progress.loaded) {
        dispatchToMap({
          type: "SET_LAYER_LOADING",
          layer: l,
          loading: true,
        });
      }
    });
    tilelayer.getSource().on("tileloadend", (e) => {
      progress.loaded += 1;
      if (progress.loaded === progress.loading) {
        dispatchToMap({
          type: "SET_LAYER_LOADING",
          layer: l,
          loading: false,
        });
        progress.loaded = 0;
        progress.loading = 0;
      }
    });
    tilelayer.getSource().on("tileloaderror", (e) => {
      progress.loaded += 1;
      if (progress.loaded === progress.loading) {
        dispatchToMap({
          type: "SET_LAYER_LOADING",
          layer: l,
          loading: false,
        });
        progress.loaded = 0;
        progress.loading = 0;
      }
    });
  }
  const imageLayer = l.maplayer as ImageLayer<ImageWMS>;
  if (imageLayer) {
    imageLayer.getSource().on("imageloadstart", (e) => {
      dispatchToMap({
        type: "SET_LAYER_LOADING",
        layer: l,
        loading: true,
      });
    });
    imageLayer.getSource().on("imageloadend", (e) => {
      dispatchToMap({
        type: "SET_LAYER_LOADING",
        layer: l,
        loading: false,
      });
    });
    imageLayer.getSource().on("imageloaderror", (e) => {
      dispatchToMap({
        type: "SET_LAYER_LOADING",
        layer: l,
        loading: false,
      });
    });
  }

  const vectorLayer = l.maplayer as VectorLayer<VectorSource<Geometry>>;
  if (vectorLayer) {
    vectorLayer.getSource().on("featuresloadstart", () => {
      dispatchToMap({
        type: "SET_LAYER_LOADING",
        layer: l,
        loading: true,
      });
    });
    vectorLayer.getSource().on("featuresloadend", () => {
      dispatchToMap({
        type: "SET_LAYER_LOADING",
        layer: l,
        loading: false,
      });
    });
    vectorLayer.getSource().on("featuresloaderror", () => {
      dispatchToMap({
        type: "SET_LAYER_LOADING",
        layer: l,
        loading: false,
      });
    });
  }
}
