import { AuthContext } from "context/AuthProvider";
import {
  Hoeringssag,
  Indlaeg,
  IndlaegTypeIdEnum,
  Ressource,
  RessourceRolleIdEnum,
} from "interfaces/IIndberetning";

import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ModalContext } from "../../Modal/Modal";
import Select from "../../FormElements/Select";
import { ToastContext } from "context/ToastProvider";
import ValidationErrorMessage from "../../FormElements/ValidationErrorMessage";
import { Objekttype, SagsbehandlingApi } from "api/interfaces";

export default function VideresendIndberetningModal(props: {
  message: string;
  indberetning: Hoeringssag;
  setIndberetning: Function;
  sagsbehandlingApi: SagsbehandlingApi;
}) {
  const firstFocusRef = useRef<HTMLSelectElement>(null);
  useLayoutEffect(() => {
    firstFocusRef.current?.focus();
  }, []);
  const { closeModal } = useContext(ModalContext);
  const toast = useContext(ToastContext);
  const { authState } = useContext(AuthContext);
  const { currentUser } = authState;
  const { indberetning, setIndberetning, sagsbehandlingApi } = props;
  const { getPersoner, skrivChatBesked, videresendIndberetning } =
    sagsbehandlingApi;
  const [values, setValues] = useState({
    person: -1,
    kommentar: "",
  });
  const [personer, setPersoner] = useState<Objekttype[]>([]);
  const [didPressSend, setDidPressSend] = useState(false);
  const [notify, setNotify] = useState({
    person: false,
    kommentar: false,
  });
  const resetNotifyPerson = () => setNotify({ ...notify, person: false });
  const resetNotifyKommentar = () => setNotify({ ...notify, kommentar: false });

  useEffect(() => {
    const getPersonerAsync = async () => {
      const ot = await getPersoner(currentUser);
      setPersoner(ot);
    };
    getPersonerAsync();
  }, [currentUser, getPersoner]);

  function handleChange(e) {
    let value;
    switch (e.target.name) {
      case "person":
        value = parseInt(e.target.value);
        break;
      default:
        value = e.target.value;
    }

    setValues({
      ...values,
      [e.target.name]: value,
    });
  }
  const acceptPrompt = () => {
    // Find den nuværende sagsbehandler indberetning:
    let nuvaerendeRessource = indberetning.ressource.find(
      (x) => x.rolleId === RessourceRolleIdEnum.sagsbehandler
    );
    if (nuvaerendeRessource) {
      videresendIndberetning(values.person, nuvaerendeRessource).then(
        (nyRessource: Ressource) => {
          // Ny sagsbehandler
          const index = indberetning.ressource.findIndex(
            (x) => x.rolleId === RessourceRolleIdEnum.sagsbehandler
          );
          let nyIndberetning = indberetning;
          nyIndberetning.ressource[index] = nyRessource;
          toast({
            content: {
              message: `Din sag blev vidersendt til sagsbehandler ${nyRessource.navn}.`,
            },
            type: "default",
          });
          setIndberetning(nyIndberetning);
        }
      );
      let indlaeg: Indlaeg = {
        id: 0,
        indhold: values.kommentar,
        organisationNavn: "",
        personId: currentUser.personId,
        personNavn: currentUser.navn,
        typeId: IndlaegTypeIdEnum.overdragelseskommentar,
        typeNavn: "",
        organisationId: 0,
        organisationForkortelse: "",
        ts: "",
      };
      skrivChatBesked(currentUser.personId, indberetning.id, indlaeg).then(
        (nytIndlaeg) => {
          // TODO check if this is the same as fetching the indberetning again
          // If it's not it's easier to just pass a flag that the indberetning should be fetched again
          // But it will create a much larger rerender i think.
          let nyIndberetning = indberetning;
          nyIndberetning.korrespondance.push(nytIndlaeg);
          setIndberetning(nyIndberetning);
        }
      );
    }
  };
  return (
    <div>
      <div className="gap-2 d-flex flex-column">
        <div className="gap-2">
          <Select
            ref={firstFocusRef}
            className={values.person === -1 && didPressSend ? "is-invalid" : ""}
            labelText="Person"
            mandatory={true}
            name={"person"}
            ariaLabel="Vælg objekttype"
            value={values.person.toString()}
            onChange={handleChange}
            options={[
              { value: -1, label: "Vælg person", disabled: true },
              ...personer.map((e) => {
                return { label: e.text, value: e.value.toString() };
              }),
            ]}
          />
          <ValidationErrorMessage
            show={values.person === -1 && didPressSend}
            message="Vælg person"
            notify={notify.person}
            resetNotify={() => resetNotifyPerson()}
          />
        </div>
        <div className="gap-2">
          <label htmlFor="beskrivelse" className="form-label">
            Kommentar <span className="text-danger ms-1" aria-label="(Skal udfyldes)" title="Skal udfyldes">
              <svg className="mt-n1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" aria-hidden="true" focusable="false" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
              </svg>
            </span>
          </label>
          <textarea
            aria-required="true"
            className={`form-control${
              !values.kommentar && didPressSend ? " is-invalid" : ""
            }`}
            id="kommentar"
            name="kommentar"
            value={values.kommentar}
            onChange={handleChange}
          ></textarea>
          <ValidationErrorMessage
            show={!values.kommentar && didPressSend}
            message="Skriv en kommentar"
            notify={notify.kommentar}
            resetNotify={() => resetNotifyKommentar()}
          />
        </div>
      </div>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (values.kommentar && values.person !== -1) {
              acceptPrompt();
              closeModal(false);
            } else {
              setNotify({ person: true, kommentar: true });
              setDidPressSend(true);
            }
          }}
        >
          OK
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
