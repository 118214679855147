import { useContext, useEffect, useState } from "react";
import { getIndberetninger } from "../../api/fip";
import {
  getSearchFilterByValue,
  IndberetningerState,
} from "../../interfaces/IIndberetning";
import { getFilterValuesByRolleId } from "config/FipConfig";
import { updateFipSagWmsParams } from "config/layers";
import { IndberetningFilterContext } from "context/IndberetningFilterProvider";
import IndberetningOversigt from "components/common/IndberetningOversigt/IndberetningOversigt";
import { SessionTimerContext } from "context/SessionTimerProvider";

export default function FipOversigt() {
  const {
    filter,
    filters,
    search,
    searchExtent,
    setFilter,
    setFilters,
    setSearch,
    searchInputText,
    setSearchInputText,
    currentUser,
  } = useContext(IndberetningFilterContext);
  const { resetSessionTimer } = useContext(SessionTimerContext);
  const [indberetningerState, setIndberetningerState] =
    useState<IndberetningerState>({
      state: "LOADING",
      indberetninger: [],
    });
  useEffect(() => {
    setIndberetningerState((i) => {
      return { ...i, state: "LOADING" };
    });
    const timer = setTimeout(() => {
      setSearch(searchInputText);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchInputText, setSearch]);

  useEffect(() => {
    const filtersForUser = getFilterValuesByRolleId(
      currentUser.rolleId
    ).filterValues.map((value) => {
      return getSearchFilterByValue(value);
    });
    setFilters(filtersForUser);
  }, [currentUser, setFilters]);

  useEffect(() => {
    let stillMounted = true;
    if (filter.text === "Alle indenfor kortvindue" && searchExtent[0] === 0)
      return;
    setIndberetningerState({
      indberetninger: [],
      state: "LOADING",
    });
    const getFilteredIndberetningerForUser = async () => {
      const options = {
        personId: currentUser.personId,
        rolleId: currentUser.rolleId,
        filterId: filter.value,
        searchEmne: filter.text === "Søgning" ? search.emne : "",
        searchBeskrivelse: filter.text === "Søgning" ? search.beskrivelse : "",
        extent:
          filter.text === "Alle indenfor kortvindue" ? searchExtent : undefined,
      };
      updateFipSagWmsParams(options);
      const response = await getIndberetninger(options);
      resetSessionTimer();
      const indberetninger = response.reverse();
      if (stillMounted)
        setIndberetningerState({
          indberetninger,
          state: "IDLE",
        });
    };
    getFilteredIndberetningerForUser();
    return () => {
      stillMounted = false;
    };
  }, [
    currentUser.personId,
    currentUser.rolleId,
    filter.text,
    filter.value,
    search.emne,
    search.beskrivelse,
    searchExtent,
    resetSessionTimer,
  ]);

  const hideBecauseEmptySearch =
    filter.text === "Søgning" && !search.beskrivelse && !search.emne;

  return (
    <IndberetningOversigt
      filter={filter}
      filters={filters}
      hideBecauseEmptySearch={hideBecauseEmptySearch}
      indberetningerState={indberetningerState}
      searchInputText={searchInputText}
      setFilter={setFilter}
      setSearchInputText={setSearchInputText}
      showDomain={currentUser.rolleId === 2}
      allowSearchBeskrivelse={currentUser.rolleId === 2}
    />
  );
}
