import { useRef } from "react";
import _uniqueId from "lodash/uniqueId";

interface IDropdown {
  children: JSX.Element | string;
  dropdownMenu: JSX.Element;
  buttonStyle?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link";
  buttonSize?: "large" | "normal" | "small";
}
export default function Dropdown(props: IDropdown) {
  const id = useRef(_uniqueId("dropdownMenuButton"));
  const {
    dropdownMenu,
    buttonStyle = "primary",
    children,
    buttonSize = "normal",
  } = props;
  return (
    <div className="dropdown">
      <button
        className={`btn btn-${buttonStyle} dropdown-toggle ${
          buttonSize === "large" ? "btn-lg" : ""
        } ${buttonSize === "small" ? "btn-sm" : ""}`}
        type="button"
        id={id.current}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {children}
      </button>
      <div className="dropdown-menu" aria-labelledby={id.current}>
        {dropdownMenu}
      </div>
    </div>
  );
}
