import { baseUrl, getWithErrorObject, post } from "../index";

const logOut = async () => {
  try {
    return await post({
      query: `prelogout.ashx`,
      apiPrefix: baseUrl,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMe = async (
  personId: number,
  prefix_url: string
): Promise<any> => {
  try {
    return await getWithErrorObject<any>({
      query: `${prefix_url}api/brugerauth/getme?personId=${personId}`,
      apiPrefix: baseUrl,
    });
  } catch (error) {
    return Promise.reject(error); // Error is an axios error object here, not a string message
  }
};

export const keepsessionalive = async (prefix: string) => {
  try {
    return await getWithErrorObject<any>({
      query: `${prefix}app/keepsessionalive?${encodeURIComponent(
        new Date().toISOString()
      )}`,
      apiPrefix: baseUrl,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
const api: BrugerAuth = {
  logOut,
  getMe,
  keepsessionalive,
};

export default api;

export interface BrugerAuth {
  logOut: {
    (): Promise<any>;
  };
  getMe: {
    (personId, prefix_url): Promise<any>;
  };
  keepsessionalive: { (prefix: string): Promise<any> };
}

export enum AuthenticationMode {
  none = 0,
  windows = 1,
  forms = 2,
}
