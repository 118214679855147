import { DrawTool, MapContext } from "context/MapProvider";

import IconDrawPoint from "../../icons/DrawPoint";
import IconDrawSelect from "../../icons/DrawSelect";
import IconDrawLine from "../../icons/DrawLine";
import IconDrawPolygon from "../../icons/DrawPolygon";
import { useContext, useEffect, useLayoutEffect, useRef } from "react";

export default function DrawTools(props: {
  showToast: () => void;
  allowedDrawTools: DrawTool[];
}) {
  const {
    showToast,
    allowedDrawTools = ["line", "point", "polygon", "udpeg"],
  } = props;
  const { dispatchToMap, mapState } = useContext(MapContext);
  const { activeDrawTool } = mapState;
  const focusRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);
  useEffect(() => {
    showToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDrawTool]);
  return (
    <div
      className="c-drawtools btn-group p-1 shadow"
      role="group"
      aria-label="Tegneværktøjer til kortet"
      ref={focusRef}
      tabIndex={-1}
    >
      {allowedDrawTools.indexOf("polygon") > -1 && (
        <DrawToolButton
          isActive={activeDrawTool === "polygon"}
          onClick={() => {
            dispatchToMap({ type: "SET_DRAWTOOL", drawTool: "polygon" });
          }}
        >
          <>
            <div>
              <IconDrawPolygon />
            </div>
            <span>Polygon</span>
          </>
        </DrawToolButton>
      )}
      {allowedDrawTools.indexOf("point") > -1 && (
        <DrawToolButton
          isActive={activeDrawTool === "point"}
          onClick={() => {
            dispatchToMap({ type: "SET_DRAWTOOL", drawTool: "point" });
          }}
        >
          <>
            <div>
              <IconDrawPoint />
            </div>
            <span>Punkt</span>
          </>
        </DrawToolButton>
      )}

      {allowedDrawTools.indexOf("line") > -1 && (
        <DrawToolButton
          isActive={activeDrawTool === "line"}
          onClick={() => {
            dispatchToMap({ type: "SET_DRAWTOOL", drawTool: "line" });
          }}
        >
          <>
            <div>
              <IconDrawLine />
            </div>
            <span>Linje</span>
          </>
        </DrawToolButton>
      )}
      {allowedDrawTools.indexOf("udpeg") > -1 && (
        <DrawToolButton
          isActive={activeDrawTool === "udpeg"}
          onClick={() => {
            dispatchToMap({ type: "SET_DRAWTOOL", drawTool: "udpeg" });
          }}
        >
          <>
            <div>
              <IconDrawSelect />
            </div>
            <span>Udpeg</span>
          </>
        </DrawToolButton>
      )}
    </div>
  );
}

const DrawToolButton = (props: {
  children: JSX.Element | string;
  onClick: Function;
  isActive: boolean;
}) => {
  const { children, isActive, onClick } = props;
  return (
    <button
      onClick={() => onClick()}
      type="button"
      className={`btn btn-outline-primary ${isActive ? "active" : ""}`}
      aria-current={isActive ? "true" : "false"}
    >
      {children}
    </button>
  );
};
