import { authenticationMode, logoutUrl } from "api";
import { keepsessionalive } from "api/auth";
import FocusTrap from "focus-trap-react";
import { useRouter } from "hooks/useRouter";
import {
  useEffect,
  createContext,
  useReducer,
  useRef,
  useCallback,
  useContext,
} from "react";
import { AuthContext } from "./AuthProvider";

export const SessionTimerContext = createContext<{
  resetSessionTimer: () => void;
}>({
  resetSessionTimer: () => {},
});
const SESSION_TIMEOUT_SEC = process.env.REACT_APP_SESSION_TIMEOUT_SEC
  ? parseInt(process.env.REACT_APP_SESSION_TIMEOUT_SEC)
  : 1200; // defaults to 20 minutes
const SESSION_TIMEOUT_WARNING_SEC = process.env
  .REACT_APP_SESSION_TIMEOUT_WARNING_SEC
  ? parseInt(process.env.REACT_APP_SESSION_TIMEOUT_WARNING_SEC)
  : 1080; // defaults to 18 minutes
const SessionTimerProvider = (props: any) => {
  const { authState } = useContext(AuthContext);
  const { currentUser } = authState;
  const [sessionState, dispatch] = useReducer(
    (state: SessionState, action: SessionStateAction): SessionState => {
      switch (action.type) {
        case "SET_STATE": {
          if (action.state === "WARNING") {
            return {
              ...state,
              state: action.state,
              warningTimer: SESSION_TIMEOUT_SEC - SESSION_TIMEOUT_WARNING_SEC,
            };
          } else {
            return { ...state, state: action.state };
          }
        }
        case "COUNT_DOWN": {
          if (state.state === "WARNING")
            return { ...state, warningTimer: state.warningTimer - 1 };
          return state;
        }
        case "RESET_SESSION_TIMER": {
          return { ...state, state: "IDLE" };
        }
      }
    },
    {
      state: "IDLE",
    }
  );
  const resetSessionTimer = useCallback(() => {
    dispatch({ type: "RESET_SESSION_TIMER" });
  }, []);

  const sessionTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const countdownTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (
      currentUser.isAuthenticated &&
      authenticationMode === 2 &&
      sessionState.state === "IDLE"
    ) {
      if (sessionTimer.current) {
        clearTimeout(sessionTimer.current);
      }
      sessionTimer.current = setTimeout(() => {
        dispatch({ type: "SET_STATE", state: "WARNING" });
      }, SESSION_TIMEOUT_WARNING_SEC * 1000);
    }
    return () => {
      if (sessionTimer.current) {
        clearTimeout(sessionTimer.current);
      }
    };
  }, [currentUser, sessionState]);

  useEffect(() => {
    if (sessionState.state === "WARNING") {
      if (sessionState.warningTimer > -1) {
        countdownTimer.current = setTimeout(() => {
          dispatch({ type: "COUNT_DOWN" });
        }, 1000);
      } else {
        dispatch({ type: "SET_STATE", state: "TIMEOUT" });
        if (countdownTimer.current) {
          clearTimeout(countdownTimer.current);
        }
      }
    }
    return () => {
      if (countdownTimer.current) {
        clearTimeout(countdownTimer.current);
      }
    };
  }, [sessionState]);

  return (
    <SessionTimerContext.Provider
      value={{
        resetSessionTimer,
      }}
    >
      {props.children}
      <SessionTimeoutWarning
        sessionState={sessionState}
        resetTimer={() => dispatch({ type: "RESET_SESSION_TIMER" })}
      />
    </SessionTimerContext.Provider>
  );
};

export default SessionTimerProvider;

type SessionState =
  | {
      state: "WARNING";
      warningTimer: number;
    }
  | {
      state: "IDLE" | "TIMEOUT";
    };
type SessionStateAction =
  | {
      type: "SET_STATE";
      state: "IDLE" | "TIMEOUT" | "WARNING";
    }
  | { type: "COUNT_DOWN" }
  | { type: "RESET_SESSION_TIMER" };

const SessionTimeoutWarning = (props: {
  sessionState: SessionState;
  resetTimer: () => void;
}) => {
  const { sessionState, resetTimer } = props;
  const { appRoot } = useRouter();
  useEffect(() => {
    if (sessionState.state === "TIMEOUT") {
      setTimeout(() => {
        window.location.assign(`${logoutUrl}?returnUrl=/${appRoot}`);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState.state]);
  if (sessionState.state === "IDLE") return null;
  const timeString = (seconds: number): string => {
    if (seconds > 119) {
      return `${Math.round(seconds / 60)} minutter`;
    }
    if (seconds > 60) {
      return "Lidt over et minut";
    }
    if (seconds === 1) return "1 sekund";
    return `${seconds} sekunder`;
  };
  return (
    <div className="c-modal-backdrop">
      <FocusTrap>
        <div
          tabIndex={-1}
          // aria-labelledby={modal.title}
          aria-modal="true"
          aria-live="polite"
          role="dialog"
          className={"c-modal"}
        >
          <div className={"c-modal-cont"}>
            <div className={"c-modal-header"}>
              {sessionState.state === "WARNING" && (
                <h1>Du er ved at blive logget ud</h1>
              )}
              {sessionState.state === "TIMEOUT" && (
                <h1>Du bliver nu logget ud</h1>
              )}
            </div>
            <div className={"c-modal-body"}>
              {sessionState.state === "WARNING" && (
                <>
                  <p>
                    Du har været inaktiv et stykke tid, så du er ved at blive
                    logget ud. Ønsker du at fortsætte, eller bare logge ud nu?
                    Ændringer du har lavet vil ikke blive gemt.
                  </p>
                  <p>
                    Tid til automatisk logud:{" "}
                    {timeString(sessionState.warningTimer)}
                  </p>
                </>
              )}
              {sessionState.state === "TIMEOUT" && (
                <p>På grund af inaktivitet</p>
              )}
              <div
                className={`mt-4 gap-2 ${
                  sessionState.state === "WARNING"
                    ? "d-flex"
                    : "visually-hidden"
                }`}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    const res = async () => {
                      try {
                        await keepsessionalive(
                          appRoot === "kort" ? "" : `${appRoot}/`
                        );
                        resetTimer();
                      } catch (error) {
                        console.error(error);
                      }
                    };
                    res();
                  }}
                >
                  Fortsæt
                </button>
                <a
                  className="btn btn-danger"
                  href={`${logoutUrl}?returnUrl=/${appRoot}`}
                >
                  Log ud
                </a>
              </div>
            </div>
          </div>
        </div>
      </FocusTrap>
    </div>
  );
};
