import { useLayoutEffect, useRef } from "react";

export default function NotFound() {
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div className="mt-5">
      <h1 tabIndex={-1} ref={titelRef}>
        404 siden kunne ikke findes
      </h1>
    </div>
  );
}

export function Error() {
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div>
      <h1 tabIndex={-1} ref={titelRef}>
        500 intern serverfejl
      </h1>
    </div>
  );
}
