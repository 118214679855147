import { Link } from "react-router-dom";
import { paths } from "routes/routesConfig";
// import { baselayers } from "config/FipConfig";

export default function Postnr() {
  return (
    <div className="mt-5">
      {/* <Link to={paths.kort.path}>Gå til FIP</Link> */}
      <h1>Postnummer Indberetningsportal</h1>
      <p className="lead me-md-6">
        Velkommen til Postnummer Indberetningsportal, leveret af{" "}
        <i>Styrelsen for Dataforsyning og Infrastruktur (SDFI)</i>.
      </p>
      <div className="card card--sdfe mt-3 mb-4" role="group">
        <div className="card-body">
          <span className="card-title">Mulige handlinger</span>
          <div className="d-flex">
            <Link to={paths.postnrIndberet} className="btn btn-primary">
              Indberet
            </Link>
            <Link
              to={paths.postnrIndberetninger}
              className="btn btn-primary ms-2"
            >
              Se oversigt over indberetninger
            </Link>
          </div>
        </div>
      </div>
      <h2 className="h4">Vedligeholdelse af postnumre</h2>
      <p>
        Danske Postnumre opdateres af Styrelsen for Dataforsyning og
        Infrastruktur. Danske Postnumre kan kontaktes på{" "}
        <a href="mailto:danskepostnumre@sdfi.dk">danskepostnumre@sdfi.dk</a>.
      </p>
    </div>
  );
}
