export default function ChevronLeftSmall(props: { class?: string }) {
  const classNames = props.class ? `${props.class} bi` : "bi bi-chevron-left";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10px"
      height="13px"
      viewBox="0 0 4.987 7.974"
      fill="currentColor"
      className={classNames}
    >
      <path
        d="M4.572,1.572a.249.249,0,0,1,.353,0L7.913,4.56a.249.249,0,0,1,0,.353L4.925,7.9a.249.249,0,1,1-.353-.353L7.384,4.736,4.572,1.925a.249.249,0,0,1,0-.353Z"
        transform="translate(8.736 8.723) rotate(180)"
        stroke="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
