import { Link } from "react-router-dom";
import { paths } from "routes/routesConfig";
// import { baselayers } from "config/FipConfig";

export default function Sogn() {
  return (
    <div className="mt-5">
      {/* <Link to={paths.kort.path}>Gå til FIP</Link> */}
      <h1>Sogne Indberetningsportal</h1>
      <p className="lead me-md-6">
        Velkommen til Sogne Indberetningsportal, leveret af{" "}
        <i>Styrelsen for Dataforsyning og Infrastruktur (SDFI)</i>.
      </p>
      <div className="card card--sdfe mt-3 mb-4" role="group">
        <div className="card-body">
          <span className="card-title">Mulige handlinger</span>
          <div className="d-flex">
            <Link to={paths.sognIndberet} className="btn btn-primary">
              Indberet
            </Link>
            <Link
              to={paths.sognIndberetninger}
              className="btn btn-primary ms-2"
            >
              Se oversigt over indberetninger
            </Link>
          </div>
        </div>
      </div>
      <h2 className="h4">Vedligeholdelse af sogne</h2>
      <p>
        Danske Sogne opdateres af Styrelsen for Dataforsyning og
        Infrastruktur. Danske Sogne kan kontaktes på{" "}
        <a href="mailto:danskesogn@sdfi.dk">danskesogn@sdfi.dk</a>.
      </p>
    </div>
  );
}
