import { useContext } from "react";
import { ModalContext } from "../Modal/Modal";

export enum BeskedType {
  besked = 0,
  note = 1,
}
export default function PromptModal(props: {
  acceptPrompt: () => void;
  message: string;
}) {
  const { closeModal } = useContext(ModalContext);
  const { message, acceptPrompt } = props;
  return (
    <div>
      <strong>
        <p>{message}</p>
      </strong>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            acceptPrompt();
            closeModal(false);
          }}
        >
          OK
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
