import { Feature, Map } from "ol";
import { WFS, WKT } from "ol/format";
import Geometry from "ol/geom/Geometry";
import SimpleGeometry from "ol/geom/SimpleGeometry";
import BaseLayer from "ol/layer/Base";
import { Vector } from "ol/source";
import GML32 from "ol/format/GML32";
import * as jsts from "jsts";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import LinearRing from "ol/geom/LinearRing";
import Polygon from "ol/geom/Polygon";
import MultiPoint from "ol/geom/MultiPoint";
import MultiLineString from "ol/geom/MultiLineString";
import MultiPolygon from "ol/geom/MultiPolygon";

export const wktFormat = new WKT();

export const wfsFormat = new WFS({
  version: "2.0.0",
});

export const gml32Format = new GML32();

/**
 * Convert string WKT feature to OpenLayers Feature
 * @param wkt WKT formatted feature string
 * @returns OpenLayers Feature
 */
export function convertWktToFeature(wkt: string): Feature<any> {
  return wktFormat.readFeature(wkt);
}

/**
 * Convert OpenLayers Feature to string WKT feature
 * @param feature OpenLayers Feature
 * @returns string WKT feature
 */
export function convertFeatureToWkt(feature: Feature<Geometry>): string {
  return wktFormat.writeFeature(feature);
}

/**
 * Translate OpenLayers geometry type to Danish
 * @param geometryType String value representing geometry type
 * @returns String value of geometry type translated to Danish
 */
export function translateGeometryType(geometryType: string) {
  switch (geometryType) {
    case "Point":
      return "Punkt";
    case "LineString":
      return "Linje";
    default:
      return geometryType;
  }
}

/**
 * Adds layer to map, unless it already exists. Throws error
 * if layer does not have title property or if there is a layer
 * in the map without title property.
 * @param map OpenLayers Map to add layer to
 * @param layer Layer to add
 */
export function addLayerToMap(map: Map, layer: BaseLayer) {
  const layerTitle = layer.get("title");
  if (!layerTitle) throw new Error("Layer should have title property");
  const layersInMap = map.getLayers().getArray();
  let shouldAddLayer = true;
  for (let i = 0; i < layersInMap.length; i++) {
    const l = layersInMap[i];
    const mapLayerTitle = l.get("title");
    if (!mapLayerTitle) {
      console.error(l);
      throw new Error("Found layer in map without title");
    }
    if (mapLayerTitle === layerTitle) shouldAddLayer = false;
  }
  if (shouldAddLayer) map.addLayer(layer);
  else console.log("Skipped adding existing layer to map", layerTitle);
}

export const zoomToFeature = (map: Map, feature: Feature<Geometry>) => {
  map.getView().fit(feature.getGeometry() as SimpleGeometry, {
    maxZoom: 12,
    padding: [100, 100, 100, 100],
  });
};

/**
 * Safely adds feature to source. If feature already
 * exist in source, nothing happens.
 * @param feature
 * @param source
 */
export const addFeatureToSource = (
  feature: Feature<Geometry>,
  source: Vector<Geometry>
) => {
  if (!source.hasFeature(feature)) source.addFeature(feature);
};
/**
 * Safely removes feature from source. If feature does not
 * exist in source, nothing happens.
 * @param feature
 * @param source
 */
export const removeFeatureFromSource = (
  feature: Feature<Geometry>,
  source: Vector<Geometry>
) => {
  if (source.hasFeature(feature)) source.removeFeature(feature);
};

const ol3Parser = new jsts.io.OL3Parser();
ol3Parser.inject(
  Point,
  LineString,
  LinearRing,
  Polygon,
  MultiPoint,
  MultiLineString,
  MultiPolygon
);

export const isValid = (geometry: Geometry) => {
  const jstsFeature = ol3Parser.read(geometry);
  return jstsFeature.isValid() as boolean;
};
