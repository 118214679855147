import axios, { AxiosResponse } from "axios";
import { AuthenticationMode } from "./auth";
// import authState from "context/AuthProvider";
export const baseUrl = process.env.REACT_APP_API_BASEURL
  ? process.env.REACT_APP_API_BASEURL
  : "/";

// TODO: Skal udskiftet med baseUrl, når vi skifter til sso site
export const loginUrl = `${baseUrl}login.ashx`;
export const logoutUrl = `${baseUrl}prelogout.ashx`;

export const authenticationMode = process.env.REACT_APP_AUTHENTICATION_MODE
  ? parseInt(process.env.REACT_APP_AUTHENTICATION_MODE)
  : AuthenticationMode.none;

export async function get<T>({
  query = "",
  apiPrefix = baseUrl,
  headers = {},
}): Promise<T> {
  try {
    const response = await axios.get<T>(`${apiPrefix}${query}`, headers);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getWithErrorObject<T>({
  query = "",
  apiPrefix = baseUrl,
}): Promise<T> {
  try {
    const response = await axios.get<T>(`${apiPrefix}${query}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getResponse<T>({
  query = "",
  apiPrefix = baseUrl,
}): Promise<AxiosResponse<T>> {
  try {
    const response = await axios.get<T>(`${apiPrefix}${query}`);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function put<T>({
  query = "",
  body = {},
  apiPrefix = baseUrl,
}): Promise<T> {
  try {
    const response = await axios.put<T>(`${apiPrefix}${query}`, body);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function post<T>({
  query = "",
  body = {},
  apiPrefix = baseUrl,
  headers = {},
}): Promise<T> {
  try {
    const response = await axios.post<T>(`${apiPrefix}${query}`, body, headers);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function del<T>({
  query = "",
  body = {},
  apiPrefix = baseUrl,
}): Promise<AxiosResponse<T>> {
  try {
    return await axios.delete<T>(`${apiPrefix}${query}`, body);
  } catch (error) {
    return Promise.reject(error);
  }
}
