import {
  Hoeringssag,
  Ressource,
  RessourceRolleIdEnum,
  statusIdEnum,
} from "interfaces/IIndberetning";
import Section from "../../Section";
import { useContext } from "react";
import { ModalContext } from "../../Modal/Modal";
import { isIndberetningMine } from "routes/Fip/FipIndberetning";
import VideresendIndberetningTilHoeringModal from "./VideresendIndberetningTilHoeringModal";
import PromptModal from "../PromptModal";
import {
  afslutHoeringsbehandling,
  getIndberetning,
  tagHoeringssagFraHoeringspart,
} from "api/fip";
import { ToastContext } from "context/ToastProvider";
import RedigerHoeringsSvarModal from "./RedigerHoeringsSvarModal";
import { Bruger } from "context/AuthProvider";

interface IHoeringspartfunktionerProps {
  currentUser: Bruger;
  indberetning: Hoeringssag;
  setIndberetning: Function;
  cancelDrawingAndUploadFile: () => void;
}

export default function Hoeringspartfunktioner(
  props: IHoeringspartfunktionerProps
) {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    currentUser,
    indberetning,
    setIndberetning,
    cancelDrawingAndUploadFile,
  } = props;
  const { showModal, closeModal } = useContext(ModalContext);
  const toast = useContext(ToastContext);
  const { statusId } = indberetning;
  const indberetningIsAfsluttet =
    statusId === statusIdEnum.afsluttet ? true : false;
  const hoeringspartmappe =
    indberetning.hoeringspartmappe &&
    indberetning.hoeringspartmappe.find(
      (m) => m.organisationId === currentUser.organisationId
    );

  const isOwner = isIndberetningMine(currentUser, indberetning, true);
  const aktivHøringspart = indberetning.ressource.find(
    (x) => x.rolleId === RessourceRolleIdEnum.høringspart
  );
  const isHoeringActive = aktivHøringspart !== undefined;
  const isOrganisationAktivHoeringspart =
    aktivHøringspart &&
    aktivHøringspart.organisationId === currentUser.organisationId;

  return (
    <Section className="mt-5">
      <div className="card card--sdfe mt-3 mb-4">
        <div className="card-body">
          <span className="card-title">Høringspartfunktioner</span>
          <p>Foretag ændringer eller tilføjelser til indberetningen.</p>
          <ul className="list-arrows">
            <li>
              <button
                className="btn btn-link"
                disabled={
                  isOwner ||
                  !isOrganisationAktivHoeringspart ||
                  !isHoeringActive
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Behandl høringssag",
                    content: (
                      <PromptModal
                        message={`Sagen er allerede under behandling af din organisation. Ønsker du at overtage sagen fra den nuværende ansvarlige`}
                        acceptPrompt={() => {
                          // Do stuff that makes you take the indberetning

                          const daværendeRessource =
                            indberetning.ressource.find(
                              (x) =>
                                x.rolleId === RessourceRolleIdEnum.høringspart
                            );
                          if (daværendeRessource) {
                            daværendeRessource.personId = currentUser.personId;

                            tagHoeringssagFraHoeringspart(
                              currentUser,
                              daværendeRessource
                            ).then((nyRessource: Ressource) => {
                              // Ny Høringspart
                              const index = indberetning.ressource.findIndex(
                                (x) =>
                                  x.rolleId === RessourceRolleIdEnum.høringspart
                              );
                              let nyIndberetning = indberetning;
                              nyIndberetning.ressource[index] = nyRessource;
                              toast({
                                type: "default",
                                content: {
                                  message: `Du har nu overtaget behandlingen af sagen fra ${daværendeRessource.navn}`,
                                },
                              });
                              setIndberetning(nyIndberetning);
                            });
                          } else {
                            toast({
                              type: "danger",
                              content: {
                                message: `Sagen er ikke i høring, du kan ikke behandle sagen før den er sendt i høring`,
                              },
                            });
                            closeModal(false);
                            throw new Error(
                              "Der findes ikke nogen høringspart i forvejen, dette er en fejl. - FEJL"
                            );
                            // TODO: se om der skal kaldes til opretHoeringssag i stedet
                          }
                        }}
                      />
                    ),
                  });
                }}
              >
                Behandl høringssag
              </button>
            </li>
            <li>
              <button
                className="btn btn-link"
                disabled={
                  !isOwner ||
                  !isOrganisationAktivHoeringspart ||
                  !isHoeringActive
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Videresend sag",
                    content: (
                      <VideresendIndberetningTilHoeringModal
                        currentUser={currentUser}
                        message={"Videresend sag"}
                        indberetning={indberetning}
                        setIndberetning={setIndberetning}
                      />
                    ),
                  });
                }}
              >
                Videresend høringssag internt
              </button>
            </li>
            <li>
              <button
                disabled={
                  !isOwner ||
                  !isHoeringActive ||
                  !isOrganisationAktivHoeringspart
                }
                className="btn btn-link"
                onClick={() => {
                  if (hoeringspartmappe) {
                    cancelDrawingAndUploadFile();
                    showModal({
                      title: "Rediger høringssvar",
                      content: (
                        <RedigerHoeringsSvarModal
                          message={"Rediger høringssvar"}
                          indberetning={indberetning}
                          hoeringspartmappe={hoeringspartmappe}
                          setIndberetning={setIndberetning}
                        />
                      ),
                    });
                  }
                }}
              >
                Opret/Rediger høringssvar
              </button>
            </li>
            <li>
              <button
                className="btn btn-link"
                disabled={
                  !isOwner ||
                  !isHoeringActive ||
                  !isOrganisationAktivHoeringspart
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Afslut høringsbehandling",
                    content:
                      hoeringspartmappe && hoeringspartmappe.svar ? (
                        <PromptModal
                          message={`Dit høringssvar er: ${hoeringspartmappe.svar}`}
                          acceptPrompt={() => {
                            // Afslut høringsbehandling

                            const daværendeRessource =
                              indberetning.ressource.find(
                                (x) =>
                                  x.rolleId === RessourceRolleIdEnum.høringspart
                              );
                            if (daværendeRessource) {
                              daværendeRessource.personId =
                                currentUser.personId;

                              afslutHoeringsbehandling(
                                currentUser,
                                indberetning,
                                daværendeRessource
                              )
                                .then(() => {
                                  // Ny Høringspart
                                  getIndberetning(
                                    indberetning.id,
                                    currentUser.personId
                                  ).then((nyIndberetning) => {
                                    toast({
                                      type: "default",
                                      content: {
                                        message: `Du har nu afsluttet høringsbehandlingen af sagen`,
                                      },
                                    });
                                    setIndberetning(nyIndberetning);
                                  });
                                })
                                .catch((e) => {
                                  toast({
                                    type: "danger",
                                    content: {
                                      message: `Det lykkedes ikke at afslutte høringsbehandlingen af sagen - FEJL`,
                                    },
                                  });
                                  throw new Error(
                                    "Det lykkedes ikke at afslutte høringsbehandlingen af sagen - FEJL"
                                  );
                                });
                            } else {
                              throw new Error(
                                "Der findes ikke nogen høringspart i forvejen, dette er en fejl og høringen kunne ikke afsluttes. - FEJL"
                              );
                              // TODO: se om der skal kaldes til opretHoeringssag i stedet
                            }
                          }}
                        />
                      ) : (
                        <div>
                          Du har ikke oprettet et høringssvar, og kan følgelig
                          ikke afslutte høringsbehandlingen.
                          <div className="mt-4 gap-2 d-flex">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={() => {
                                closeModal(false);
                              }}
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      ),
                  });
                }}
              >
                Afslut høringsbehandling
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
}
