export default function MinusSquare(props: { class?: string }) {
  const classNames = props.class ? `${props.class} bi` : "bi bi-dash-square";
  return (
    <svg
      fill="currentColor"
      className={classNames}
      data-name="Group 6"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="16"
            height="16"
            fill="none"
          />
        </clipPath>
      </defs>
      <g data-name="Group 5" clipPath="url(#clip-path)">
        <path
          id="Path_9"
          data-name="Path 9"
          d="M14,0H2A2.006,2.006,0,0,0,0,2V14a2,2,0,0,0,2,2H14a1.993,1.993,0,0,0,2-2V2a2,2,0,0,0-2-2M11.5,9h-7a1,1,0,0,1-1-1,1.016,1.016,0,0,1,1-1h7a1,1,0,0,1,1,1,.99.99,0,0,1-1,1"
        />
      </g>
    </svg>
  );
}
