import {
  getHoeringsPersoner,
  getIndberetning,
  skrivChatBesked,
  videresendIndberetningTilHoering,
  videresendIndberetningTilHoeringInternt,
} from "api/fip";
import { Bruger } from "context/AuthProvider";
import {
  Hoeringssag,
  Indlaeg,
  IndlaegTypeIdEnum,
  Ressource,
  RessourceRolleIdEnum,
  // statusIdEnum,
} from "interfaces/IIndberetning";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ModalContext } from "../../Modal/Modal";
import Select from "../../FormElements/Select";
import ValidationErrorMessage from "../../FormElements/ValidationErrorMessage";
import LoadingSpinner from "../../LoadingSpinner";
import { Objekttype } from "api/interfaces";

export default function VideresendIndberetningTilHoeringModal(props: {
  currentUser: Bruger;
  message: string;
  indberetning: Hoeringssag;
  setIndberetning: Function;
}) {
  const { closeModal } = useContext(ModalContext);
  const { indberetning, setIndberetning, currentUser } = props;
  const [values, setValues] = useState({
    person: -1,
    kommentar: "",
  });
  const [state, setState] = useState<State>("LOADING");
  const [personer, setPersoner] = useState<Objekttype[]>([]);

  const [notify, setNotify] = useState({
    person: false,
    kommentar: false,
  });
  const resetNotifyPerson = () => setNotify({ ...notify, person: false });
  const resetNotifyKommentar = () => setNotify({ ...notify, kommentar: false });
  const firstFocusRef = useRef<HTMLSelectElement>(null);
  useLayoutEffect(() => {
    if (personer.length) {
      setTimeout(() => {
        // settimeout pga conditional render
        firstFocusRef.current?.focus();
      }, 0);
    }
  }, [personer]);
  const [didPressSend, setDidPressSend] = useState(false);

  useEffect(() => {
    const getPersonerAsync = async () => {
      const ot = await getHoeringsPersoner(
        currentUser,
        currentUser.rolleId === RessourceRolleIdEnum.høringspart
          ? currentUser.organisationId
          : undefined
      );
      setPersoner(ot.filter((p) => p.value !== currentUser.personId));
      setState("IDLE");
    };
    setState("LOADING");
    getPersonerAsync();
  }, [currentUser]);

  function handleChange(e) {
    let value;
    switch (e.target.name) {
      case "person":
        value = parseInt(e.target.value);
        break;
      default:
        value = e.target.value;
    }

    setValues({
      ...values,
      [e.target.name]: value,
    });
  }
  const acceptPrompt = () => {
    // Find den nuværende høringspart:
    let nuvaerendeRessource = indberetning.ressource.find(
      (x) => x.rolleId === RessourceRolleIdEnum.høringspart
    );
    let indlaeg: Indlaeg = {
      id: 0,
      indhold: values.kommentar,
      organisationNavn: "",
      personId: currentUser.personId,
      personNavn: currentUser.navn,
      typeId: IndlaegTypeIdEnum.overdragelseskommentar,
      typeNavn: "",
      organisationId: 0,
      organisationForkortelse: "",
      ts: "",
    };
    // Hvis der er en høringspart, skal den sendes internt
    if (nuvaerendeRessource) {
      // First we need to post the indlaeg

      videresendIndberetningTilHoeringInternt(
        currentUser,
        nuvaerendeRessource.id,
        values.person,
        indberetning,
        values.kommentar
      )
        .then((nyRessource: Ressource) => {
          skrivChatBesked(currentUser.personId, indberetning.id, indlaeg)
            .then((e) => {
              getIndberetning(indberetning.id, currentUser.personId).then(
                (nyIndberetning) => {
                  setIndberetning(nyIndberetning);
                }
              );
            })
            .catch((e) => {
              throw new Error("Kunne ikke sende overdragelseskommentar - FEJL");
            });
        })
        .catch((e) => {
          throw new Error("Kunne ikke overdrage høringssagen - FEJL");
        });
      // Hvis der ikke er en høringspart, skal den ikke sendes internt, men oprettes i systemet.
    } else {
      videresendIndberetningTilHoering(
        currentUser,
        values.person,
        indberetning,
        values.kommentar
      )
        .then((nyRessource: Ressource) => {
          getIndberetning(indberetning.id, currentUser.personId).then(
            (nyIndberetning) => {
              setIndberetning(nyIndberetning);
            }
          );
        })
        .catch((e) => {
          throw new Error("Kunne ikke overdrage høringssagen - FEJL");
        });
    }
  };
  if (state === "LOADING") {
    return <LoadingSpinner />;
  }
  return (
    <div>
      {personer.length === 0 && (
        <>
          <div className="gap-2 d-flex flex-column">
            Du den eneste registrerede sagsbehandler i din organisation.
          </div>
          <div className="mt-4 gap-2 d-flex">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => closeModal(false)}
            >
              OK
            </button>
          </div>
        </>
      )}
      {personer.length > 0 && (
        <>
          <div className="gap-2 d-flex flex-column">
            <div className="gap-2">
              <Select
                ref={firstFocusRef}
                className={
                  values.person < 0 && didPressSend ? "is-invalid" : ""
                }
                labelText="Person"
                mandatory={true}
                name={"person"}
                ariaLabel="Vælg objekttype"
                value={values.person.toString()}
                onChange={handleChange}
                options={[
                  { value: -1, label: "Vælg person", disabled: true },
                  ...personer.map((e) => {
                    return { label: e.text, value: e.value.toString() };
                  }),
                ]}
              />
              <ValidationErrorMessage
                show={values.person < 0 && didPressSend}
                message="Vælg en person fra listen"
                notify={notify.person}
                resetNotify={() => resetNotifyPerson()}
              />
            </div>
            <div className="gap-2">
              <label htmlFor="beskrivelse" className="form-label">
                Kommentar <span className="text-danger ms-1" aria-label="(Skal udfyldes)" title="Skal udfyldes">
                  <svg className="mt-n1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" aria-hidden="true" focusable="false" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
                  </svg>
                </span>
              </label>
              <textarea
                aria-required="true"
                className={
                  !values.kommentar && didPressSend
                    ? "form-control is-invalid"
                    : "form-control"
                }
                id="kommentar"
                name="kommentar"
                value={values.kommentar}
                onChange={handleChange}
              ></textarea>{" "}
              <ValidationErrorMessage
                show={!values.kommentar && didPressSend}
                message="Skriv en kommentar"
                notify={notify.kommentar}
                resetNotify={() => resetNotifyKommentar()}
              />
            </div>
          </div>
          <div className="mt-4 gap-2 d-flex">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (!values.kommentar || values.person < 0) {
                  setDidPressSend(true);
                } else {
                  acceptPrompt();
                  closeModal(false);
                }
              }}
            >
              OK
            </button>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={() => {
                closeModal(true);
              }}
            >
              Fortryd
            </button>
          </div>
        </>
      )}
    </div>
  );
}
