import { Indlaeg, IndlaegTypeIdEnum } from "interfaces/IIndberetning";
import { formatDateString } from "util/date";

export default function ChatMessage(props: {
  indlaeg: Indlaeg;
  type: "Høringssag" | "Sagsbehandling" | "Stednavn";
}) {
  const { indlaeg, type } = props;
  let left = true;
  if (type === "Sagsbehandling") {
    if (
      [
        IndlaegTypeIdEnum.fraIndberetter,
        IndlaegTypeIdEnum.tilIndberetter,
      ].includes(indlaeg.typeId)
    ) {
      left = true;
    } else {
      left = false;
    }
  } else if (type === "Høringssag") {
    if (
      [
        IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig,
        IndlaegTypeIdEnum.beskedTilHoeringspart,
        IndlaegTypeIdEnum.overdragelseskommentar,
      ].includes(indlaeg.typeId)
    ) {
      left = true;
    } else {
      left = false;
    }
  } else {
    if ([4, 10].includes(indlaeg.typeId)) {
      left = false;
    } else {
      left = true;
    }
  }

  return (
    <div
      key={indlaeg.id}
      className={`c-journal__message ${convertTypeidToClass(
        indlaeg.typeId,
        type
      )} ${left ? "" : "ms-auto"}`}
    >
      <div
        className={`c-journal__message-header small d-block d-md-flex justify-content-between`}
      >
        <div>
          <span
            role="img"
            className="geometri-color me-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Farve i kortet (Geometri)"
            aria-label="Signatur farve i kort"
          ></span>
          <span>
            <strong>{indlaeg.personNavn}</strong>
            {" " + convertTypeidToType(indlaeg.typeId, type)}
          </span>
        </div>
        <span aria-hidden className="d-inline-block mt-1 mt-md-0">
          {formatDateString(indlaeg.ts)}
        </span>
        <span className="visually-hidden">
          <span>{formatDateString(indlaeg.ts, "dd-MM-yyyy")}</span> klokken{" "}
          {formatDateString(indlaeg.ts, "HH:mm")}
        </span>
      </div>
      <div className="c-journal__message-content">
        <p>{indlaeg.indhold}</p>
      </div>
      {[IndlaegTypeIdEnum.intern].includes(indlaeg.typeId) && (
        <div className="c-journal__message-footer">
          <span className="text-primary small">
            <strong>Intern besked</strong> – Vises kun for sagsbehandlere
          </span>
        </div>
      )}
    </div>
  );
}
const convertTypeidToClass = (
  typeId: IndlaegTypeIdEnum,
  type: "Høringssag" | "Sagsbehandling" | "Stednavn"
) => {
  let classname = "";

  if (typeId === IndlaegTypeIdEnum.fraIndberetter) {
    classname = "c-journal__message--indberetter";
  }
  if (typeId === IndlaegTypeIdEnum.tilIndberetter) {
    // Det her giver ingen mening, men det er rigtigt
    classname = "c-journal__message--indberetter";
  }
  if (typeId === IndlaegTypeIdEnum.fraSagsbehandler) {
    classname = "c-journal__message--sagsbehandler";
  }

  if (typeId === IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig) {
    classname = "c-journal__message--horingsansvarlig";
  }
  if (typeId === IndlaegTypeIdEnum.beskedTilHoeringspart) {
    classname = "c-journal__message--horingsansvarlig";
  }
  if (typeId === IndlaegTypeIdEnum.beskedFraHoeringspart) {
    classname = "c-journal__message--horingspart";
  }
  if (typeId === IndlaegTypeIdEnum.intern) {
    classname = "c-journal__message--intern-note";
  }

  if (typeId === IndlaegTypeIdEnum.overdragelseskommentar) {
    classname = "c-journal__message--overdragelseskommentar";
  }

  if (typeId === IndlaegTypeIdEnum.afslutningsKommentar) {
    classname = "c-journal__message--afslutningskommentar";
  }
  if (type === "Stednavn") {
    if (typeId === 3) {
      classname = "c-journal__message--indberetter";
    }
    if (typeId === 4 || typeId === 10) {
      classname = "c-journal__message--sagsbehandler";
    }
  }
  if (classname !== "") {
    return classname;
  } else {
    return "c-journal__message--corrupt";
  }
};
const convertTypeidToType = (
  typeId: IndlaegTypeIdEnum,
  type: "Høringssag" | "Sagsbehandling" | "Stednavn"
) => {
  if (type === "Stednavn") {
    switch (typeId) {
      case 3:
        return "Indberetter";
      case 4:
        return "Sagsbehandler";
      case 10:
        return "Sagsbehandler";
    }
  }
  switch (typeId) {
    case IndlaegTypeIdEnum.fraIndberetter:
      return "Indberetter";
    case IndlaegTypeIdEnum.tilIndberetter:
      return "Indberetter";
    case IndlaegTypeIdEnum.intern:
      return "Intern Note";
    case IndlaegTypeIdEnum.fraSagsbehandler:
      return "Sagsbehandler";
    case IndlaegTypeIdEnum.tilSagsbehandler:
      return "Sagsbehandler";
    case IndlaegTypeIdEnum.overdragelseskommentar:
      return "Har videresendt sagen";
    case IndlaegTypeIdEnum.beskedFraHoeringsAnsvarlig:
      return "Høringsansvarlig";
    case IndlaegTypeIdEnum.beskedFraHoeringspart:
      return "Høringspart";
    case IndlaegTypeIdEnum.beskedTiLHoeringsAnsvarlig:
      return "Høringspart"; // De her to er weird
    case IndlaegTypeIdEnum.beskedTilHoeringspart:
      return "Høringsansvarlig"; // De her to er weird
    case IndlaegTypeIdEnum.afslutningsKommentar:
      return "har afsluttet sagen";
    default:
      return "FIX ENUM";
  }
};
