import { Link, useLocation } from "react-router-dom";
import { routesArray } from "routes/routesConfig";
import { makeBreadCrumbs } from "./breadcrumbsUtil";

export default function BreadCrumbs() {
  const Wrapper = ({ children }) => {
    return (
      <nav className="c-breadcrumbs ms-4 ms-xl-0" aria-label="Brødkrummestig">
        <div className="c-breadcrumbs__container">
          <ol className="breadcrumb">
            <li className="me-2" aria-hidden="true">Du er her:</li>
            {children}
          </ol>
        </div>
      </nav>
    );
  };
  const { pathname } = useLocation();

  const crumbs = makeBreadCrumbs(pathname, routesArray);

  if (crumbs.length === 0)
    return (
      <Wrapper>
        <li
          className="breadcrumb-item active"
          aria-current="page"
          title={`Siden findes ikke`}
          aria-label="404 - Siden findes ikke"
        >
          404
        </li>
      </Wrapper>
    );
  return (
    <nav className="c-breadcrumbs ms-4 ms-xl-0" aria-label="Brødkrummestig">
      <div className="c-breadcrumbs__container">
        <ol className="breadcrumb">
          <li className="me-2" aria-hidden="true">Du er her:</li>
          {crumbs.map((crumb, i) => {
            // Den sidste breadcrumb skal ikke have link
            if (i === crumbs.length - 1) {
              return (
                <li
                  key={i}
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  <span title={`${crumb.name}`} aria-label={`${crumb.name}`}>
                    {crumb.name}
                  </span>
                </li>
              );
            }
            return (
              <li key={i} className="breadcrumb-item">
                <Link to={crumb.path} title={`${crumb.name}`} aria-label={`${crumb.name}`}>
                  {crumb.name}
                </Link>
              </li>
            );
          })}
        </ol>
      </div>
    </nav>
  );
}
