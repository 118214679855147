import { createContext, useState } from "react";

export const LayoutContext = createContext<{
  paneToShow: "left" | "right";
  setPaneToShow: (paneToShow: "left" | "right") => void;
}>({
  paneToShow: "left",
  setPaneToShow: () => {},
});

export const LayoutProvider = ({ children }) => {
  let [paneToShow, setPaneToShow] = useState<"left" | "right">("left");
  return (
    <LayoutContext.Provider value={{ paneToShow, setPaneToShow }}>
      {children}
    </LayoutContext.Provider>
  );
};
