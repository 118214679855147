import { uniqueId } from "lodash";
import { Feature, Map } from "ol";
import Geometry from "ol/geom/Geometry";
import GeometryType from "ol/geom/GeometryType";
import MultiPoint from "ol/geom/MultiPoint";
import MultiPolygon from "ol/geom/MultiPolygon";
import Polygon from "ol/geom/Polygon";
import { Draw, Modify, Select } from "ol/interaction";
import { DrawEvent } from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import RenderFeature from "ol/render/Feature";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { addLayerToMap, isValid } from "util/ol";
import { selectableLayers } from "./layers";

const hoveredImageStyle = new CircleStyle({
  radius: 7,
  fill: new Fill({ color: "#3057ed" }),
  stroke: new Stroke({ color: "white", width: 2 }),
});
const hoveredStrokeStyle = new Stroke({
  color: "#3057ed",
  width: 3,
});
const hoveredFillStyle = new Fill({ color: "#3057ed48" });

const drawStroke = (feature: Feature<any> | RenderFeature) => {
  const source = feature.get("mappe");
  const isHovered = feature.get("hovered");
  if (isHovered) return hoveredStrokeStyle;
  switch (source) {
    //indberetning, sagsbehandler, hoeringspart, hoeringsansvarlig
    case "sagsbehandler":
      return new Stroke({ color: "#ff8d27", width: 4 });
    case "hoeringspart":
      return new Stroke({ color: "#9a3dc9", width: 4 });
    case "hoeringsansvarlig":
      return new Stroke({ color: "#b35f35", width: 4 });
  }
  // indberetning
  return new Stroke({ color: "#2fa369", width: 4 });
};

const drawFill = (feature: Feature<any> | RenderFeature) => {
  const source = feature.get("mappe");
  const isHovered = feature.get("hovered");
  if (isHovered) return hoveredFillStyle;
  switch (source) {
    //indberetning, sagsbehandler, hoeringspart, hoeringsansvarlig
    case "sagsbehandler":
      return new Fill({ color: "#ff8d2740" });
    case "hoeringspart":
      return new Fill({ color: "#9a3dc940" });
    case "hoeringsansvarlig":
      return new Fill({ color: "#b35f3540" });
  }
  // indberetning
  return new Fill({ color: "#2fa36940" });
};

const getDrawLayerFeatureText = (feature: Feature<any> | RenderFeature) => {
  const source = feature.get("mappe");
  const featuretype = feature.get("type");
  const type = featuretype ? " - " + featuretype : "";

  switch (source) {
    //indberetning, sagsbehandler, hoeringspart, hoeringsansvarlig
    case "sagsbehandler":
      return `GST${type}`;
    case "hoeringspart":
      return `GST høringspart${type}`;
    case "hoeringsansvarlig":
      return `GST høring${type}`;
    case "indberetning":
      return `Indberetter${type}`;
  }

  return `${type}`;
};
export const drawLayerStyle = (feature: Feature<any> | RenderFeature) => {
  return new Style({
    stroke: drawStroke(feature),
    fill: drawFill(feature),
    image: new CircleStyle({
      radius: 5,
      stroke: drawStroke(feature),
      fill: drawFill(feature),
    }),
    text: new Text({
      text: getDrawLayerFeatureText(feature),
      overflow: true,
      font: "500 14px sans-serif",
      stroke: new Stroke({ color: "#FFFFFFAA", width: 3 }),
      offsetY: 15,
    }),
  });
};

const drawInteractionSource = new VectorSource({ wrapX: false });
export const tempDrawingSource = new VectorSource({ wrapX: false });
export const tempDrawLayer = new VectorLayer({
  properties: { title: "tempDrawLayer" },
  zIndex: 99,
  source: tempDrawingSource,
  style: new Style({
    stroke: new Stroke({
      color: "#01525aaa",
      width: 2,
      lineDash: [5, 5],
    }),
    image: new CircleStyle({
      radius: 4,
      stroke: new Stroke({
        color: "#01525aaa",
        width: 2,
      }),
    }),
  }),
});
export const finishedDrawingSource = new VectorSource({ wrapX: false });
export const drawLayer = new VectorLayer({
  properties: { title: "drawLayer" },
  zIndex: 100,
  source: finishedDrawingSource,
  style: function (feature) {
    return drawLayerStyle(feature);
  },
});
export const modifySource = new VectorSource({ wrapX: false });
export const modify = new Modify({
  source: modifySource,
  style: new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: "rgba(0,0,100,0.3)",
      }),
      radius: 20,
    }),
  }),
});
const geometryIsValid = (feature: Feature<any> | RenderFeature) => {
  const geom = feature.getGeometry();
  const geometryType = geom.getType();
  if (geometryType === "Polygon") {
    const polygon = geom as Polygon;
    if (polygon.getLinearRing(0).getCoordinates().length > 3)
      return isValid(polygon);
  }
  if (geometryType === "MultiPolygon") {
    const mp = geom as MultiPolygon;
    if (mp.getPolygon(0).getLinearRing(0).getCoordinates().length > 3) {
      return isValid(mp);
    }
  }
  return true;
};
const modifyLayer = new VectorLayer({
  properties: { title: "modifyLayer" },
  zIndex: 100,
  source: modifySource,
  style: function (feature) {
    const ok = geometryIsValid(feature);
    return ok
      ? new Style({
          image: hoveredImageStyle,
          stroke: hoveredStrokeStyle,
          fill: hoveredFillStyle,
        })
      : new Style({
          image: imageStyle,
          stroke: new Stroke({
            color: "#e25f5f",
            width: 5,
          }),
          fill: new Fill({ color: "#e25f5f20" }),
        });
  },
});
const modifyLayerPolygonVertices = new VectorLayer({
  properties: { title: "drawLayerPolygonVertices" },
  source: modifySource,
  zIndex: 101,
  style: function (feature) {
    const valid = geometryIsValid(feature);
    return new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: valid ? "#3057ed" : "#e25f5f",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
        radius: 7,
      }),
      geometry: (feature) => {
        const geom = feature.getGeometry();
        let coordinates = geom.getCoordinates();
        if (geom.getType() === "Polygon") {
          let c = [];
          for (let i = 0; i < coordinates.length; i++) {
            c = c.concat(coordinates[i]);
          }
          coordinates = c;
          return new MultiPoint(coordinates);
        } else if (geom.getType() === "MultiPolygon") {
          let c = [];
          for (let i = 0; i < coordinates.length; i++) {
            for (let j = 0; j < coordinates[i].length; j++) {
              c = c.concat(coordinates[i][j]);
            }
          }
          coordinates = c;
          return new MultiPoint(coordinates);
        } else if (geom.getType() === "LineString")
          return new MultiPoint(coordinates);
        return geom;
      },
    });
  },
});

let drawingCallback: (drawing: Feature<Geometry>, isValid: boolean) => void;
export const setDrawingCallback = (
  callback: (drawing: Feature<Geometry>, isValid: boolean) => void
) => {
  drawingCallback = callback;
};
const onDrawEnd = (event: DrawEvent) => {
  const { feature } = event;
  feature.set("id", uniqueId("feature"));
  finishedDrawingSource.addFeature(feature);
  if (drawingCallback) {
    const geom = feature.getGeometry();
    const ok =
      geom.getType() === "Polygon" &&
      geom.getLinearRing().getCoordinates().length > 3
        ? isValid(geom)
        : true;
    drawingCallback(feature, ok);
  }
};
const imageStyle = new CircleStyle({
  radius: 7,
  fill: new Fill({ color: "#02818F" }),
  stroke: new Stroke({ color: "white", width: 2 }),
});
const strokeStyle = new Stroke({ color: "#02818F", width: 2 });
const fillStyle = new Fill({ color: "#aaaacc20" });
const pointDraw = new Draw({
  source: drawInteractionSource,
  type: GeometryType.POINT,
  stopClick: true,
  style: new Style({
    image: imageStyle,
  }),
});
pointDraw.on("drawend", onDrawEnd);

const lineDraw = new Draw({
  source: drawInteractionSource,
  type: GeometryType.LINE_STRING,
  stopClick: true,
  style: new Style({
    image: imageStyle,
    stroke: strokeStyle,
  }),
});
lineDraw.on("drawend", onDrawEnd);

const polygonDraw = new Draw({
  source: drawInteractionSource,
  type: GeometryType.POLYGON,
  stopClick: true,
  style: function (f) {
    const geom = f.getGeometry();
    const ok =
      geom.getType() === "Polygon" &&
      geom.getLinearRing().getCoordinates().length > 3
        ? isValid(f.getGeometry())
        : true;
    if (geom.getType() === "LineString") {
      return new Style({ stroke: new Stroke({ color: "transparent" }) });
    }
    return new Style({
      image: imageStyle,
      stroke: ok
        ? strokeStyle
        : new Stroke({
            color: "#e25f5f",
            width: 3,
          }),
      fill: ok ? fillStyle : new Fill({ color: "#e25f5f20" }),
    });
  },
});
polygonDraw.on("drawend", onDrawEnd);

const selectFeature = new Select({
  layers: selectableLayers,
  style: drawLayerStyle,
});
selectFeature.on("select", function (e) {
  selectFeature.getFeatures().clear();
  if (e.selected.length) {
    const feature = e.selected[0];
    feature.set("id", uniqueId("feature"));
    const isBygning = feature.get("bygningstype") ? true : false;
    const isVej =
      feature.get("synligVejkant") || feature.get("vejkategori") ? true : false;
    const isVandløb = feature.get("vandloebstype") ? true : false;
    if (isBygning) {
      feature.set("fipFeatureTypeId", 2);
    } else if (isVej) {
      feature.set("fipFeatureTypeId", 3);
    } else if (isVandløb) {
      feature.set("fipFeatureTypeId", 4);
    } else {
      feature.set("fipFeatureTypeId", 1);
    }
    finishedDrawingSource.addFeature(feature);
    if (drawingCallback) {
      drawingCallback(feature, true);
    }
  }
});

export const addDrawInteractionsToMap = (map: Map) => {
  addLayerToMap(map, tempDrawLayer);
  addLayerToMap(map, drawLayer);
  map.addInteraction(pointDraw);
  map.addInteraction(lineDraw);
  map.addInteraction(polygonDraw);
  map.addInteraction(modify);
  modify.setActive(true);
  addLayerToMap(map, modifyLayer);
  addLayerToMap(map, modifyLayerPolygonVertices);
  map.addInteraction(selectFeature);
  // Vis at features kan vælges i kortet, lav pointer-cursor på hover
  map.on("pointermove", function (e) {
    const mapElement = document.getElementById(map.getTarget() as string);
    if (mapElement) {
      mapElement.style.cursor = "default";
      var hit = map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        if (
          selectableLayers.indexOf(
            layer as VectorLayer<VectorSource<Geometry>>
          ) > -1
        )
          return true;
      });
      if (selectFeature.getActive() && hit) {
        mapElement.style.cursor = "pointer";
      }
    }
  });
};

export const disableAllInteractions = () => {
  pointDraw.setActive(false);
  lineDraw.setActive(false);
  polygonDraw.setActive(false);
  selectFeature.setActive(false);
  // modify.setActive(false);
};
export const activatePointDraw = () => {
  disableAllInteractions();
  pointDraw.setActive(true);
};
export const activateLineDraw = () => {
  disableAllInteractions();
  lineDraw.setActive(true);
};
export const activatePolygonDraw = () => {
  disableAllInteractions();
  polygonDraw.setActive(true);
};
export const activateSelectFeature = () => {
  disableAllInteractions();
  selectFeature.setActive(true);
};

export const activateExplore = () => {
  disableAllInteractions();
};
