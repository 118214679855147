import { Sagsakt } from "interfaces/IIndberetning";
import { Fil } from "routes/Fip/FipIndberet";
import IconDrawPolygon from "components/icons/DrawPolygon";
import ThreeDotsVertical from "components/icons/ThreeDotsVertical";
import Trash from "components/icons/Trash";
import Download from "components/icons/Download";
import { formatDashedDate, formatDateString } from "util/date";
import { ShowModalSignature } from "../Modal/Modal";
import ModalConfirmDelete from "../Indberet/ModalConfirmDelete";
import GeometryAvatar from "../GeometryAvatar";
import { translateGeometryType, wktFormat } from "util/ol";
import { uniqueId } from "lodash";

export default function FilListItem(props: {
  type: "compressed" | "normal";
  index?: number;
  id: number;
  fil: Sagsakt | Fil;
  hoveredFeatures: (string | number)[];
  hideDeleteOption?: boolean;
  hideEditOptions?: boolean;
  hideOptions?: boolean;
  downloadFileLink?: string;
  downloadGeometryLink?: string;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (f) => void;
  removeFil: () => void;
  onEditGeometryClick: () => void;
  onEditClick: () => void;
  showModal: ShowModalSignature;
  closeModal: (withCallback: boolean) => void;
}) {
  const {
    id,
    index,
    fil,
    hideDeleteOption,
    hideEditOptions,
    hideOptions,
    downloadFileLink,
    downloadGeometryLink,
    hoveredFeatures,
    onClick,
    onMouseEnter,
    onMouseLeave,
    removeFil,
    onEditClick,
    onEditGeometryClick,
    showModal,
    closeModal,
    type,
  } = props;
  let geometrytype =
    typeof fil.geometri === "string"
      ? wktFormat.readFeature(fil.geometri).getGeometry().getType()
      : fil.geometri.getGeometry().getType();
  geometrytype = geometrytype ? translateGeometryType(geometrytype) : "";
  const dropdownMenuButtonId = uniqueId("dropdownMenuButton");
  return (
    <>
      {type === "compressed" && (
        <p className="mb-0">
          {fil.beskrivelse.split("\n").map((item, i) => {
            // beskrivelser kommer fra textarea, så kan have linjeskift
            return (
              <span key={i}>
                {item}
                <br />
              </span>
            );
          })}
        </p>
      )}
      <Wrapper
        type={type === "compressed" ? "div" : "li"}
        hovered={hoveredFeatures.indexOf(id) > -1}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <button
          type="button"
          className={`list-group-item__action ${
            hoveredFeatures.indexOf(id) > -1 ? "hover" : ""
          }`}
          aria-label="Vis i kort"
          title=""
          onClick={onClick}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClick(fil.geometri);
            }
          }}
        ></button>
        <div className="row align-items-top">
          <div className="col-auto d-none d-md-flex">
            <GeometryAvatar feature={fil.geometri} />
          </div>
          <div className="col ms-md-n2 text-truncate">
            <span className="h4 mb-0 name text-truncate">
              {index !== undefined && <span>#{index + 1}</span>}
              {type === "compressed" ? geometrytype : fil.originalFilNavn}
            </span>

            {index !== undefined && (
              <p className="mb-0 small">
                <span aria-hidden>{formatDateString(fil.dato)}</span>
                <span className="visually-hidden">
                  {formatDashedDate(fil.dato)}
                </span>
              </p>
            )}
            {fil.beskrivelse && type === "normal" && (
              <p className="mb-0">
                {fil.beskrivelse.split("\n").map((item, i) => {
                  // beskrivelser kommer fra textarea, så kan have linjeskift
                  return (
                    <span key={i}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </p>
            )}
            {type === "compressed" &&
              downloadFileLink !== undefined &&
              !hideOptions && (
                <p className="mb-0">
                  <a
                    className="small"
                    href={downloadFileLink}
                    download
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    <Download /> <strong>{fil.originalFilNavn} (hent)</strong>
                  </a>
                </p>
              )}
          </div>
          <div className="col-auto">
            {!hideOptions && (
              <div className="dropdown">
                <button
                  className="btn btn-icon btn-outline-primary"
                  aria-label="Funktioner"
                  title="Funktioner"
                  type="button"
                  id={dropdownMenuButtonId}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ThreeDotsVertical />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby={dropdownMenuButtonId}
                >
                  {!hideEditOptions && (
                    <>
                      <li>
                        <button
                          type="button"
                          className="dropdown-item"
                          aria-label="Rediger"
                          title="Rediger"
                          onClick={onEditClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-fill me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>
                          Ret oplysninger
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="dropdown-item"
                          aria-label="Ret geometri"
                          title="Ret geometri"
                          onClick={onEditGeometryClick}
                        >
                          <IconDrawPolygon class="me-2" />
                          Ret geometri
                        </button>
                      </li>
                    </>
                  )}
                  {!hideDeleteOption && (
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        aria-label="Slet"
                        title="Slet"
                        onClick={(e) => {
                          e.stopPropagation();
                          showModal({
                            title: "Slet fil",
                            content: (
                              <ModalConfirmDelete
                                type={"fil"}
                                confirm={() => {
                                  removeFil();
                                }}
                                closeModal={closeModal}
                              />
                            ),
                          });
                        }}
                      >
                        <Trash class="me-2" />
                        Slet
                      </button>
                    </li>
                  )}

                  {downloadGeometryLink !== undefined && (
                    <li>
                      <a
                        href={downloadGeometryLink}
                        rel="noreferrer"
                        className="dropdown-item"
                        download
                      >
                        <Download class="me-2" />
                        Gem geometri
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
}
const Wrapper = (props: {
  children: any;
  type: "li" | "div";
  hovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  const { type, children, hovered, onMouseEnter, onMouseLeave } = props;
  const classNames = `list-group-item list-group-item--card p-3 ${
    hovered ? "hover" : ""
  }`;
  if (type === "li") {
    return (
      <li
        className={classNames}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </li>
    );
  }
  return (
    <div
      className={classNames}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};
