import { tema } from "config/FipConfig";
import { MapContext } from "context/MapProvider";
import { useRouter } from "hooks/useRouter";
import { useContext, useEffect, useState } from "react";

export default function LayerToggleTemaselect() {
  const [value, setValue] = useState("0");
  const { dispatchToMap, mapState } = useContext(MapContext);
  useEffect(() => {
    if (mapState.tema) setValue(mapState.tema.id.toString());
  }, [mapState.tema]);
  const { isFipGenerel } = useRouter();
  if (!isFipGenerel) return null;

  return (
    <div className="c-layers__section">
      <select
        value={value}
        className={"form-select form-select-sm"}
        aria-label={"Vælg korttema"}
        onChange={(e) => {
          const temaId = e.target.value;
          setValue(temaId);
          const t = tema.find((t) => t.id.toString() === temaId);
          if (t) {
            dispatchToMap({
              type: "SET_LAYERS",
              baselayers: t.baseLayers,
              layers: t.layers,
            });
          }
        }}
      >
        {tema
          .map((t) => {
            return { value: t.id.toString(), label: t.name };
          })
          .map((option) => {
            const { value, label } = option;
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
      </select>
      {mapState.tema && mapState.tema.id.toString() !== value && (
        <div
          className="text-start d-flex align-items-start bg-tint-primary text-shade-primary fw-normal py-2 px-3 p-1 mt-2"
          role="alert"
        >
          <p className="mb-0 small">
            Du har valgt et andet kort end det du er igang med at indberette i.
            <button
              onClick={() => {
                if (mapState.tema) setValue(mapState.tema?.id.toString());
              }}
              type="button"
              className="btn btn-link d-inline p-0 btn-sm"
            >
              Skift til indberetningskort
            </button>
          </p>
        </div>
      )}
    </div>
  );
}
