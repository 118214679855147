import { useEffect, useState } from "react";

export default function ValidationErrorMessage(props: {
  show: boolean;
  message: string;
  notify: boolean;
  resetNotify: () => void;
}) {
  const { show, message, notify, resetNotify } = props;
  const [activateVoiceOverMessage, setActivateVoiceOverMessage] =
    useState(false);

  useEffect(() => {
    if (notify) {
      setTimeout(() => {
        resetNotify();
        setActivateVoiceOverMessage(true);
      }, 300);
    }
  }, [notify, resetNotify]);

  if (!show) return null;
  return (
    <div className="invalid-feedback" aria-live="polite">
      {message}
      {notify && activateVoiceOverMessage && (
        <span className="visually-hidden"> {message}</span>
      )}
    </div>
  );
}
