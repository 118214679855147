import { useLayoutEffect, useRef } from "react";

export default function LoggetUd() {
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div className="mt-5">
      <h1 tabIndex={-1} ref={titelRef}>
        Du er nu logget ud af FIP
      </h1>
      <span>
        <p>
          Hvis andre har adgang til denne computer, smartphone eller tablet,
          skal du:
        </p>
        <p>
          Lukke internetbrowseren helt ned - ikke kun fanebladet, eller slette
          internetbrowserens midlertidige filer.
        </p>
      </span>
    </div>
  );
}
