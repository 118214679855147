interface ISectionProps {
  children: any;
  className?: string;
}
export default function Section(props: ISectionProps) {
  const { children, className } = props;
  return (
    <section className={className ? `l-section ${className}` : "l-section"}>
      {children}
    </section>
  );
}
