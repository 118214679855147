import { AuthContext, Bruger } from "context/AuthProvider";
import { useContext } from "react";
import Dropdown from "../Dropdown/Dropdown";
import authApi from "api/auth";
import { rolleStore } from "config/FipConfig";
import { useLocation } from "react-router";
interface ISkiftBrugerProps {
  brugere: Bruger[];
  currentBruger?: Bruger;
  setCurrentBruger: (bruger: Bruger) => void;
}
export default function DemoSkiftBruger(props: ISkiftBrugerProps) {
  const { brugere, currentBruger } = props;
  const { authState, setAuthState } = useContext(AuthContext);
  const location = useLocation();
  const renderMenu = () => {
    return (
      <>
        {brugere.map((b) => {
          return (
            <button
              key={`${b.personId} - ${b.navn}`}
              className="dropdown-item"
              onClick={() => {
                let prefix_url = "";
                if (location.pathname.toLowerCase().includes("/stednavn")) {
                  prefix_url = "stednavn/";
                }
                authApi.getMe(b.personId, prefix_url).then((bruger) => {
                  setAuthState({
                    ...authState,
                    currentUser: bruger,
                  });
                });
              }}
            >
              {b.navn}
            </button>
          );
        })}
      </>
    );
  };
  return (
    <div className="demo-user" aria-hidden="true">
      <h6 className="fw-bolder">DEMO bruger</h6>
      <Dropdown dropdownMenu={renderMenu()} buttonSize="small">
        {currentBruger ? currentBruger.navn : "Vælg"}
      </Dropdown>
      <h6 className="fw-bolder ms-1">
        {`rolle: ${rolleStore[authState.currentUser.rolleId].name}`}
        <br />
        {`personId: ${authState.currentUser.personId}`}
      </h6>
    </div>
  );
}
