export default function HandPointing(props: {
  class?: string;
  ariaLabel?: string;
}) {
  const { ariaLabel } = props;
  const classNames = props.class ? `${props.class} bi` : "bi";
  return (
    <svg aria-label={ariaLabel ? ariaLabel : ""} className={classNames} fill="currentColor" width="1.05em" height="auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <title>{ariaLabel}</title>
      <g transform="matrix(2,0,0,2,0,0)"><g>
        <path d="M10.64,6a5,5,0,0,1,5.21,3.77,1,1,0,1,0,1.94-.48A7,7,0,0,0,10.5,4,7.1,7.1,0,0,0,4,10.46a7,7,0,0,0,5.14,7.29,1,1,0,0,0,1.23-.7,1,1,0,0,0-.71-1.23A5,5,0,0,1,6,10.61,5.09,5.09,0,0,1,10.64,6Z"></path>
        <path d="M11,20a9,9,0,0,1-9-9.26A9.11,9.11,0,0,1,10.74,2,9,9,0,0,1,20,11a1,1,0,0,0,2,0A11,11,0,0,0,10.69,0,11.14,11.14,0,0,0,0,10.69,11,11,0,0,0,11,22a1,1,0,0,0,0-2Z"></path>
        <path d="M23.51,16.43,11.86,10.24a.92.92,0,0,0-1.09.16.94.94,0,0,0-.17,1.08l6.19,12a.94.94,0,0,0,.82.5h0a.94.94,0,0,0,.82-.54l1.68-3.71,3.35-1.68a.9.9,0,0,0,.51-.81A.92.92,0,0,0,23.51,16.43Z"></path>
      </g></g></svg>

  );
}
