import { getIndberetning, redigerHoeringspartMappe } from "api/fip";
import { AuthContext } from "context/AuthProvider";
import { format } from "date-fns";
import { Hoeringsmappe, Hoeringssag } from "interfaces/IIndberetning";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { ModalContext } from "../../Modal/Modal";

export default function RedigerHoeringsFristModal(props: {
  message: string;
  indberetning: Hoeringssag;
  hoeringspartmappe: Hoeringsmappe;
  setIndberetning: Function;
}) {
  const firstFocusRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    firstFocusRef.current?.focus();
  }, []);
  const { closeModal } = useContext(ModalContext);
  const { authState } = useContext(AuthContext);
  const { currentUser } = authState;
  const { indberetning, setIndberetning, hoeringspartmappe } = props;

  const [values, setValues] = useState({
    frist: hoeringspartmappe.svarFrist
      ? format(new Date(hoeringspartmappe.svarFrist), "yyyy-MM-dd")
      : "",
  });

  function handleChange(e) {
    let value = e.target.value;

    setValues({
      ...values,
      [e.target.name]: value,
    });
  }
  const acceptPrompt = () => {
    // Do stuff when accepted

    const frist = values.frist
      ? format(new Date(values.frist), "yyyy-MM-dd'T00:00:00'") // TODO: Is this correct?
      : "";
    if (hoeringspartmappe !== undefined) {
      let nyHoeringspartmappe = {
        ...hoeringspartmappe,
        ...{ svarFrist: frist },
      };

      redigerHoeringspartMappe(currentUser, nyHoeringspartmappe).then((e) => {
        getIndberetning(indberetning.id, currentUser.personId).then((e) => {
          setIndberetning(e);
        });
      });
    } else {
      console.error("fejl i rediger indberetning");
    }
  };
  return (
    <div>
      <div className=" gap-2 d-flex flex-column">
        <div className="gap-2">
          <label className={"form-label"}>Frist</label>
          <input
            ref={firstFocusRef}
            className={"form-select"}
            onChange={handleChange}
            type="date"
            id="start"
            name="frist"
            value={values.frist}
          />
        </div>
      </div>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            acceptPrompt();
            closeModal(false);
          }}
        >
          OK
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
