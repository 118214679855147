import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import IconDrawPolygon from "components/icons/DrawPolygon";
import Trash from "components/icons/Trash";
import ThreeDotsVertical from "components/icons/ThreeDotsVertical";
import { ShowModalSignature } from "../Modal/Modal";
import ModalConfirmDelete from "../Indberet/ModalConfirmDelete";
import GeometryAvatar from "../GeometryAvatar";
import { uniqueId } from "lodash";

export default function FeatureItem(props: {
  onDeleteClick: Function;
  kategori: string;
  feature: Feature<Geometry>;
  hovered: boolean;
  setHoveredFeatures: (ids: string[]) => void;
  zoomToDrawing: (drawing: Feature<Geometry>) => void;
  onEditGeometryClick: () => void;
  showModal: ShowModalSignature;
  closeModal: (withCallback: boolean) => void;
}) {
  const {
    feature,
    hovered,
    setHoveredFeatures,
    zoomToDrawing,
    kategori,
    onDeleteClick,
    onEditGeometryClick,
    showModal,
    closeModal,
  } = props;
  const id = feature.get("id");
  const dropdownMenuButtonId = uniqueId("dropdownMenuButton");
  return (
    <div
      className={`list-group-item list-group-item--card p-3 ${
        hovered ? "hover" : ""
      }`}
      onMouseEnter={() => setHoveredFeatures([id])}
      onMouseLeave={() => setHoveredFeatures([])}
    >
      <button
        type="button"
        className={`list-group-item__action ${hovered ? "hover" : ""}`}
        aria-label="Vis i kort"
        title=""
        onClick={() => {
          zoomToDrawing(feature);
        }}
      ></button>
      <div className="row align-items-top">
        <div className="col-auto d-none d-md-flex">
          <GeometryAvatar feature={feature} />
        </div>
        <div className="col ms-md-n2">
          <span className="h4 mb-0 name">{kategori}</span>
        </div>
        <div className="col-auto">
          <div className="dropdown">
            <button
              className="btn btn-icon btn-outline-primary"
              aria-label="Funktioner"
              title="Funktioner"
              type="button"
              id={dropdownMenuButtonId}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(e) => e.stopPropagation()}
            >
              <ThreeDotsVertical />
            </button>
            <ul
              className="dropdown-menu"
              aria-labelledby={dropdownMenuButtonId}
            >
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  aria-label="Ret geometri"
                  title="Ret geometri"
                  onClick={onEditGeometryClick}
                >
                  <IconDrawPolygon class="me-2" />
                  Ret geometri
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="dropdown-item"
                  aria-label="Slet"
                  title="Slet"
                  onClick={(e) => {
                    e.stopPropagation();
                    showModal({
                      title: "Slet opdatering",
                      content: (
                        <ModalConfirmDelete
                          type={"opdatering"}
                          confirm={() => {
                            onDeleteClick();
                          }}
                          closeModal={closeModal}
                        />
                      ),
                    });
                  }}
                >
                  <Trash class="me-2" />
                  Slet
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
