import { Hoeringssag, Tema } from "interfaces/IIndberetning";
import { Feature } from "ol";
import { convertFeatureToWkt, convertWktToFeature } from "util/ol";
import { NyIndberetningState } from "./FipIndberet";

export interface NyIndberetningFormValidation {
  form: boolean;
  fields: {
    tema: { message: string; notify: boolean };
    emne: { message: string; notify: boolean };
    beskrivelse: { message: string; notify: boolean };
    vilkårAccepteret: { message: string; notify: boolean };
    filEllerGeometri: { message: string; notify: boolean };
    email: { message: string; notify: boolean };
  };
}

/**
 *
 * @param indberetning
 * @returns Empty string if valid,
 */
export function validateNyIndberetningForm(
  indberetning: NyIndberetningState
): NyIndberetningFormValidation {
  const { beskrivelse, emne, fil, geometri, vilkårAccepteret, tema, email } =
    indberetning;
  const validation: NyIndberetningFormValidation = {
    form: true,
    fields: {
      tema: { message: "", notify: false },
      emne: { message: "", notify: false },
      beskrivelse: { message: "", notify: false },
      vilkårAccepteret: { message: "", notify: false },
      filEllerGeometri: { message: "", notify: false },
      email: { message: "", notify: false },
    },
  };
  if (!tema.value) {
    validation.form = false;
    validation.fields.tema.message = "Tema skal udfyldes";
    validation.fields.tema.notify = true;
  }
  if (!emne.value) {
    validation.form = false;
    validation.fields.emne.message = "Emne skal udfyldes";
    validation.fields.emne.notify = true;
  }
  if (!beskrivelse.value) {
    validation.form = false;
    validation.fields.beskrivelse.message = "Beskrivelse skal udfyldes";
    validation.fields.beskrivelse.notify = true;
  }
  if (fil.value.length === 0 && geometri.value.length === 0) {
    validation.form = false;
    validation.fields.filEllerGeometri.message =
      "Indberetning skal indeholde mindst én fil eller geometri";
    validation.fields.filEllerGeometri.notify = true;
  }

  if (!vilkårAccepteret.value) {
    validation.form = false;
    validation.fields.vilkårAccepteret.message =
      "Betingelserne skal være accepteret";
    validation.fields.vilkårAccepteret.notify = true;
  }
  if (!email.value) {
    validation.form = false;
    validation.fields.email.message = "Email skal være udfyldt";
    validation.fields.email.notify = true;
  }
  return validation;
}

export function prepareSag(
  indberetning: NyIndberetningState,
  tema: Tema
): Hoeringssag {
  const { beskrivelse, emne, fil, geometri } = indberetning;
  const { domainId, id: temaId } = tema;
  const requestDTO: Hoeringssag = {
    bbox: "",
    beskrivelse: beskrivelse.value,
    domaeneType: "",
    domaeneTypeId: domainId,
    emne: emne.value,
    esdhJournalId: "",
    esdhJournalNummer: "",
    fraTs: null,
    frist: null,
    id: 0,
    indberetningsmappe: [
      {
        beskrivelse: beskrivelse.value,
        domaeneType: "",
        domaeneTypeId: domainId,
        emne: emne.value,
        fejlSet: "",
        fil: fil.value.map((f, i) => {
          return {
            beskrivelse: f.beskrivelse,
            dato: f.dato,
            filId: f.filId,
            filreference: "",
            geometri: convertFeatureToWkt(f.geometri),
            geometriType: f.geometri.getGeometry().getType(),
            personId: 0,
            personNavn: "",
            id: 0,
            kortforsyningId: "",
            mimetype: "",
            nr: i + 1,
            openLayersId: "",
            originalFilNavn: "",
            supplInfo: "{}",
            udpeget: false,
            url: "",
          };
        }),
        geometri: geometri.value.map((g, i) => {
          return {
            aendringType: g.aendringType,
            aendringTypeId: g.aendringTypeId,
            beskrivelse: g.beskrivelse,
            dato: g.dato,
            featureType: g.featureType,
            featureTypeId: g.featureTypeId,
            filId: 0,
            filreference: "",
            geometri: convertFeatureToWkt(g.drawing),
            geometriType: g.drawing.getGeometry().getType(),
            personId: 0,
            personNavn: "",
            id: 0,
            kortforsyningId: "",
            mimetype: "",
            nr: i + 1,
            openLayersId: "", // g.drawing.getId().toString(),
            originalFilNavn: "",
            supplInfo: "{}",
            udpeget: false,
            url: "",
          };
        }),
        id: 0,
        korttemaId: temaId,
      },
    ],
    korrespondance: [],
    oprettetTs: null,
    prioritet: 0,
    ressource: [],
    sagsbehandlermappe: [],
    sagsid: 0,
    sagsnummer: "",
    statusId: 1,
    tilTs: null,
  };
  return requestDTO;
}

/**
 *
 * @param sag
 * @returns
 */
export const getSagsaktFeaturesFromHoeringssag = (
  sag: Hoeringssag
): Feature<any>[] => {
  let features: Feature<any>[] = [];
  const {
    indberetningsmappe,
    hoeringsansvarligmappe,
    hoeringspartmappe,
    sagsbehandlermappe,
  } = sag;
  if (indberetningsmappe && indberetningsmappe.length) {
    indberetningsmappe.forEach((mappe) => {
      const { geometri, fil } = mappe;
      if (geometri && fil) {
        features = [
          ...features,
          ...geometri.map((g) => {
            const feature = convertWktToFeature(g.geometri);
            feature.set("mappe", "indberetning");
            feature.set("id", g.id);
            feature.set("geometriId", g.geometriId);
            feature.set("beskrivelse", g.beskrivelse);
            feature.set("type", "Geometri");
            return feature;
          }),
        ];
        features = [
          ...features,
          ...fil.map((g) => {
            const feature = convertWktToFeature(g.geometri);
            feature.set("mappe", "indberetning");
            feature.set("id", g.id);
            feature.set("geometriId", g.geometriId);
            feature.set("beskrivelse", g.beskrivelse);
            feature.set("type", "Fil");
            return feature;
          }),
        ];
      } else {
        features = [...features];
      }
    });
  }
  if (hoeringsansvarligmappe && hoeringsansvarligmappe.length) {
    hoeringsansvarligmappe.forEach((mappe) => {
      const { geometri, fil } = mappe;
      if (geometri) {
        features = [
          ...features,
          ...geometri.map((g) => {
            const feature = convertWktToFeature(g.geometri);
            feature.set("mappe", "hoeringsansvarlig");
            feature.set("id", g.id);
            feature.set("geometriId", g.geometriId);
            feature.set("beskrivelse", g.beskrivelse);
            feature.set("type", "Geometri");
            return feature;
          }),
        ];
      }
      if (fil) {
        features = [
          ...features,
          ...fil.map((g) => {
            const feature = convertWktToFeature(g.geometri);
            feature.set("mappe", "hoeringsansvarlig");
            feature.set("id", g.id);
            feature.set("geometriId", g.geometriId);
            feature.set("beskrivelse", g.beskrivelse);
            feature.set("type", "Fil");
            return feature;
          }),
        ];
      }
    });
  }
  if (hoeringspartmappe && hoeringspartmappe.length) {
    hoeringspartmappe.forEach((mappe) => {
      const { geometri, fil } = mappe;
      if (geometri) {
        features = [
          ...features,
          ...geometri.map((g) => {
            const feature = convertWktToFeature(g.geometri);
            feature.set("mappe", "hoeringspart");
            feature.set("id", g.id);
            feature.set("geometriId", g.geometriId);
            feature.set("beskrivelse", g.beskrivelse);
            feature.set("type", "Geometri");
            return feature;
          }),
        ];
      }
      if (fil) {
        features = [
          ...features,
          ...fil.map((g) => {
            const feature = convertWktToFeature(g.geometri);
            feature.set("mappe", "hoeringspart");
            feature.set("id", g.id);
            feature.set("geometriId", g.geometriId);
            feature.set("beskrivelse", g.beskrivelse);
            feature.set("type", "Fil");
            return feature;
          }),
        ];
      }
    });
  }
  if (sagsbehandlermappe && sagsbehandlermappe.length) {
    sagsbehandlermappe.forEach((mappe) => {
      const { geometri, fil } = mappe;
      features = [
        ...features,
        ...geometri.map((g) => {
          const feature = convertWktToFeature(g.geometri);
          feature.set("mappe", "sagsbehandler");
          feature.set("id", g.id);
          feature.set("geometriId", g.geometriId);
          feature.set("beskrivelse", g.beskrivelse);
          feature.set("type", "Geometri");
          return feature;
        }),
      ];
      features = [
        ...features,
        ...fil.map((g) => {
          const feature = convertWktToFeature(g.geometri);
          feature.set("mappe", "sagsbehandler");
          feature.set("id", g.id);
          feature.set("geometriId", g.geometriId);
          feature.set("beskrivelse", g.beskrivelse);
          feature.set("type", "Fil");
          return feature;
        }),
      ];
    });
  }

  /* return features.map((f) => {
    f.set("id", f.get("geometriId"));
    return f;
  }); */
  return features;
};

/**
 *
 * @param sag
 * @returns
 */

export const getDownloadGeometryLink = (
  indberetningId: number,
  sagsaktId: number
) => {
  let VIEWPARAMS = `viewparams=sagId:${indberetningId}&sagsaktId:${sagsaktId}`;
  let url = `${process.env.REACT_APP_API_BASEURL}proxy.ashx?service=WFS&version=1.1.0&request=GetFeature&typeName=${process.env.REACT_APP_GEOSERVER_WORKSPACE}:SagDownload&maxFeatures=100&outputFormat=SHAPE-ZIP&${VIEWPARAMS}`;

  return url;
};
