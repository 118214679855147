import { useLayoutEffect, useRef } from "react";

export default function Forbidden() {
  const titelRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    titelRef.current?.focus();
  }, []);
  return (
    <div>
      <div>
        <div>
          <div className="mt-5">
            <h1 tabIndex={-1} ref={titelRef}>
              Ingen adgang
            </h1>
            <p>Du har ikke adgang til dette indhold - login først</p>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
}
