import { baseUrl } from "api";
import { useContext, useState } from "react";
import { ModalContext } from "../Modal/Modal";

export default function PrintModal(props: { sagId: number; personId: number }) {
  const { personId, sagId } = props;
  const { closeModal } = useContext(ModalContext);
  const [sagsbeskrivelse, setSagsbeskrivelse] = useState(true);
  const [kort, setKort] = useState(true);
  const [korrespondance, setKorrespondance] = useState(true);
  const [kommentarer, setKommentarer] = useState(true);
  const [geometrier, setGeometrier] = useState(true);
  const [filer, setFiler] = useState(true);

  return (
    <div>
      <p className="form-label">
        Vælg hvilke dele af indberetningen, du ønsker at udskrive:
      </p>

      <form
        action={`${baseUrl}download/pdf/`}
        method="POST"
        onSubmit={() => {
          closeModal(true);
        }}
        target="_blank"
      >
        <input
          style={{ display: "none" }}
          name="sagId"
          value={sagId}
          readOnly
        />
        <input
          style={{ display: "none" }}
          name="personId"
          value={personId}
          readOnly
        />
        <div className="form-check">
          <label className="form-check-label" htmlFor="Sagsbeskrivelse1">
            Sagsbeskrivelse
          </label>
          <input
            name="sagsbeskrivelse"
            className="form-check-input"
            type="checkbox"
            id="Sagsbeskrivelse1"
            checked={sagsbeskrivelse}
            value={sagsbeskrivelse ? 1 : 0}
            onChange={(e) => setSagsbeskrivelse(e.target.checked)}
          />
        </div>

        <div className="form-check">
          <label className="form-check-label" htmlFor="Kort1">
            Kort
          </label>
          <input
            name="kort"
            className="form-check-input"
            type="checkbox"
            id="Kort1"
            checked={kort}
            value={kort ? 1 : 0}
            onChange={(e) => setKort(e.target.checked)}
          />
        </div>

        <div className="form-check">
          <label className="form-check-label" htmlFor="Korrespondance1">
            Korrespondance
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="Korrespondance1"
            name="korrespondance"
            checked={korrespondance}
            value={korrespondance ? 1 : 0}
            onChange={(e) => setKorrespondance(e.target.checked)}
          />
        </div>

        <div className="form-check">
          <label className="form-check-label" htmlFor="Kommentarer1">
            Kommentarer
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="Kommentarer1"
            name="kommentarer"
            checked={kommentarer}
            value={kommentarer ? 1 : 0}
            onChange={(e) => setKommentarer(e.target.checked)}
          />
        </div>

        <div className="form-check">
          <label className="form-check-label" htmlFor="Geometrier1">
            Geometrier
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="Geometrier1"
            name="geometrier"
            checked={geometrier}
            value={geometrier ? 1 : 0}
            onChange={(e) => setGeometrier(e.target.checked)}
          />
        </div>

        <div className="form-check">
          <label className="form-check-label" htmlFor="Filer1">
            Filer
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="Filer1"
            name="filer"
            checked={filer}
            value={filer ? 1 : 0}
            onChange={(e) => setFiler(e.target.checked)}
          />
        </div>

        {/* modal footer */}
        <div className="mt-4 gap-2 d-flex">
          <button type="submit" className="btn btn-primary">
            Print
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              closeModal(true);
            }}
          >
            Fortryd
          </button>
        </div>
      </form>
    </div>
  );
}
