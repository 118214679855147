import { createContext, useContext, useEffect, useReducer } from "react";
import { Feature, Map } from "ol";
import {
  activateLineDraw,
  activatePointDraw,
  activatePolygonDraw,
  activateSelectFeature,
  disableAllInteractions,
  finishedDrawingSource,
  modifySource,
  tempDrawingSource,
} from "config/interactions";
import { FipLayer } from "interfaces/FipLayer";
import Geometry from "ol/geom/Geometry";
import { addFeatureToSource, isValid, zoomToFeature } from "util/ol";
import { FipLayerFolder, isFipLayer, Tema } from "interfaces/IIndberetning";
import { ToastContext } from "./ToastProvider";
import { LayoutContext } from "./LayoutProvider";
import { usePrevious } from "hooks/usePrevious";
import { SessionTimerContext } from "./SessionTimerProvider";

export type DrawTool = undefined | "point" | "line" | "polygon" | "udpeg";

export type MapState = {
  map?: Map;
  baselayers: FipLayer[];
  layers: (FipLayer | FipLayerFolder)[];
  activeDrawTool: DrawTool;
  editGeometryState: {
    active: boolean;
    originalGeometry: Geometry | undefined;
  };
  hoveredFeatures: (string | number)[];
  viewState: {
    center: number[];
    extent: number[];
    resolution: number;
    zoom: number;
  };
  tema: Tema | undefined;
};
export type MapAction =
  | { type: "SET_MAP"; map: Map }
  | {
      type: "SET_VIEWSTATE";
      viewState: {
        center: number[];
        resolution: number;
        zoom: number;
        extent: number[];
      };
    }
  | {
      type: "SET_LAYERS";
      baselayers: FipLayer[];
      layers: (FipLayer | FipLayerFolder)[];
    }
  | {
      type: "SET_LAYER_LOADING";
      layer: FipLayer;
      loading: boolean;
    }
  | {
      type: "SET_VISIBLE_BASELAYER";
      layer: FipLayer;
    }
  | {
      type: "SET_LAYER_VISIBILITY";
      layer: FipLayer;
      visible: boolean;
    }
  | {
      type: "SET_DRAWTOOL";
      drawTool: DrawTool;
    }
  | { type: "SET_HOVERED_FEATURES"; hoveredFeatures: (string | number)[] }
  | {
      type: "EDIT_GEOMETRY";
      geometryToEdit: Feature<Geometry>;
    }
  | {
      type: "CANCEL_EDIT_GEOMETRY";
    }
  | {
      type: "SAVE_EDIT_GEOMETRY";
      clonedGeometry?: Geometry;
    }
  | { type: "SET_TEMA"; tema: Tema | undefined };

const initialState: MapState = {
  map: undefined,
  layers: [],
  baselayers: [],
  activeDrawTool: undefined,
  hoveredFeatures: [],
  editGeometryState: {
    active: false,
    originalGeometry: undefined,
  },
  viewState: {
    center: [0, 0],
    extent: [0, 0, 0, 0],
    resolution: 0,
    zoom: 0,
  },
  tema: undefined,
};

export const MapContext = createContext<{
  mapState: MapState;
  dispatchToMap: React.Dispatch<MapAction>;
}>({
  mapState: initialState,
  dispatchToMap: () => null,
});

const MapProvider = ({ children }) => {
  const toast = useContext(ToastContext);
  const { setPaneToShow } = useContext(LayoutContext);
  const { resetSessionTimer } = useContext(SessionTimerContext);
  const reducer = (state: MapState, action: MapAction): MapState => {
    switch (action.type) {
      case "SET_MAP":
        return { ...state, map: action.map };
      case "SET_VIEWSTATE":
        return { ...state, viewState: action.viewState };
      case "SET_LAYERS":
        return {
          ...state,
          baselayers: action.baselayers,
          layers: action.layers,
        };
      case "SET_VISIBLE_BASELAYER": {
        const { layer } = action;
        return {
          ...state,
          baselayers: state.baselayers.map((l) => {
            if (l === layer) {
              l.maplayer.setVisible(true);
              l.visible = true;
            } else {
              l.maplayer.setVisible(false);
              l.visible = false;
            }
            return l;
          }),
        };
      }
      case "SET_LAYER_VISIBILITY": {
        const { layer, visible } = action;
        layer.maplayer.setVisible(visible);
        return {
          ...state,
          layers: state.layers.map((l) => {
            if (isFipLayer(l)) {
              if (l === layer) {
                l.visible = visible;
              }
            } else {
              l.layers = l.layers.map((folderLayer) => {
                if (folderLayer === layer) {
                  folderLayer.visible = visible;
                }
                return folderLayer;
              });
            }

            return l;
          }),
        };
      }
      case "SET_LAYER_LOADING": {
        const { layer, loading } = action;
        let updated = false;
        state.baselayers = [
          ...state.baselayers.map((l) => {
            if (l.title === layer.title) {
              updated = true;
              return { ...l, loading };
            }
            return l;
          }),
        ];
        if (!updated) {
          state.layers = [
            ...state.layers.map((l) => {
              if (isFipLayer(l)) {
                if (l.title === layer.title) {
                  updated = true;
                  return { ...l, loading };
                }
              } else {
                l.layers = l.layers.map((folderLayer) => {
                  if (folderLayer.title === layer.title) {
                    updated = true;
                    return { ...folderLayer, loading };
                  }
                  return folderLayer;
                });
              }

              return l;
            }),
          ];
        }
        return updated ? { ...state } : state;
      }
      case "SET_DRAWTOOL":
        switch (action.drawTool) {
          case undefined:
            disableAllInteractions();
            break;
          case "point":
            activatePointDraw();
            break;
          case "line":
            activateLineDraw();
            break;
          case "polygon":
            activatePolygonDraw();
            break;
          case "udpeg":
            activateSelectFeature();
            break;
        }
        return { ...state, activeDrawTool: action.drawTool };
      case "SET_HOVERED_FEATURES":
        const hoverableFeatures = finishedDrawingSource
          .getFeatures()
          .concat(modifySource.getFeatures());
        hoverableFeatures.forEach((feature) => {
          feature.set(
            "hovered",
            action.hoveredFeatures.indexOf(feature.get("id")) > -1
              ? true
              : false
          );
        });
        return { ...state, hoveredFeatures: action.hoveredFeatures };

      case "EDIT_GEOMETRY": {
        cancelEditGeometrySideEffects(state);

        const { geometryToEdit } = action;
        const clonedOriginalGeometry = geometryToEdit.clone();
        if (finishedDrawingSource.hasFeature(geometryToEdit)) {
          finishedDrawingSource.removeFeature(geometryToEdit);
          modifySource.addFeature(geometryToEdit);
          addFeatureToSource(clonedOriginalGeometry, tempDrawingSource);
          if (state.map) zoomToFeature(state.map, geometryToEdit);
        }
        return {
          ...state,
          editGeometryState: {
            active: true,
            originalGeometry: clonedOriginalGeometry.getGeometry(),
          },
        };
      }
      case "SAVE_EDIT_GEOMETRY": {
        const { clonedGeometry } = action;
        const modifyFeatures = modifySource.getFeatures();
        const modifyFeature =
          modifyFeatures.length > 0 ? modifyFeatures[0] : undefined;
        const { originalGeometry } = state.editGeometryState;
        if (
          modifyFeature &&
          clonedGeometry &&
          originalGeometry &&
          !finishedDrawingSource.hasFeature(modifyFeature)
        ) {
          if (!isValid(clonedGeometry)) {
            modifyFeature.setGeometry(originalGeometry.clone());
            toast({
              type: "danger",
              content: {
                header: "Ugyldig geometri",
                message: `Den angivne geometri er ugyldig, hvilket kan skyldes, at geometrien "krydser" sig selv. Tegn venligst en ny geometri.`,
              },
            });
            return state;
          }
          // modifyFeature.setGeometry(originalGeometry);
          finishedDrawingSource.addFeature(modifyFeature);
          modifySource.clear();
          tempDrawingSource.clear();
          if (editFeatureCallback) {
            editFeatureCallback(modifyFeature);
          }
        }
        return { ...state, editGeometryState: initialState.editGeometryState };
      }
      case "CANCEL_EDIT_GEOMETRY": {
        cancelEditGeometrySideEffects(state);
        return { ...state, editGeometryState: initialState.editGeometryState };
      }
      case "SET_TEMA": {
        return { ...state, tema: action.tema };
      }
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { viewState } = state;
  useEffect(() => {
    resetSessionTimer();
  }, [viewState, resetSessionTimer]);
  const { activeDrawTool, editGeometryState } = state;
  const prevDrawTool = usePrevious(activeDrawTool);
  const prevEditState = usePrevious(editGeometryState);
  useEffect(() => {
    if (!prevDrawTool && activeDrawTool) {
      setPaneToShow("right");
    } else if (prevDrawTool && !activeDrawTool) {
      setPaneToShow("left");
    } else if (!prevEditState?.active && editGeometryState.active) {
      setPaneToShow("right");
    } else if (prevEditState?.active && !editGeometryState.active) {
      setPaneToShow("left");
    }
  }, [
    activeDrawTool,
    editGeometryState,
    prevDrawTool,
    prevEditState,
    setPaneToShow,
  ]);

  return (
    <MapContext.Provider value={{ mapState: state, dispatchToMap: dispatch }}>
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
function cancelEditGeometrySideEffects(state: MapState) {
  const { originalGeometry } = state.editGeometryState;
  const modifyFeatures = modifySource.getFeatures();
  const modifyFeature =
    modifyFeatures.length > 0 ? modifyFeatures[0] : undefined;
  if (
    modifyFeature &&
    originalGeometry &&
    !finishedDrawingSource.hasFeature(modifyFeature)
  ) {
    modifyFeature.setGeometry(originalGeometry);
    finishedDrawingSource.addFeature(modifyFeature);
    modifySource.clear();
    tempDrawingSource.clear();
  }
}

let editFeatureCallback: (feature: Feature<Geometry>) => void;
export const setEditFeatureCallback = (
  callback: (feature: Feature<Geometry>) => void
) => {
  editFeatureCallback = callback;
};
