export default function Skat() {
  return (
    <svg
      data-name="Group 1919"
      xmlns="http://www.w3.org/2000/svg"
      width="2.75em"
      height="auto"
      viewBox="0 0 61.3 61.3"
      style={{marginRight: ".75em"}}
    >
      <circle
        data-name="Ellipse 349"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(25.9 0)"
        fill="#0097a7"
      />
      <circle
        data-name="Ellipse 350"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(25.9 51.9)"
        fill="#0097a7"
        opacity="0.4"
      />
      <circle
        data-name="Ellipse 351"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(51.9 25.8)"
        fill="#0097a7"
        opacity="0.7"
      />
      <circle
        data-name="Ellipse 352"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(0 25.8)"
        fill="#0097a7"
        opacity="0.25"
      />
      <circle
        data-name="Ellipse 353"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(44.2 7.6)"
        fill="#0097a7"
        opacity="0.85"
      />
      <circle
        data-name="Ellipse 354"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(7.6 44.2)"
        fill="#0097a7"
        opacity="0.3"
      />
      <circle
        data-name="Ellipse 355"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(44.5 44.2)"
        fill="#0097a7"
        opacity="0.55"
      />
      <circle
        data-name="Ellipse 356"
        cx="4.7"
        cy="4.7"
        r="4.7"
        transform="translate(7.8 7.5)"
        fill="#0097a7"
        opacity="0.2"
      />
      <g data-name="Group 1916">
        <g data-name="Group 1914">
          <path
            data-name="Path 787"
            d="M38.9,26.8C38.6,24.8,37,24,35,24a7.638,7.638,0,0,0-2.7.6,1.636,1.636,0,0,0-1.4-1.9V21.6a.3.3,0,1,0-.6,0v1.1a1.772,1.772,0,0,0-1.4,1.9,5.4,5.4,0,0,0-2.7-.6c-2,0-3.6.9-3.9,2.8a2.9,2.9,0,0,0-2.9,3.3c.1,1.5,2,4,3.2,5.3h.9c-1.5-1.6-3.3-4.1-3.4-5.4a2.55,2.55,0,0,1,.5-1.9,2.393,2.393,0,0,1,1.6-.7V28a8.511,8.511,0,0,0,1.1,3,39.529,39.529,0,0,0,2.9,4.4h.9s-4-4.9-4.2-7.4c0,0-.6-3.4,3.3-3.4a5.843,5.843,0,0,1,4,1.8v9H31v-9a5.842,5.842,0,0,1,4-1.8c3.9,0,3.3,3.4,3.3,3.4-.3,2.5-4.2,7.4-4.2,7.4H35A28.022,28.022,0,0,0,37.9,31,8.511,8.511,0,0,0,39,28v-.6a2.637,2.637,0,0,1,1.6.7,2.288,2.288,0,0,1,.5,1.9c-.1,1.3-1.9,3.7-3.4,5.4h.9c1.2-1.4,3.1-3.8,3.2-5.3A2.845,2.845,0,0,0,38.9,26.8Zm-8.2-1.3a1.1,1.1,0,1,1,1.1-1.1A1.11,1.11,0,0,1,30.7,25.5Z"
            fill="#1e1e1e"
          />
        </g>
        <path
          data-name="Path 788"
          d="M30.7,19.8a.319.319,0,0,0,.3-.3V18a.319.319,0,0,0-.3-.3.451.451,0,0,0-.3.3v1.4C30.3,19.6,30.5,19.8,30.7,19.8Z"
          fill="#1e1e1e"
        />
        <g data-name="Group 1915">
          <path
            data-name="Path 789"
            d="M28.2,20.8h1.4a.319.319,0,0,0,.3-.3.451.451,0,0,0-.3-.3H28.2a.3.3,0,0,0,0,.6Z"
            fill="#1e1e1e"
          />
          <path
            data-name="Path 790"
            d="M31.7,20.8h1.4a.319.319,0,0,0,.3-.3.451.451,0,0,0-.3-.3H31.7a.3.3,0,0,0,0,.6Z"
            fill="#1e1e1e"
          />
        </g>
        <rect
          data-name="Rectangle 1456"
          width="16"
          height="0.7"
          transform="translate(22.7 37.9)"
          fill="#1e1e1e"
        />
        <rect
          data-name="Rectangle 1457"
          width="16"
          height="0.7"
          transform="translate(22.7 39.3)"
          fill="#1e1e1e"
        />
        <path data-name="Path 791" d="M22.7,36.5v.7h16v-.7Z" fill="#1e1e1e" />
      </g>
    </svg>
  );
}
