import ValidationErrorMessage from "components/common/FormElements/ValidationErrorMessage";

export default function StednavnInput(props: {
  ændringstype: "opret" | "ændre";
  showValidationWarning: boolean;
  validationWarningMessage: string;
  stednavn: string;
  setStednavn: (string) => void;
  notify: boolean;
  resetNotify: () => void;
}) {
  const {
    ændringstype,
    showValidationWarning,
    validationWarningMessage,
    stednavn,
    setStednavn,
    notify,
    resetNotify,
  } = props;
  return (
    <div className="c-textarea mb-3">
      <label htmlFor="nytstednavn" className="form-label">
        {ændringstype === "opret" && "Stednavn "}
        {ændringstype === "ændre" && "Nyt stednavn "}
        <span className="text-danger" aria-label="(Skal udfyldes)" title="Skal udfyldes"> *</span>
      </label>
      <input
        aria-required="true"
        // disabled={disableForm}
        className={
          showValidationWarning ? "form-control is-invalid" : "form-control"
        }
        id="nytstednavn"
        value={stednavn}
        onChange={(e) => {
          setStednavn(e.target.value);
        }}
      />
      <ValidationErrorMessage
        show={showValidationWarning}
        message={validationWarningMessage}
        notify={notify}
        resetNotify={resetNotify}
      />
    </div>
  );
}
