import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Helpers for react router in FIP
 *
 * source for searchQuery: https://reacttraining.com/react-router/web/example/query-parameters
 */
export function useRouter() {
  const { search, pathname } = useLocation();
  const appRoot = pathname.split("/")[1];
  const isFipGenerel = appRoot === "kort";
  const isStednavn = appRoot === "stednavn";
  const isPostnr = appRoot === "postnr";
  const isSogn = appRoot === "sogn";
  return {
    isFipGenerel,
    isStednavn,
    isPostnr,
    isSogn,
    appRoot,
    searchQuery: useMemo(() => new URLSearchParams(search), [search]),
  };
}
