import Select from "components/common/FormElements/Select";
import ValidationErrorMessage from "components/common/FormElements/ValidationErrorMessage";
import { ForwardedRef, forwardRef } from "react";

const KategoriSelect = forwardRef(
  (
    props: {
      isInvalid: boolean;
      currentKategori?: { value: number; label: string };
      setCurrentKategori: (value: string) => void;
      kategorier: { value: number; label: string }[];
      notify: boolean;
      resetNotify: () => void;
    },
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    const {
      currentKategori,
      setCurrentKategori,
      kategorier,
      isInvalid,
      notify,
      resetNotify,
    } = props;
    return (
      <>
        <Select
          ref={ref}
          className={isInvalid ? "form-control is-invalid" : "form-control"}
          labelText="Vælg kategori"
          mandatory={true}
          ariaLabel="Vælg kategori"
          onChange={(e) => setCurrentKategori(e.target.value)}
          value={currentKategori ? currentKategori.value.toString() : ""}
          options={[
            { label: "Vælg kategori", value: "", disabled: true },
            ...kategorier,
          ]}
        />
        <ValidationErrorMessage
          show={isInvalid}
          message="Vælg kategori"
          notify={notify}
          resetNotify={resetNotify}
        />
      </>
    );
  }
);

export default KategoriSelect;
