export default function ModalConfirmDelete(props: {
  type: "opdatering" | "fil";
  closeModal: (withCallback: boolean) => void;
  confirm: () => void;
}) {
  const { type, closeModal, confirm } = props;
  return (
    <div>
      <p>
        Er du sikker på at du vil slette{" "}
        {type === "fil" ? "filen" : "opdateringen"}.
      </p>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            confirm();
            closeModal(false);
          }}
        >
          Slet
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
