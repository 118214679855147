import React, { useContext } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import "ol/ol.css";
import "./App.scss";
import TwoPaneLayout from "components/layouts/TwoPaneLayout/TwoPaneLayout";
import Map from "components/common/Map/Map";
import ConfigProvider from "context/ConfigProvider";
import AuthProvider, { AuthContext } from "context/AuthProvider";
import { ModalProvider } from "components/common/Modal/Modal";
import MapProvider from "context/MapProvider";
import NotFound, { Error } from "routes/404/404";
import { routes, routesArray } from "routes/routesConfig";
import ToastProvider from "context/ToastProvider";
import IndberetningFilterProvider from "context/IndberetningFilterProvider";
import PrivateRoute from "routes/PrivateRoute";

import { ErrorBoundary } from "react-error-boundary";
import { LayoutProvider } from "context/LayoutProvider";
import SessionTimerProvider from "context/SessionTimerProvider";
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="position-absolute bottom-50 end-50">
      {error?.response?.status === 404 ? <NotFound /> : <Error />}

      <pre>{error.message}</pre>
      <button
        type="button"
        className="btn btn-primary p-2 col"
        onClick={resetErrorBoundary}
      >
        Gå tilbage
      </button>
    </div>
  );
}
function App() {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  return (
    <div id="App" className="App">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          history.push("/");
        }}
      >
        <TwoPaneLayout rightPane={<Map />}>
          <Switch>
            <Route exact path={"/"}>
              <Redirect to={routes.kort.path} />
            </Route>

            {routesArray.map(({ path, isPublic, Component }, key) => {
              if (isPublic) {
                return (
                  <Route exact path={path} key={key} component={Component} />
                );
              } else {
                return (
                  <PrivateRoute
                    exact
                    path={path}
                    component={Component}
                    key={key}
                    authState={authState}
                  />
                );
              }
            })}

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </TwoPaneLayout>
      </ErrorBoundary>
    </div>
  );
}

const AppWrapper = () => {
  return (
    <AuthProvider>
      <SessionTimerProvider>
        <LayoutProvider>
          <ToastProvider>
            <ModalProvider>
              <MapProvider>
                <ConfigProvider>
                  <IndberetningFilterProvider>
                    <React.StrictMode>
                      <App />
                    </React.StrictMode>
                  </IndberetningFilterProvider>
                </ConfigProvider>
              </MapProvider>
            </ModalProvider>
          </ToastProvider>
        </LayoutProvider>
      </SessionTimerProvider>
    </AuthProvider>
  );
};

export default AppWrapper;
