import { useContext, useLayoutEffect, useRef, useState } from "react";
import { ModalContext } from "../Modal/Modal";

export default function EditFileModal(props: {
  currentBeskrivelse: string;
  updateFile: (beskrivelse: string) => void;
}) {
  const firstFocusRef = useRef<HTMLInputElement>(null);
  useLayoutEffect(() => {
    firstFocusRef.current?.focus();
  }, []);
  const { closeModal } = useContext(ModalContext);
  const { currentBeskrivelse, updateFile } = props;
  const [beskrivelse, setBeskrivelse] = useState(currentBeskrivelse);
  return (
    <div>
      <label htmlFor="beskrivelse" className="form-label">
        Beskrivelse
      </label>
      <input
        ref={firstFocusRef}
        className="form-control"
        id="beskrivelse"
        value={beskrivelse}
        onChange={(e) => setBeskrivelse(e.target.value)}
      ></input>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            updateFile(beskrivelse);
            closeModal(false);
          }}
        >
          Gem
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
