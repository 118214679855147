import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import PlusCircleFill from "components/icons/PlusCircleFill";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { ModalContext } from "../Modal/Modal";

export const AddFileModal = (props: {
  geometri: Feature<Geometry>;
  filId: number;
  originalFilNavn: string;
  addFile: (
    beskrivelse: string,
    filId: number,
    geometri: Feature<Geometry>,
    originalFilNavn: string
  ) => void;
}) => {
  const firstFocusRef = useRef<HTMLTextAreaElement>(null);
  useLayoutEffect(() => {
    firstFocusRef.current?.focus();
  }, []);
  const { closeModal } = useContext(ModalContext);
  const { geometri, addFile, filId, originalFilNavn } = props;
  const [beskrivelse, setBeskrivelse] = useState("");
  return (
    <div>
      <label htmlFor="beskrivelse" className="form-label">
        Beskrivelse
      </label>
      <textarea
        ref={firstFocusRef}
        className="form-control"
        id="beskrivelse"
        value={beskrivelse}
        onChange={(e) => setBeskrivelse(e.target.value)}
      ></textarea>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            addFile(beskrivelse, filId, geometri, originalFilNavn);
            closeModal(false);
          }}
        >
          <PlusCircleFill class="me-2" />
          Tilføj
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
};
