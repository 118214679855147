import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import Select from "../FormElements/Select";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { ModalContext } from "../Modal/Modal";
import { Drawing } from "routes/Fip/FipIndberet";

export default function EditDrawingModal(props: {
  drawing: Drawing;
  hideObjekttype?: boolean;
  hideAendringstype?: boolean;
  submit: (
    aendringTypeId: number,
    aendringType: string,
    drawing: Feature<Geometry>,
    featureTypeId: number,
    featureType: string,
    beskrivelse: string
  ) => void;
  ændringsTyper: { value: number; text: string }[];
  objektTyper: { value: number; text: string }[];
}) {
  const { closeModal } = useContext(ModalContext);
  const {
    drawing,
    hideObjekttype,
    hideAendringstype,
    submit,
    ændringsTyper,
    objektTyper,
  } = props;
  const [objekttype, setObjekttype] = useState(
    objektTyper.find((o) => o.value === drawing.featureTypeId) ?? objektTyper[0]
  );
  const [ændringstype, setÆndringstype] = useState(
    ændringsTyper.find((æ) => æ.value === drawing.aendringTypeId) ??
      ændringsTyper[0]
  );
  const [kommentar, setKommentar] = useState(drawing.beskrivelse);
  const objekttypeRef = useRef<HTMLSelectElement>(null);
  const ændringstypeRef = useRef<HTMLSelectElement>(null);
  const kommentarRef = useRef<HTMLTextAreaElement>(null);
  // const objekttypeDisabled = defaultObjektType ? true : false;
  useLayoutEffect(() => {
    if (!hideObjekttype) {
      objekttypeRef.current?.focus();
    } else if (!hideAendringstype) {
      ændringstypeRef.current?.focus();
    } else {
      if (kommentarRef.current) {
        kommentarRef.current.focus();
        kommentarRef.current.selectionStart = kommentar.length;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {!hideObjekttype && (
        <div className="c-select mb-3">
          <Select
            ref={objekttypeRef}
            labelText="Objekttype"
            mandatory={true}
            ariaLabel="Vælg objekttype"
            value={objekttype.value.toString()}
            onChange={(e) => {
              const ot = objektTyper.find(
                (o) => o.value.toString() === e.target.value
              );
              if (ot) setObjekttype(ot);
            }}
            options={objektTyper.map((ot) => {
              return { label: ot.text, value: ot.value.toString() };
            })}
          />
        </div>
      )}
      {!hideAendringstype && (
        <div className="c-select mb-3">
          <Select
            ref={ændringstypeRef}
            labelText="Ændringstype"
            mandatory={true}
            ariaLabel="Vælg ændringstype"
            value={ændringstype.value.toString()}
            onChange={(e) => {
              const æt = ændringsTyper.find(
                (æ) => æ.value.toString() === e.target.value
              );
              if (æt) setÆndringstype(æt);
            }}
            options={ændringsTyper.map((æt) => {
              return { label: æt.text, value: æt.value.toString() };
            })}
          />
        </div>
      )}
      <label htmlFor="drawingKommentar" className="form-label">
        Kommentar
      </label>
      <textarea
        ref={kommentarRef}
        className="form-control"
        id="drawingKommentar"
        value={kommentar}
        onChange={(e) => setKommentar(e.target.value)}
      ></textarea>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            submit(
              ændringstype.value,
              ændringstype.text,
              drawing.drawing,
              objekttype.value,
              objekttype.text,
              kommentar
            );
            closeModal(false);
          }}
        >
          Gem
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
