import { uniqueId } from "lodash";
import { ForwardedRef, forwardRef } from "react";

const Select = forwardRef(
  (
    props: {
      labelText: string;
      mandatory: boolean;
      ariaLabel: string;
      className?: string;
      name?: string;
      value: string;
      onChange: React.ChangeEventHandler<HTMLSelectElement>;
      options: {
        value: string | number;
        label: string;
        disabled?: boolean;
      }[];
      disabled?: boolean;
    },
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    const {
      ariaLabel,
      onChange,
      options,
      value,
      className,
      labelText,
      mandatory,
      name,
      disabled,
    } = props;
    const classes = className ? `form-select ${className}` : "form-select";
    const id = uniqueId();
    return (
      <>
        <label htmlFor={id} className="form-label">
          {labelText}
          {mandatory && <span className="text-danger ms-1" aria-label="(Skal udfyldes)" title="Skal udfyldes">
            <svg className="mt-n1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" aria-hidden="true" focusable="false" fill="currentColor" viewBox="0 0 16 16">
              <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
            </svg>
          </span>
          }
        </label>
        <select
          ref={ref}
          aria-required={mandatory}
          disabled={disabled}
          name={name}
          id={id}
          className={classes}
          aria-label={ariaLabel}
          value={value}
          onChange={onChange}
        >
          {options.map((option) => {
            const { value, label, disabled } = option;
            return (
              <option
                key={value}
                value={value}
                disabled={disabled ? true : false}
              >
                {label}
              </option>
            );
          })}
        </select>
      </>
    );
  }
);

export default Select;
