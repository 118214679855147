export default function ChevronRight(props: {
  class?: string;
  ariaLabel?: string;
}) {
  const { ariaLabel } = props;
  const classNames = props.class ? `${props.class} bi` : "bi bi-chevron-right";
  return (
    <svg
      aria-label={ariaLabel ? ariaLabel : ""}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className={classNames}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
}
