import DrawLine from "components/icons/DrawLine";
import DrawPoint from "components/icons/DrawPoint";
import DrawPolygon from "components/icons/DrawPolygon";
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { wktFormat } from "util/ol";

export default function GeometryAvatar(props: {
  feature: Feature<Geometry> | string;
}) {
  let { feature } = props;
  let geom: Geometry;
  if (typeof feature === "string") {
    geom = wktFormat.readFeature(feature).getGeometry();
  } else {
    geom = feature.getGeometry();
  }
  switch (geom.getType()) {
    case "Point":
      return (
        <div
          aria-label="Geometri type: Punkt"
          title="Geometri type: Punkt"
          className="c-avatar bg-primary"
          role="img"
        >
          <DrawPoint />
        </div>
      );
    case "LineString":
      return (
        <div
          aria-label="Geometri type: Linje"
          title="Geometri type: Linje"
          className="c-avatar bg-primary"
          role="img"
        >
          <DrawLine />
        </div>
      );

    default:
      return (
        <div
          aria-label="Geometri type: Polygon"
          title="Geometri type: Polygon"
          className="c-avatar bg-primary"
          role="img"
        >
          <DrawPolygon />
        </div>
      );
  }
}
