export default function Close(props: { class?: string }) {
  const classNames = props.class ? `${props.class} bi bi-download` : "bi bi-download";
  return (

<svg className={classNames} width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
<path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
</svg>


  );
}
