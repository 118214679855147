import { baseUrl } from "api";
import { SagsbehandlingApi } from "api/interfaces";
import EditDrawingModal from "components/common/Indberet/EditDrawingModal";
import ModalConfirmDelete from "components/common/Indberet/ModalConfirmDelete";
import { ModalContext } from "components/common/Modal/Modal";
import { finishedDrawingSource, modifySource } from "config/interactions";
import { Bruger } from "context/AuthProvider";
import { MapContext } from "context/MapProvider";
import {
  Aendringsforslag,
  Hoeringssag,
  RessourceRolleIdEnum,
  statusIdEnum,
} from "interfaces/IIndberetning";
import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { useContext } from "react";
import { isIndberetningAfsluttet } from "routes/Fip/FipIndberetning";
import { getDownloadGeometryLink } from "routes/Fip/fipUtil";
import { formatDateString } from "util/date";
import { convertFeatureToWkt, zoomToFeature } from "util/ol";
import DrawingListItem from "../DrawingListItem";
import FilListItem from "../FilListItem";

export default function Sagsagt(props: {
  indberetning: Hoeringssag;
  setIndberetning: Function;
  indlaeg: Aendringsforslag;
  isMe: boolean;
  fromBehandler: boolean;
  isHoeringssagChat: boolean;
  isStednavn: boolean;
  currentUser: Bruger;
  setChatState: React.Dispatch<React.SetStateAction<State>>;
  cancelDrawingAndUploadFile: () => void;
  onEditGeometry: (feature: Feature<Geometry>) => void;
  objektTyper: {
    value: number;
    text: string;
  }[];
  ændringsTyper: {
    value: number;
    text: string;
  }[];
  sagsbehandlingApi: SagsbehandlingApi;
}) {
  const {
    indlaeg,
    isMe,
    fromBehandler,
    isHoeringssagChat,
    isStednavn,
    indberetning,
    currentUser,
    setChatState,
    cancelDrawingAndUploadFile,
    setIndberetning,
    onEditGeometry,
    objektTyper,
    ændringsTyper,
    sagsbehandlingApi,
  } = props;

  const {
    deleteAendringsforslag,
    deleteSagsakt,
    getIndberetning,
    redigerAendringsforslag,
    redigerSagsakt,
  } = sagsbehandlingApi;
  const { showModal, closeModal } = useContext(ModalContext);
  const { mapState, dispatchToMap } = useContext(MapContext);
  const setHoveredFeatures = (ids) =>
    dispatchToMap({
      type: "SET_HOVERED_FEATURES",
      hoveredFeatures: ids,
    });
  const { map, hoveredFeatures } = mapState;
  const sagsAktType = indlaeg.originalFilNavn === "" ? "Geometri" : "Fil";
  const features = finishedDrawingSource.getFeatures() as any; // TODO: Hack because i can't get values otherwise

  const currentFeature = features.find(
    (e) => e.get("geometriId") === indlaeg.geometriId
  );
  const modifyFeature = (modifySource.getFeatures() as any).find(
    (e) => e.get("geometriId") === indlaeg.geometriId
  );

  if (!(currentFeature || modifyFeature)) {
    return null;
  }
  const geometri = {
    aendringTypeId: indlaeg.aendringTypeId,
    aendringType: indlaeg.aendringType,
    drawing: currentFeature ? currentFeature : modifyFeature,
    featureTypeId: indlaeg.featureTypeId,
    featureType: indlaeg.featureType,
    beskrivelse: indlaeg.beskrivelse,
    dato: indlaeg.dato,
  };
  let name;
  if (isHoeringssagChat) {
    if (fromBehandler) {
      name = "Høringspart";
    } else {
      name = "Høringsansvarlig";
    }
  } else {
    if (fromBehandler) {
      name = "Sagsbehandler";
    } else {
      name = "Indberetter";
    }
  }
  // if the sag is not in høring it cant be edited
  let hideEdit = false;
  if (isHoeringssagChat && indberetning.statusId !== statusIdEnum.iHoering) {
    hideEdit = true;
  } else if (!isHoeringssagChat) {
    const currentSagsbehandler = indberetning.ressource?.find(
      (x) => x.rolleId === RessourceRolleIdEnum.sagsbehandler
    );
    if (
      (currentUser.personId !== currentSagsbehandler?.personId &&
        fromBehandler) ||
      isIndberetningAfsluttet(indberetning)
    ) {
      hideEdit = true;
    }
  }
  // The three ... menu on geometry and file must be hidden if:

  let hideOptions = false;
  // currentUser is not the owner and is an indberetter or gæst
  if (
    [RessourceRolleIdEnum.indberetter, RessourceRolleIdEnum.gæst].includes(
      currentUser.rolleId
    ) &&
    !isMe
  ) {
    hideOptions = true;
  }

  return (
    // <div key={k.id} className="c-journal__message ms-auto">
    <div
      className={`c-journal__message c-journal__message--${name.replace(
        "ø",
        "o"
      )} ${fromBehandler ? "ms-auto" : ""} ${sagsAktType.toLowerCase()}`}
    >
      <div
        className={`c-journal__message-header small d-block d-md-flex justify-content-between `}
      >
        <div>
          <span
            role="img"
            className="geometri-color me-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Farve i kortet (Geometri)"
            aria-label="Signatur farve i kort"
          ></span>
          <span className="me-1">
            {indlaeg.personNavn && (
              <strong>{`${indlaeg.personNavn} - `}</strong>
            )}
            {name}
          </span>
        </div>
        <span aria-hidden className="d-inline-block mt-1 mt-md-0 text-end">
          {formatDateString(indlaeg?.dato)}
        </span>
        <span className="visually-hidden">
          <span>
            {formatDateString(new Date(indlaeg?.dato).toString(), "dd-MM-yyyy")}
          </span>{" "}
          klokken{" "}
          {formatDateString(new Date(indlaeg?.dato).toString(), "HH:mm")}
        </span>
      </div>

      <div className="c-journal__message-content">
        {indlaeg.geometriId && geometri.drawing && sagsAktType === "Geometri" && (
          <DrawingListItem
            type={"compressed"}
            key={indlaeg.geometriId}
            hovered={hoveredFeatures.indexOf(indlaeg.id) > -1}
            geometri={geometri}
            hideEditOptions={!isMe || hideEdit}
            hideDeleteOption={
              !isMe ||
              hideEdit ||
              (isStednavn &&
                currentUser.rolleId === RessourceRolleIdEnum.indberetter)
            }
            hideOptions={hideOptions}
            downloadLink={getDownloadGeometryLink(indberetning.id, indlaeg.id)}
            setHoveredFeatures={setHoveredFeatures}
            zoomToDrawing={() => {
              if (map) {
                zoomToFeature(map, geometri.drawing);
              }
            }}
            onEditGeometryClick={() => onEditGeometry(currentFeature)}
            onEditClick={() => {
              setChatState("LOADING");
              cancelDrawingAndUploadFile();
              showModal({
                title: "Rediger indtegning",
                content: (
                  <EditDrawingModal
                    drawing={geometri}
                    hideObjekttype={fromBehandler && !isHoeringssagChat} // only hide it from the sagsbehandlerin sagsbehandling
                    hideAendringstype={fromBehandler && !isHoeringssagChat} // only hide it from the sagsbehandler in sagsbehandling
                    submit={(
                      aendringTypeId: number,
                      aendringType: string,
                      drawing: Feature<Geometry>,
                      featureTypeId: number,
                      featureType: string,
                      beskrivelse: string
                    ) => {
                      if (fromBehandler) {
                        redigerSagsakt(
                          currentUser,
                          indlaeg.id,
                          beskrivelse,
                          ""
                        ).then((e) => {
                          getIndberetning(
                            indberetning.id,
                            currentUser.personId
                          ).then((nyIndberetning) => {
                            setIndberetning(nyIndberetning);
                            setChatState("IDLE");
                          });
                        });
                      } else {
                        redigerAendringsforslag(
                          currentUser,
                          aendringTypeId,
                          aendringType,
                          indlaeg.id,
                          beskrivelse,
                          featureType,
                          featureTypeId,
                          convertFeatureToWkt(drawing),
                          `Openlayers.Geometry.${drawing
                            .getGeometry()
                            .getType()}`,
                          ""
                        ).then((e) => {
                          getIndberetning(
                            indberetning.id,
                            currentUser.personId
                          ).then((nyIndberetning) => {
                            setIndberetning(nyIndberetning);
                            setChatState("IDLE");
                          });
                        });
                      }
                    }}
                    objektTyper={objektTyper}
                    ændringsTyper={ændringsTyper}
                  />
                ),
                closeModalCallback: () => {
                  setChatState("IDLE");
                },
              });
            }}
            onDeleteClick={() => {
              cancelDrawingAndUploadFile();
              showModal({
                title: "Slet opdatering",
                content: (
                  <ModalConfirmDelete
                    type={"opdatering"}
                    confirm={() => {
                      setChatState("LOADING");
                      if (fromBehandler) {
                        deleteSagsakt(currentUser, indlaeg.id).then(() => {
                          getIndberetning(
                            indberetning.id,
                            currentUser.personId
                          ).then((nyIndberetning) => {
                            setIndberetning(nyIndberetning);
                            setChatState("IDLE");
                          });
                        });
                      } else {
                        deleteAendringsforslag(currentUser, indlaeg.id).then(
                          () => {
                            getIndberetning(
                              indberetning.id,
                              currentUser.personId
                            ).then((nyIndberetning) => {
                              setIndberetning(nyIndberetning);
                              setChatState("IDLE");
                            });
                          }
                        );
                      }
                    }}
                    closeModal={closeModal}
                  />
                ),
              });
            }}
          />
        )}
        {indlaeg.filId !== undefined &&
          geometri.drawing &&
          sagsAktType === "Fil" && (
            <FilListItem
              type={"compressed"}
              key={indlaeg.filId}
              fil={indlaeg}
              id={indlaeg.id}
              hideOptions={hideOptions}
              hideEditOptions={true} // You can never edit a file in the chat
              hideDeleteOption={!isMe || hideEdit}
              downloadFileLink={
                baseUrl +
                `download/zip/?sagId=${indberetning.id}&sagsaktId=${indlaeg.id}&personId=${currentUser.personId}`
              }
              downloadGeometryLink={getDownloadGeometryLink(
                indberetning.id,
                indlaeg.id
              )}
              hoveredFeatures={hoveredFeatures}
              onClick={() => {
                if (map) {
                  zoomToFeature(map, currentFeature);
                }
              }}
              onMouseEnter={() => {
                if (indlaeg.id) {
                  setHoveredFeatures([indlaeg.id]);
                }
              }}
              onMouseLeave={() => {
                setHoveredFeatures([]);
              }}
              removeFil={() => {
                setChatState("LOADING");
                deleteSagsakt(currentUser, indlaeg.id).then(() => {
                  getIndberetning(indberetning.id, currentUser.personId).then(
                    (nyIndberetning) => {
                      setIndberetning(nyIndberetning);
                      setChatState("IDLE");
                    }
                  );
                });
              }}
              onEditGeometryClick={() => onEditGeometry(currentFeature)}
              onEditClick={() => {
                console.log("not implemented");
              }}
              showModal={showModal}
              closeModal={closeModal}
            />
          )}
      </div>
    </div>
  );
}
