import { DrawTool } from "context/MapProvider";

export default function DrawToolsToasts(props: {
  activeDrawTool: DrawTool;
  showToast: boolean;
  setShowToast: (show: boolean) => void;
}) {
  const { activeDrawTool, setShowToast, showToast } = props;
  const renderToast = () => {
    if (showToast) {
      switch (activeDrawTool) {
        case "point":
          return (
            <div
              className="toast text-white bg-primary align-items-center show w-100"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="d-flex">
                <div className="toast-body">
                  <strong>Sæt ét punkt</strong> i kortet med kortets tegneværktøj.
                </div>
                <button
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                  onClick={() => setShowToast(false)}
                  aria-label="Close"
                ></button>
              </div>
            </div>
          );
        case "line":
          return (
            <div
              className="toast text-white bg-primary align-items-center show w-100"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="d-flex">
                <div className="toast-body">
                  <strong>Sæt min. 2 punkter</strong> i kortet med kortets tegneværktøj.{" "}
                  <i>Dobbeltklik</i> for at afslutte din linje.
                </div>
                <button
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                  onClick={() => setShowToast(false)}
                  aria-label="Close"
                ></button>
              </div>
            </div>
          );
        case "polygon":
          return (
            <div
              className="toast text-white bg-primary align-items-center show w-100"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="d-flex">
                <div className="toast-body">
                  <strong>Sæt min. 3 punkter</strong> i kortet med kortets tegneværktøj.{" "}
                  <i>Dobbeltklik</i> for at afslutte din polygon.
                </div>
                <button
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                  onClick={() => setShowToast(false)}
                  aria-label="Close"
                ></button>
              </div>
            </div>
          );
        case "udpeg":
          return (
            <div
              className="toast text-white bg-primary align-items-center show w-100"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="d-flex">
                <div className="toast-body">
                  For at udpege med kortets tegneværktøj skal du tænde for det kortlag, som du ønsker at
                  udpege i. Herefter skal du vælge et objekt ved at <strong>klikke på det i kortet</strong>.
                  Hvis du bevæger musen over et objekt i kortet, vil den blive
                  til en hånd med pegefinger.
                </div>
                <button
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                  onClick={() => setShowToast(false)}
                  aria-label="Close"
                ></button>
              </div>
            </div>
          );
      }
    }
  };
  return <div className="c-messages toast-container">{renderToast()}</div>;
}
