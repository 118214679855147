import { getSearchFilterByValue, SearchFilter } from "interfaces/IIndberetning";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext, Bruger } from "./AuthProvider";
import { MapContext } from "./MapProvider";

export const IndberetningFilterContext = createContext<{
  filter: SearchFilter;
  setFilter: React.Dispatch<React.SetStateAction<SearchFilter>>;
  filters: SearchFilter[];
  setFilters: React.Dispatch<React.SetStateAction<SearchFilter[]>>;
  search: {
    emne: string;
    beskrivelse: string;
  };
  setSearch: React.Dispatch<
    React.SetStateAction<{
      emne: string;
      beskrivelse: string;
    }>
  >;
  searchInputText: {
    emne: string;
    beskrivelse: string;
  };
  setSearchInputText: React.Dispatch<
    React.SetStateAction<{
      emne: string;
      beskrivelse: string;
    }>
  >;
  searchExtent: number[];
  currentUser: Bruger;
}>({
  filter: { value: 0, text: "Aktive" },
  setFilter: () => {},
  filters: [],
  setFilters: () => {},
  search: { beskrivelse: "", emne: "" },
  setSearch: () => {},
  searchInputText: { beskrivelse: "", emne: "" },
  setSearchInputText: () => {},
  searchExtent: [0, 0, 0, 0],
  currentUser: { navn: "", personId: 0, rolleId: 0 },
});

const IndberetningFilterProvider = ({ children }) => {
  const { authState } = useContext(AuthContext);
  const { currentUser } = authState;
  const { mapState } = useContext(MapContext);
  const { viewState } = mapState;
  const { extent } = viewState;
  const [filter, setFilter] = useState<SearchFilter>(getSearchFilterByValue(0));
  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const [search, setSearch] = useState<{
    emne: string;
    beskrivelse: string;
  }>({
    emne: "",
    beskrivelse: "",
  });
  const [searchInputText, setSearchInputText] = useState<{
    emne: string;
    beskrivelse: string;
  }>({
    emne: "",
    beskrivelse: "",
  });
  const [searchExtent, setSearchExtent] = useState<number[]>(extent);
  useEffect(() => {
    if (filter.text === "Alle indenfor kortvindue") {
      setSearchExtent(extent);
    }
  }, [extent, filter]);

  useEffect(() => {
    if (filters.length && filters.indexOf(filter) < 0) {
      setFilter(filters[0]);
    }
  }, [filters, filter, setFilter]);

  // nulstil beskrivelse dersom man skifter til en rolle der
  // ikke kan søge i beskrivelse.
  useEffect(() => {
    if (currentUser.rolleId !== 2) {
      setSearchInputText((prev) => {
        return { ...prev, beskrivelse: "" };
      });
    }
  }, [currentUser.rolleId, setSearchInputText]);
  return (
    <IndberetningFilterContext.Provider
      value={{
        filter,
        setFilter,
        filters,
        setFilters,
        search,
        setSearch,
        searchInputText,
        setSearchInputText,
        searchExtent,
        currentUser,
      }}
    >
      {children}
    </IndberetningFilterContext.Provider>
  );
};
export default IndberetningFilterProvider;
