import ValidationErrorMessage from "../FormElements/ValidationErrorMessage";
import { ShowModalSignature } from "../Modal/Modal";

export default function AccepterVilkår(props: {
  disabled: boolean;
  showValidationWarning: boolean;
  validationWarningMessage: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  showModal: ShowModalSignature;
  closeModal: (withCallback: boolean) => void;
  notify: boolean;
  resetNotify: () => void;
}) {
  const {
    checked,
    setChecked,
    disabled,
    showValidationWarning,
    validationWarningMessage,
    // showModal,
    // closeModal,
    notify,
    resetNotify,
  } = props;
  return (
    <div className="col-8 me-auto">
      <div className="form-check">
        <input
          aria-required="true"
          disabled={disabled}
          className={
            showValidationWarning
              ? "form-check-input is-invalid"
              : "form-check-input"
          }
          type="checkbox"
          id="gridCheck"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="gridCheck">
          <strong className="d-block">Privatlivspolitik</strong>
          <span>
            {/* TODO: betingelser skal rettes til privatlivspolitik */}
            Jeg har læst og forstået SDFI's{" "}
            {/* <button
              className="btn btn-link p-0"
              onClick={() => {
                showModal({
                  content: <ModalBetingelser closeModal={closeModal} />,
                  title: "Privatlivspolitik",
                });
              }}
            >
              privatlivspolitik
            </button> */}
            <a
              href="https://indberetning.sdfe.dk/privatlivspolitik_indberetning.pdf"
              target="_blank"
              rel="noreferrer"
            >
              privatlivspolitik
            </a>
            .
          </span>
        </label>
        <ValidationErrorMessage
          show={showValidationWarning}
          message={validationWarningMessage}
          notify={notify}
          resetNotify={resetNotify}
        />
      </div>
    </div>
  );
}
