import {
  Hoeringssag,
  Ressource,
  RessourceRolleIdEnum,
} from "interfaces/IIndberetning";
import { prioritetStore, statusStore, tema } from "config/FipConfig";
import Section from "../Section";
import { formatDateString, formatDottedDate } from "util/date";
import { Fragment, useContext } from "react";
import { AuthContext } from "context/AuthProvider";
import { ModalContext } from "../Modal/Modal";
import PrintModal from "./PrintModal";
import { ToastContext } from "context/ToastProvider";
import useClipboard from "react-use-clipboard";
import { useRouter } from "hooks/useRouter";

interface IDetaljevisningProps {
  indberetning: Hoeringssag;
  indberetter: Ressource | undefined;
  sagsbehandler: Ressource | undefined;
  zoomToIndberetning: () => void;
}
export default function Detaljevisning(props: IDetaljevisningProps) {
  const { indberetning, indberetter, sagsbehandler, zoomToIndberetning } =
    props;

  const { authState } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);
  const { isStednavn } = useRouter();
  const toast = useContext(ToastContext);
  const { currentUser } = authState;
  const { indberetningsmappe: indberetningsmappeListe } = indberetning;
  const [, setCopied] = useClipboard(window.location.href, {
    successDuration: 1000,
  });
  const aktivHøringspart = indberetning.ressource?.find(
    (x) => x.rolleId === RessourceRolleIdEnum.høringspart
  );
  const aktivIndberetter = indberetning.ressource?.find(
    (x) => x.rolleId === RessourceRolleIdEnum.indberetter
  );
  const isHøringsSag =
    indberetning.hoeringsansvarligmappe &&
    indberetning.hoeringsansvarligmappe.length > 0;
  return (
    <div
      className="tab-pane fade show active"
      id="sags-indberetningsmappe"
      role="tabpanel"
      aria-labelledby="sags-indberetningsmappe-tab"
    >
      <Section className="mt-4">
        <h2>Detaljevisning</h2>
        <p>Sagsoplysninger.</p>
        <dl className="row c-metadata-list mt-4">
          <dt className="col-sm-3">FIP ID:</dt>
          <dd className="col-sm-9">
            <p>{indberetning.id}</p>
          </dd>

          <dt className="col-sm-3">Emne:</dt>
          <dd className="col-sm-9">
            <p>{indberetning.emne}</p>
          </dd>
          {![RessourceRolleIdEnum.høringspart].includes(currentUser.rolleId) &&
            indberetter && (
              <>
                <dt className="col-sm-3">Indberetter:</dt>
                <dd className="col-sm-9">
                  <p>
                    {indberetter.navn}{" "}
                    {indberetter.organisationsnavn
                      ? `(${indberetter.organisationsnavn})`
                      : ""}
                  </p>
                </dd>
              </>
            )}
          {isStednavn &&
            currentUser.rolleId === RessourceRolleIdEnum.sagsbehandler &&
            indberetter && (
              <>
                <dt className="col-sm-3">Indberetter email:</dt>
                <dd className="col-sm-9">
                  <p>{indberetter.email}</p>
                </dd>
              </>
            )}
          {![
            RessourceRolleIdEnum.indberetter,
            RessourceRolleIdEnum.høringspart,
            RessourceRolleIdEnum.gæst,
          ].includes(currentUser.rolleId) && (
            <>
              <dt className="col-sm-3">Sagsbehandler:</dt>
              <dd className="col-sm-9">
                {sagsbehandler && <p>{sagsbehandler.navn}</p>}
                {!sagsbehandler && <p>Ingen sagsbehandler tilknyttet</p>}
              </dd>
            </>
          )}
          {indberetning.oprettetTs && (
            <>
              <dt className="col-sm-3">Oprettet:</dt>
              <dd className="col-sm-9">
                <p>
                  <span aria-hidden>
                    {formatDateString(indberetning.oprettetTs)}
                  </span>
                  <span className="visually-hidden">
                    <span>
                      {formatDateString(indberetning.oprettetTs, "dd-MM-yyyy")}
                    </span>{" "}
                    klokken {formatDateString(indberetning.oprettetTs, "HH:mm")}
                  </span>
                </p>
              </dd>
            </>
          )}
          {indberetning.tilTs && (
            <>
              <dt className="col-sm-3">Afsluttet:</dt>
              <dd className="col-sm-9">
                <p>
                  <span aria-hidden>
                    {indberetning.tilTs !== "9999-12-31T00:00:00" // TODO, this seems like a sketchy check, probably safer to use the status of the indberetning
                      ? formatDateString(indberetning.tilTs)
                      : "-"}
                  </span>
                  <span className="visually-hidden">
                    {indberetning.tilTs !== "9999-12-31T00:00:00" ? (
                      <>
                        <span>
                          {formatDateString(indberetning.tilTs, "dd-MM-yyyy")}
                        </span>{" "}
                        klokken {formatDateString(indberetning.tilTs, "HH:mm")}
                      </>
                    ) : (
                      "Ikke afsluttet"
                    )}
                  </span>
                </p>
              </dd>
            </>
          )}

          {currentUser.rolleId !== RessourceRolleIdEnum.indberetter && (
            <Fragment>
              {!isStednavn &&
                indberetning.domaeneType &&
                ![RessourceRolleIdEnum.høringspart].includes(
                  currentUser.rolleId
                ) && (
                  <>
                    <dt className="col-sm-3">Domæne:</dt>
                    <dd className="col-sm-9">
                      <p>{indberetning.domaeneType}</p>
                    </dd>
                  </>
                )}
              {indberetning.statusId > 0 &&
                ![RessourceRolleIdEnum.høringspart].includes(
                  currentUser.rolleId
                ) && (
                  <>
                    <dt className="col-sm-3">Status:</dt>
                    <dd className="col-sm-9">
                      <p>
                        {
                          statusStore.find(
                            (s) => s.id === indberetning.statusId
                          )?.name
                        }
                      </p>
                    </dd>
                  </>
                )}
              {isHøringsSag && (
                <>
                  <dt className="col-sm-3">Aktiv høringspart:</dt>
                  <dd className="col-sm-9">
                    <p>
                      {aktivHøringspart
                        ? `${aktivHøringspart.navn} (${aktivHøringspart.organisationsnavn})`
                        : "Ingen"}
                    </p>
                  </dd>
                  <dt className="col-sm-3">Høringssvar:</dt>
                  <dd className="col-sm-9">
                    {indberetning.hoeringspartmappe &&
                      indberetning.hoeringspartmappe.map((e) => {
                        return (
                          <p key={e.id}>
                            {e.organisationNavn}:{" "}
                            {e.svar ? e.svar : ` IKKE AFGIVET`}
                          </p>
                        );
                      })}
                  </dd>
                  <dt className="col-sm-3">Høringsfrist:</dt>
                  <dd className="col-sm-9">
                    {indberetning.hoeringspartmappe &&
                      indberetning.hoeringspartmappe.map((e) => {
                        return (
                          <p key={e.id}>
                            {e.organisationNavn}:{" "}
                            {e.svarFrist ? formatDottedDate(e.svarFrist) : ""}
                          </p>
                        );
                      })}
                  </dd>
                </>
              )}
              {!isStednavn &&
                ![
                  RessourceRolleIdEnum.høringspart,
                  RessourceRolleIdEnum.gæst,
                ].includes(currentUser.rolleId) && (
                  <>
                    <dt className="col-sm-3">Frist:</dt>
                    <dd className="col-sm-9">
                      <p>
                        {indberetning.frist
                          ? formatDottedDate(indberetning.frist)
                          : ""}
                      </p>
                    </dd>
                  </>
                )}
              {![
                RessourceRolleIdEnum.høringspart,
                RessourceRolleIdEnum.gæst,
              ].includes(currentUser.rolleId) && (
                <>
                  {!isStednavn && (
                    <>
                      {" "}
                      <dt className="col-sm-3">Prioriteret:</dt>
                      <dd className="col-sm-9">
                        <p>
                          {
                            prioritetStore.find(
                              (p) => p.id === indberetning.prioritet
                            )?.name
                          }
                        </p>
                      </dd>
                    </>
                  )}

                  <dt className="col-sm-3">ESDH Journal:</dt>
                  <dd className="col-sm-9">
                    {!indberetning.esdhJournalNummer && (
                      <p>Ingen ESDH-journal</p>
                    )}
                    {indberetning.esdhJournalNummer && (
                      <a
                        href={`${process.env.REACT_APP_API_BASEURL}redirect/captia/${indberetning.esdhJournalId}`}
                      >
                        Gå til ESDH-journal ({indberetning.esdhJournalNummer})
                      </a>
                    )}
                  </dd>
                </>
              )}
            </Fragment>
          )}
          {![
            RessourceRolleIdEnum.indberetter,
            RessourceRolleIdEnum.høringspart,
          ].includes(currentUser.rolleId) &&
            indberetningsmappeListe.map((indberetningsmappe, index) => {
              const {
                korttemaId,
                // geometri,
                //  fil
              } = indberetningsmappe;
              const korttema = tema.find((t) => t.id === korttemaId)?.name;
              return (
                <Fragment key={index}>
                  {/* korttema */}
                  {!isStednavn && korttema && (
                    <>
                      <dt className="col-sm-3">Korttema:</dt>
                      <dd className="col-sm-9">
                        <p>{korttema}</p>
                      </dd>
                    </>
                  )}
                </Fragment>
              );
            })}
          {/* <dl className="row mt-3 mb-0 px-0 mx-0"> */}
          {!(
            [RessourceRolleIdEnum.gæst].includes(currentUser.rolleId) ||
            (currentUser.rolleId === RessourceRolleIdEnum.indberetter &&
              aktivIndberetter?.personId !== currentUser.personId)
          ) && (
            <>
              <dt className="col-sm-3">Beskrivelse:</dt>
              <dd className="col-sm-9">
                <p>{indberetning.beskrivelse}</p>
              </dd>
            </>
          )}
          {/* </dl> */}
        </dl>
      </Section>
      <Section className="mb-n4">
        <div className="d-flex gap-2 mb-4">
          {currentUser.rolleId !== RessourceRolleIdEnum.indberetter && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setCopied();
                toast({
                  type: "default",
                  content: { message: "URL kopieret til udklipsholder" },
                });
              }}
            >
              Kopier url
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              showModal({
                content: (
                  <PrintModal
                    personId={currentUser.personId}
                    sagId={indberetning.id}
                  />
                ),
                title: "Print",
              });
            }}
          >
            Print
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={zoomToIndberetning}
          >
            Zoom til indberetning
          </button>
        </div>
      </Section>
    </div>
  );
}
