import { get } from "api";
import { SearchResult, SearchResultResponse } from "interfaces/IGeoSearch";

export type ResourceType =
  | "adresser"
  | "matrikelnumre"
  | "stednavne_v3"
  | "kommuner"
  | "opstillingskredse"
  | "politikredse"
  | "postdistrikter"
  | "regioner"
  | "retskredse"
  | "sogne";
export const allResources =
  "adresser,matrikelnumre,stednavne_v3,kommuner,opstillingskredse,politikredse,postdistrikter,regioner,retskredse,sogne";
export const getSearchResult = async (
  query: string,
  resources?: ResourceType[]
): Promise<SearchResult[]> => {
  try {
    const resourcesString =
      resources && resources.length > 0 ? resources.join(",") : allResources;
    const result = await get<SearchResultResponse>({
      query: `Geosearch?service=GEO&search=${query}&ticket=54d9561a836b02cc6997484ce31e8ba3&resources=${resourcesString}&limit=100`,
      apiPrefix: "https://kortforsyningen.kms.dk/",
    });
    if (result.status !== "OK") return Promise.reject(result.message);
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
