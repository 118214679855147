import { del, get, post, put } from "api";
import { Objekttype, SagsbehandlingApi } from "api/interfaces";
import { getFilterValues, getFilterValueUrlString } from "api/util";
import { Bruger } from "context/AuthProvider";
import { FeatureCollection } from "geojson";
import {
  convertIndberetningDTO,
  ExpandedIndberetningDTO,
  Hoeringssag,
  IFileUploadResponse,
  Indberetning,
  IndberetningDTO,
  Indlaeg,
  Ressource,
  RessourceRolleIdEnum,
  Sagsakt,
  statusIdEnum,
} from "interfaces/IIndberetning";
import { formatDateString } from "util/date";

export const getIndberetninger = async (options: {
  personId: number;
  rolleId: number;
  filterId: number;
  searchEmne?: string;
  searchBeskrivelse?: string;
  extent?: number[];
}): Promise<Indberetning[]> => {
  const { filterId, personId, rolleId, searchBeskrivelse, searchEmne, extent } =
    options;
  const filterValues = getFilterValues(extent, searchEmne, searchBeskrivelse);
  try {
    const iDTOs = await get<IndberetningDTO[]>({
      query: `stednavn/api/saginfo?filterId=${filterId}&rolleId=${rolleId}&personId=${personId}&domaeneTypes=&filterValues=${filterValues}&page=1&start=0&limit=25&sort=%5B%7B%22property%22%3A%22id%22%2C%22direction%22%3A%22DESC%22%7D%5D`,
    });
    return iDTOs.map((i) => convertIndberetningDTO(i));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getIndberetning = async (
  indberetningId: number,
  personId: number
): Promise<Hoeringssag> => {
  try {
    const iDTO = await get<Hoeringssag>({
      query: `stednavn/api/sager/${indberetningId}?filterId=0&personId=${personId}&id=${indberetningId}`,
    });
    return iDTO;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postIndberetning = async (
  personId: number,
  email: string,
  body: Hoeringssag
): Promise<Indberetning> => {
  try {
    //http://fip.cust.septima.dk/api/sager?personId=4&email=xxx%40yyy.dk
    const iDTO = await post<ExpandedIndberetningDTO>({
      query: `stednavn/api/sager/?personId=${personId}&email=${email}`,
      body,
    });
    return convertIndberetningDTO(iDTO);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const putIndberetningDSSYS = async (
  personId: number,
  statusId: statusIdEnum,
  body: Hoeringssag
): Promise<Hoeringssag> => {
  body.statusId = statusId;
  try {
    //http://fip.cust.septima.dk/api/sager?personId=4&email=xxx%40yyy.dk
    const sag = await put<Hoeringssag>({
      query: `stednavn/api/sager/${body.id}?personId=${personId}`,
      body,
    });
    return sag;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getStednavnFeatureInfo = async (options: {
  personId: number;
  rolleId: number;
  filterId: number;
  searchEmne?: string;
  searchBeskrivelse?: string;
  extent: number[];
  height;
  width: number;
  x: number;
  y: number;
}) => {
  const {
    filterId,
    personId,
    rolleId,
    searchBeskrivelse,
    searchEmne,
    extent,
    height,
    width,
    x,
    y,
  } = options;
  try {
    const queryParams: any = {
      service: "WMS",
      Version: "1.1.1",
      request: "GetFeatureInfo",
      LAYERS: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":Sag2",
      QUERY_LAYERS: process.env.REACT_APP_GEOSERVER_WORKSPACE + ":Sag2",
      FORMAT: "application/json",
      INFO_FORMAT: "application/json",
      SRS: "EPSG:25832",
      BBOX: extent.join(","),
      FEATURE_COUNT: 10,
      height,
      width,
      x,
      y,
      VIEWPARAMS: `applikationId:4;domaeneTypeIds:;filterId:${
        filterId === 6 ? 4 : filterId
      };personId:${personId};rolleId:${rolleId};filterValues:${getFilterValueUrlString(
        searchEmne,
        searchBeskrivelse
      )}`,
    };
    const featureCollection = await get<FeatureCollection>({
      query: `${process.env.REACT_APP_API_BASEURL}proxy.ashx?${new URLSearchParams(queryParams).toString()}`,
    });
    return featureCollection;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const afbrydHoering = async (
  currentUser: Bruger,
  daværendeRessource: Ressource,
  indberetning: Hoeringssag,
  statusId: statusIdEnum
): Promise<void> => {
  try {
    const r = await del<string>({
      query: `stednavn/api/ressourcer/${daværendeRessource.id}?personId=${currentUser.personId}&sagId=${indberetning.id}&statusId=${statusId}`,
      body: daværendeRessource,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const skrivChatBesked = async (
  personId: number,
  sagId: number,
  body: Indlaeg
): Promise<Indlaeg> => {
  try {
    const r = await post<string>({
      query: `stednavn/api/indlaeg/?personId=${personId}&sagId=${sagId}`,
      body: body,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const afslutIndberetning = async (
  kommentar: string,
  currentUser: Bruger,
  id: number,
  indberetning: Hoeringssag
): Promise<void> => {
  try {
    // First post the komment as an indlaeg.
    let indlaeg = {
      id: 0,
      indhold: kommentar,
      organisationForkortelse: "",
      organisationId: 0,
      organisationNavn: "",
      personId: currentUser.personId,
      personNavn: "",
      ts: "",
      typeId: 5,
      typeNavn: "",
    };
    const r1 = skrivChatBesked(currentUser.personId, id, indlaeg);
    const resp1 = JSON.parse(JSON.stringify(r1)); // Gets rid of single-quotes
    if (resp1.errormsg) Promise.reject(resp1);

    // Get the current date in the correct format
    const r = await del<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/Sager/${id}?personId=${currentUser.personId}`,
      body: indberetning,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const opretSag = async (
  user: Bruger,
  indberetning: Hoeringssag
): Promise<Hoeringssag> => {
  try {
    const r = await put<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/hoeringssager/${indberetning.id}?personId=${user.personId}&updateMode=1`,
      body: indberetning,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }

    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const tagIndberetning = async (
  user: Bruger,
  indberetning: Hoeringssag,
  statusId: statusIdEnum
): Promise<Ressource> => {
  try {
    let nyRessource: any = {
      email: "",
      fraTs: null,
      id: 0,
      navn: "",
      organisationId: 0,
      organisationsnavn: "",
      personId: user.personId,
      rolleId: RessourceRolleIdEnum.sagsbehandler,
      tilTs: null,
      "fipbase.model.sag_id": indberetning.id, // TODO: type this?
    };

    const r = await post<string>({
      query: `stednavn/api/ressourcer/?personId=${user.personId}&sagId=${indberetning.id}&statusId=${statusId}`,
      body: nyRessource,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const tagIndberetningFraSagsbehandler = async (
  user: Bruger,
  ressource: Ressource
): Promise<Ressource> => {
  try {
    const r = await put<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/ressourcer/${ressource.id}?personId=${user.personId}`,
      body: ressource,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const redigerIndberetning = async (
  user: Bruger,
  indberetning: Hoeringssag
): Promise<Ressource> => {
  try {
    const r = await put<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/hoeringssager/${indberetning.id}?personId=${user.personId}`,
      body: indberetning,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

const objekttyperCache: any = {};
export const getObjekttyper = async (
  domaeneId: number
): Promise<Objekttype[]> => {
  try {
    if (objekttyperCache[domaeneId.toString()]) {
      return objekttyperCache[domaeneId.toString()];
    } else {
      const objekttyper = await get<{ value: number; text: string }[]>({
        query: `stednavn/api/objekttype?domaeneId=${domaeneId}&page=1&start=0&limit=25`,
      });
      objekttyperCache[domaeneId.toString()] = objekttyper;
      return objekttyper;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
export const redigerAendringsforslag = async (
  currentUser: Bruger,
  aendringTypeId: number,
  aendringType: string,
  id: number,
  beskrivelse: string,
  featureType: string,
  featureTypeId: number,
  geometri: string,
  geometriType: string,
  openLayersId: string
): Promise<Sagsakt> => {
  try {
    const dato: string = formatDateString(
      new Date().toISOString(),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const nytAendringsforslag = {
      aendringType: aendringType,
      aendringTypeId: aendringTypeId,
      beskrivelse: beskrivelse,
      dato: dato,
      id: id,
      geometri: geometri,
      geometriType: geometriType,
      featureType: featureType,
      featureTypeId: featureTypeId,
      openLayersId: openLayersId,
    };
    // Get the current date in the correct format
    const r = await put<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/aendringsforslag/${id}?personId=${currentUser.personId}`,
      body: nytAendringsforslag,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadAendringsforslagGeometri = async (
  currentUser: Bruger,
  aendringTypeId: number,
  aendringType: string,
  beskrivelse: string,
  featureType: string,
  featureTypeId: number,
  geometri: string,
  geometriType: string,
  openLayersId: string,
  mappeId: number
): Promise<Sagsakt> => {
  try {
    const dato: string = formatDateString(
      new Date().toISOString(),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const nytAendringsforslag = {
      aendringType: aendringType,
      aendringTypeId: aendringTypeId,
      beskrivelse: beskrivelse,
      dato: dato,
      id: 0,
      featureType: featureType,
      featureTypeId: featureTypeId,
      geometri: geometri,
      geometriType: geometriType,
      kortforsyningId: "",
      mimetype: "",
      nr: 1,
      openLayersId: openLayersId,
      originalFilNavn: "",
      supplinfo: "",
      udpeget: false,
      url: "",
    };
    // Get the current date in the correct format
    const r = await post<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/aendringsforslag?personId=${currentUser.personId}&mappeId=${mappeId}`,
      body: nytAendringsforslag,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const redigerSagsaktGeometri = async (
  currentUser: Bruger,
  id: number,
  beskrivelse: string,
  geometri: string
): Promise<Sagsakt> => {
  try {
    const dato: string = formatDateString(
      new Date().toISOString(),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const nySagsaktGeometri = {
      beskrivelse: beskrivelse,
      dato: dato,
      id: id,
      geometri: geometri,
    };
    // Get the current date in the correct format
    const r = await put<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/sagsakter/${id}?personId=${currentUser.personId}`,
      body: nySagsaktGeometri,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadSagsaktGeometri = async (
  currentUser: Bruger,
  id: number,
  beskrivelse: string,
  openLayersId: string,
  geometri: string,
  geometriType: string,
  udpeget: boolean,
  mappeId: number
): Promise<Sagsakt> => {
  try {
    const dato: string = formatDateString(
      new Date().toISOString(),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const nySagsakt = {
      beskrivelse: beskrivelse,
      dato: dato,
      id: 0,
      filId: 0,
      openLayersId: openLayersId,
      nr: 0, // TODO: check om dette skal sættes korrekt
      geometri: geometri,
      geometriType: geometriType,
      mimetype: "",
      originalFilNavn: "",
      supplInfo: "",
      udpeget: udpeget,
      url: "",
    };
    // Get the current date in the correct format
    const r = await post<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/sagsakter?personId=${currentUser.personId}&mappeId=${mappeId}`,
      body: nySagsakt,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const uploadFile = async (
  formData: FormData
): Promise<IFileUploadResponse> => {
  try {
    const r = await post<string>({
      query: "upload",
      body: formData,
    });
    const resp = JSON.parse(
      r
        .replace("success", '"success"')
        .replace("id", '"id"')
        .replace("originalFilNavn", '"originalFilNavn"')
        .replace("errormsg", '"errormsg"')
        .replaceAll("'", '"')
    );
    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadSagsaktFil = async (
  currentUser: Bruger,
  beskrivelse: string,
  filId: number,
  geometri: string,
  originalFilNavn: string,
  openLayersId: string,
  geometriType: string,
  udpeget: boolean,
  mappeId: number
): Promise<Sagsakt> => {
  try {
    const dato: string = formatDateString(
      new Date().toISOString(),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const nySagsakt = {
      beskrivelse: beskrivelse,
      dato: dato,
      id: 0,
      filId: filId,
      openLayersId: openLayersId,
      nr: 0, // TODO: check om dette skal sættes korrekt
      geometri: geometri,
      geometriType: geometriType,
      mimetype: "",
      originalFilNavn: originalFilNavn,
      supplInfo: "",
      udpeget: udpeget,
      url: "",
    };
    // Get the current date in the correct format
    const r = await post<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/sagsakter?personId=${currentUser.personId}&mappeId=${mappeId}`,
      body: nySagsakt,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

var personerCache: Objekttype[] = [];
export const getPersoner = async (
  currentUser: Bruger
): Promise<Objekttype[]> => {
  try {
    // If the cache has data, and the currentuser is NOT in the cache, it's fine
    if (
      personerCache.length > 0 &&
      !personerCache.find((p) => p.value === currentUser.personId)
    ) {
      return personerCache;
    } else {
      // Else we need to update the cache
      const personer = await get<{ value: number; text: string }[]>({
        query: `stednavn/api/sagsbehandler?personId=${currentUser.personId}`,
      });
      personerCache = personer;
      return personerCache;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const videresendIndberetning = async (
  personId: number, // person der skal overtage sagen
  currentUser: Ressource // den person der havde sagen før
): Promise<Ressource> => {
  try {
    // Update the personId
    let nyRessource = { ...currentUser, ...{ personId: personId } };

    const r = await put<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/ressourcer/${currentUser.id}?personId=${currentUser.personId}`,
      body: nyRessource,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAendringsforslag = async (
  currentUser: Bruger,
  id: number
): Promise<void> => {
  try {
    // Get the current date in the correct format
    const r = await del<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/aendringsforslag/${id}?personId=${currentUser.personId}`,
      body: { id: id },
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSagsakt = async (
  currentUser: Bruger,
  id: number
): Promise<void> => {
  try {
    // Get the current date in the correct format
    const r = await del<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/sagsakter/${id}?personId=${currentUser.personId}`,
      body: { id: id },
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const redigerSagsakt = async (
  currentUser: Bruger,
  id: number,
  beskrivelse: string,
  openLayersId: string
): Promise<Sagsakt> => {
  try {
    const dato: string = formatDateString(
      new Date().toISOString(),
      "yyyy-MM-dd'T'HH:mm:ss"
    );
    const nySagsakt = {
      beskrivelse: beskrivelse,
      dato: dato,
      id: id,
      openLayersId: openLayersId,
    };
    // Get the current date in the correct format
    const r = await put<string>({
      // apiPrefix: "https://fip.cust.septima.dk/",
      query: `stednavn/api/sagsakter/${id}?personId=${currentUser.personId}`,
      body: nySagsakt,
    });
    const resp = JSON.parse(JSON.stringify(r)); // Gets rid of single-quotes

    if (resp.errormsg) {
      return Promise.reject(resp);
    }
    return resp;
  } catch (error) {
    return Promise.reject(error);
  }
};

/* Is never used in stednavn but satisfies API impl. */
export const opretHoeringssag = async (
  user: Bruger,
  indberetning: Hoeringssag
): Promise<Hoeringssag> => {
  return indberetning;
};
export const sagsbehandlingApi: SagsbehandlingApi = {
  afbrydHoering,
  afslutIndberetning,
  deleteAendringsforslag,
  deleteSagsakt,
  getIndberetning,
  getObjekttyper,
  getPersoner,
  opretHoeringssag,
  redigerAendringsforslag,
  redigerIndberetning,
  redigerSagsakt,
  redigerSagsaktGeometri,
  skrivChatBesked,
  tagIndberetning,
  tagIndberetningFraSagsbehandler,
  uploadAendringsforslagGeometri,
  uploadFile,
  uploadSagsaktFil,
  uploadSagsaktGeometri,
  videresendIndberetning,
};
