import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import PlusCircleFill from "components/icons/PlusCircleFill";
import Select from "../FormElements/Select";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { ModalContext } from "../Modal/Modal";
import { Validation } from "interfaces/IIndberetning";
import ValidationErrorMessage from "../FormElements/ValidationErrorMessage";

export default function AddDrawingModal(props: {
  drawing: Feature<Geometry>;
  hideObjekttype?: boolean;
  hideAendringstype?: boolean;
  submit: (
    aendringTypeId: number,
    aendringType: string,
    drawing: Feature<Geometry>,
    featureTypeId: number,
    featureType: string,
    beskrivelse: string
  ) => void;
  ændringsTyper: { value: number; text: string }[];
  objektTyper: { value: number; text: string }[];
  mappedObjektType?: number;
}) {
  const { closeModal } = useContext(ModalContext);
  const {
    drawing,
    submit,
    hideObjekttype,
    hideAendringstype,
    ændringsTyper,
    objektTyper,
    mappedObjektType,
  } = props;
  const objekttypeOptions = [
    { value: "", label: "Vælg type af objekt", disabled: true },
    ...objektTyper.map((æt) => {
      return { label: æt.text, value: æt.value.toString() };
    }),
  ];
  const defaultObjektType = mappedObjektType
    ? objektTyper.find((a) => a.value === mappedObjektType)
    : undefined;
  const [objekttype, setObjekttype] = useState<
    | undefined
    | {
        value: number;
        text: string;
      }
  >(defaultObjektType ? defaultObjektType : undefined);
  const ændringstypeOptions = [
    { value: "", label: "Vælg ændringstype", disabled: true },
    ...ændringsTyper.map((æt) => {
      return { label: æt.text, value: æt.value.toString() };
    }),
  ];
  const [ændringstype, setÆndringstype] = useState<
    | undefined
    | {
        value: number;
        text: string;
      }
  >(undefined);
  const [kommentar, setKommentar] = useState("");
  const [validation, setValidation] = useState<{
    ændringstype: Validation;
    objekttype: Validation;
  }>({
    objekttype: {
      message: "Objekttype skal være sat",
      touched: false,
      valid: false,
      notify: false,
    },
    ændringstype: {
      message: "Ændringstype skal være sat",
      touched: false,
      valid: false,
      notify: false,
    },
  });
  const resetObjekttypeNotify = () =>
    setValidation({
      ...validation,
      objekttype: { ...validation.objekttype, notify: false },
    });
  const resetÆndringstypeNotify = () =>
    setValidation({
      ...validation,
      ændringstype: { ...validation.ændringstype, notify: false },
    });
  const objekttypeRef = useRef<HTMLSelectElement>(null);
  const ændringstypeRef = useRef<HTMLSelectElement>(null);
  const kommentarRef = useRef<HTMLTextAreaElement>(null);
  const objekttypeDisabled = defaultObjektType ? true : false;
  useLayoutEffect(() => {
    if (!objekttypeDisabled && !hideObjekttype) {
      objekttypeRef.current?.focus();
    } else if (!hideAendringstype) {
      ændringstypeRef.current?.focus();
    } else {
      kommentarRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {!hideObjekttype && (
        <div className="c-select mb-3">
          <Select
            ref={objekttypeRef}
            disabled={objekttypeDisabled}
            className={
              validation.objekttype.touched && !validation.objekttype.valid
                ? "is-invalid"
                : ""
            }
            mandatory={true}
            labelText={"Type"}
            ariaLabel="Vælg type af objekt"
            value={objekttype ? objekttype.value.toString() : ""}
            onChange={(e) => {
              const ot = objektTyper.find(
                (o) => o.value.toString() === e.target.value
              );
              if (ot) {
                setValidation({
                  ...validation,
                  objekttype: { ...validation.objekttype, valid: true },
                });
                setObjekttype(ot);
              }
            }}
            options={objekttypeOptions}
          />
          <ValidationErrorMessage
            show={validation.objekttype.touched && !validation.objekttype.valid}
            message={validation.objekttype.message}
            notify={validation.objekttype.notify}
            resetNotify={resetObjekttypeNotify}
          />
        </div>
      )}
      {!hideAendringstype && (
        <div className="c-select mb-3">
          <Select
            ref={ændringstypeRef}
            className={
              validation.ændringstype.touched && !validation.ændringstype.valid
                ? "is-invalid"
                : ""
            }
            labelText="Ændringsforslag"
            mandatory={true}
            ariaLabel="Vælg type af ændringsforslag"
            value={ændringstype ? ændringstype.value.toString() : ""}
            onChange={(e) => {
              const æt = ændringsTyper.find(
                (æ) => æ.value.toString() === e.target.value
              );
              if (æt) {
                setValidation({
                  ...validation,
                  ændringstype: { ...validation.ændringstype, valid: true },
                });
                setÆndringstype(æt);
              }
            }}
            options={ændringstypeOptions}
          />
          <ValidationErrorMessage
            show={
              validation.ændringstype.touched && !validation.ændringstype.valid
            }
            message={validation.ændringstype.message}
            notify={validation.ændringstype.notify}
            resetNotify={resetÆndringstypeNotify}
          />
        </div>
      )}
      <label htmlFor="drawingKommentar" className="form-label">
        Kommentar
      </label>
      <textarea
        ref={kommentarRef}
        className="form-control"
        id="drawingKommentar"
        value={kommentar}
        onChange={(e) => setKommentar(e.target.value)}
      ></textarea>
      <div className="mt-4 gap-2 d-flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            if (objekttype && ændringstype) {
              submit(
                ændringstype.value,
                ændringstype.text,
                drawing,
                objekttype.value,
                objekttype.text,
                kommentar
              );
              closeModal(false);
            } else if (hideAendringstype && hideObjekttype) {
              submit(0, "", drawing, 0, "", kommentar);
              closeModal(false);
            } else {
              setValidation({
                ændringstype: {
                  ...validation.ændringstype,
                  valid: ændringstype ? true : false,
                  touched: true,
                  notify: true,
                },
                objekttype: {
                  ...validation.objekttype,
                  valid: objekttype ? true : false,
                  touched: true,
                  notify: true,
                },
              });
            }
          }}
        >
          <PlusCircleFill class="me-2" />
          Tilføj
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => {
            closeModal(true);
          }}
        >
          Fortryd
        </button>
      </div>
    </div>
  );
}
