import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { getIndberetning, sagsbehandlingApi } from "api/fip";
import {
  Hoeringssag,
  IndberetningState,
  RessourceRolleIdEnum,
  statusIdEnum,
} from "interfaces/IIndberetning";
import ChevronLeft from "components/icons/ChevronLeftSmall";
import Section from "components/common/Section";
import { Link, useParams } from "react-router-dom";
import { AuthContext, Bruger } from "context/AuthProvider";
import { MapContext } from "context/MapProvider";
import { paths } from "routes/routesConfig";
import { convertWktToFeature, zoomToFeature } from "util/ol";
import { statusStore, tema } from "config/FipConfig";
import { finishedDrawingSource } from "config/interactions";
import { getSagsaktFeaturesFromHoeringssag } from "./fipUtil";
import IndberetningNav from "components/common/Indberetning/IndberetningNav";
import Detaljevisning from "components/common/Indberetning/Detaljevisning";
import Sagsbehandlerfunktioner from "components/common/Indberetning/Fip/Sagsbehandlerfunktioner";
import Hoeringspartfunktioner from "components/common/Indberetning/Fip/Hoeringspartfunktioner";
import { useErrorHandler } from "react-error-boundary";
import Chat from "components/common/Indberetning/Chat/Chat";
import { NewFileState } from "./FipIndberet";
import { SessionTimerContext } from "context/SessionTimerProvider";

export const isIndberetningMine = (
  bruger: Bruger,
  indberetning: Hoeringssag,
  isHoeringssag: boolean
) => {
  let isMine = false;
  const ressource = indberetning.ressource;
  if (ressource) {
    ressource.forEach((r) => {
      if (
        r.rolleId === RessourceRolleIdEnum.sagsbehandler &&
        r.personId === bruger.personId
      ) {
        isMine = true;
      } else if (
        bruger.rolleId === RessourceRolleIdEnum.indberetter &&
        r.rolleId === RessourceRolleIdEnum.indberetter
      ) {
        isMine = true;
      } else if (
        bruger.rolleId === RessourceRolleIdEnum.høringspart &&
        r.rolleId === RessourceRolleIdEnum.høringspart &&
        r.personId === bruger.personId
      ) {
        isMine = true;
      }
    });
  }
  if (!isHoeringssag && bruger.rolleId === RessourceRolleIdEnum.høringspart) {
    isMine = false;
  }

  return isMine;
};

export const isIndberetningAfsluttet = (indberetning: Hoeringssag) => {
  return (
    [statusIdEnum.afsluttet, statusIdEnum.afsluttetGST].indexOf(
      indberetning.statusId
    ) > -1
  );
};

export type PaneState = "sagsdetaljer" | "sagsbehandling" | "hoeringssag";

export default function FipIndberetning() {
  let { id } = useParams<any>();
  const { authState } = useContext(AuthContext);
  const { resetSessionTimer } = useContext(SessionTimerContext);
  const { dispatchToMap, mapState } = useContext(MapContext);
  const { map } = mapState;
  const handleError = useErrorHandler();
  const { currentUser } = authState;
  const [indberetningState, setIndberetningState] = useState<IndberetningState>(
    {
      state: "LOADING",
      indberetning: undefined,
    }
  );
  const [newFile, setNewFile] = useState<NewFileState>({
    state: "IDLE",
    file: undefined,
  });
  const cancelDrawingAndUploadFile = () => {
    setNewFile({ state: "IDLE", file: undefined });
    dispatchToMap({ type: "CANCEL_EDIT_GEOMETRY" });
    dispatchToMap({
      type: "SET_DRAWTOOL",
      drawTool: undefined,
    });
  };
  const indberetningStateHandler = (indberetning: Hoeringssag) => {
    setIndberetningState({
      ...indberetningState,
      indberetning: indberetning,
    });
  };

  const hash = window.location.hash.replace("#", "");
  const [activePaneState, setActivePaneState] = useState<
    "sagsdetaljer" | "sagsbehandling" | "hoeringssag"
  >(
    hash === "sagsbehandling" || hash === "hoeringssag" ? hash : "sagsdetaljer"
  );

  useLayoutEffect(() => {
    window.location.hash = activePaneState;
  }, [activePaneState]);

  const [sagsbehandlingChatScrollPos, setSagsbehandlingChatScrollPos] =
    useState<number | undefined>(undefined);
  const [høringsChatScrollPos, setHøringsChatScrollPos] = useState<
    number | undefined
  >(undefined);
  useEffect(() => {
    setIndberetningState({ indberetning: undefined, state: "LOADING" });
    const hentIndberetning = async () => {
      getIndberetning(id, currentUser.personId)
        .then((response) => {
          const features = getSagsaktFeaturesFromHoeringssag(response);

          features.forEach((feature) => {
            finishedDrawingSource.addFeature(feature);
          });

          setIndberetningState({
            indberetning: response,
            state: "IDLE",
          });
          resetSessionTimer();
        })
        .catch((e) => {
          e.response.status = 404;
          e.message = "Kunne ikke finde indberetning";
          handleError(e);
        });
    };
    hentIndberetning();
  }, [setIndberetningState, id, currentUser, handleError, resetSessionTimer]);

  const didZoomAlready = useRef(false);
  useEffect(() => {
    const { indberetning } = indberetningState;
    if (map && indberetning && indberetning.bbox && !didZoomAlready.current) {
      zoomToFeature(map, convertWktToFeature(indberetning.bbox));
      didZoomAlready.current = true;
    }
  }, [indberetningState, map, activePaneState]);
  useEffect(() => {
    const { indberetning } = indberetningState;

    if (map && indberetningState.state === "IDLE" && indberetning) {
      const indberetningsmappe = indberetning.indberetningsmappe.length
        ? indberetning.indberetningsmappe[0]
        : undefined;
      if (indberetningsmappe) {
        const indberetningTema = tema.find(
          (t) => t.id === indberetningsmappe.korttemaId
        );
        if (indberetningTema) {
          dispatchToMap({ type: "SET_TEMA", tema: indberetningTema });
          dispatchToMap({
            type: "SET_LAYERS",
            baselayers: indberetningTema.baseLayers,
            layers: indberetningTema.layers,
          });
        }
      }

      finishedDrawingSource.clear();
      const features = getSagsaktFeaturesFromHoeringssag(indberetning);
      features.forEach((feature) => {
        finishedDrawingSource.addFeature(feature);
      });
    }
    return () => {
      finishedDrawingSource.clear();
      dispatchToMap({ type: "SET_TEMA", tema: undefined });
    };
  }, [map, indberetningState, dispatchToMap]);
  const titelRef = useRef<HTMLHeadingElement>(null);
  const loadingRef = useRef<HTMLHeadingElement>(null);
  useLayoutEffect(() => {
    if (indberetningState.state === "IDLE") titelRef.current?.focus();
    if (indberetningState.state === "LOADING") loadingRef.current?.focus();
  }, [indberetningState.state]);
  if (indberetningState.state === "LOADING")
    return (
      <div className="mt-5 d-flex text-center align-items-center justify-content-center">
        <div className="text-center">
          <div
            className="spinner-border text-primary"
            style={{ width: "1.5rem", height: "1.5rem" }}
            role="status"
          >
            {/* <span className="visually-hidden">Loading...</span> */}
          </div>
        </div>
        <h1 tabIndex={-1} ref={loadingRef} className="h4 ms-2 mb-0">
          Henter sag ...
        </h1>
      </div>
    );
  if (indberetningState.state === "IDLE" && indberetningState.indberetning) {
    const { indberetning } = indberetningState;
    const {
      ressource,
      hoeringsansvarligmappe: hoeringsansvarligmappeListe,
      hoeringspartmappe: hoeringspartmappeListe,
      indberetningsmappe: indberetningsmappeListe,
      sagsbehandlermappe: sagsbehandlermappeListe,
    } = indberetning;

    const indberettere = ressource?.filter((r) => r.rolleId === 1);
    const indberetter = indberettere?.length > 0 ? indberettere[0] : undefined;

    const sagsbehandlere = ressource?.filter((r) => r.rolleId === 2);
    const sagsbehandler =
      sagsbehandlere?.length > 0 ? sagsbehandlere[0] : undefined;

    return (
      <div className="l-case mt-5">
        <div className="l-case__header">
          <Link
            to={paths.kortIndberetninger}
            className="d-flex align-items-center mb-4"
          >
            <ChevronLeft class="me-2 text-dark" />
            Tilbage til indberetninger
          </Link>
          <h1 tabIndex={-1} ref={titelRef}>
            {indberetning.emne}
          </h1>
          <div className="d-flex gap-1 mb-4">
            <span className="visually-hidden">Status: </span>
            {isIndberetningMine(currentUser, indberetning, false) && (
              <span className="badge bg-primary">
                {currentUser.rolleId === RessourceRolleIdEnum.indberetter
                  ? "Indberettet af dig"
                  : "Din indberetning"}
                <span className="visually-hidden">, </span>
              </span>
            )}
            <span
              className={`badge ${
                statusStore.find((s) => s.id === indberetning.statusId)
                  ?.className
              }`}
            >
              {statusStore.find((s) => s.id === indberetning.statusId)?.name}
            </span>
            {/*<span className="badge bg-horing">I høring</span>
            <span className="badge bg-warning">Afventer sagsbehandling</span> */}
          </div>
        </div>
        {/* Fane Nav */}
        <IndberetningNav
          indberetning={indberetning}
          setActivePaneState={setActivePaneState}
          activePaneState={activePaneState}
        />
        {/* Fane indhold */}
        <div className="tab-content" id="sags-tabContent">
          {/* Detaljevisning */}
          {activePaneState === "sagsdetaljer" &&
            indberetningsmappeListe &&
            indberetningsmappeListe.length > 0 && (
              <Detaljevisning
                indberetter={indberetter}
                sagsbehandler={sagsbehandler}
                indberetning={indberetning}
                zoomToIndberetning={() => {
                  if (map) {
                    zoomToFeature(map, convertWktToFeature(indberetning.bbox));
                  }
                }}
              />
            )}
          {/* Sagsbehandling / Chat mellem sagsbehandler og indberetter */}
          {activePaneState === "sagsbehandling" &&
            ((sagsbehandlermappeListe && sagsbehandlermappeListe.length > 0) ||
              (indberetningsmappeListe &&
                indberetningsmappeListe.length > 0)) && (
              <Chat
                currentUser={currentUser}
                indberetning={indberetning}
                setIndberetning={indberetningStateHandler}
                isHoeringssagChat={false}
                isStednavn={false}
                newFile={newFile}
                setNewFile={setNewFile}
                cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                scrollPos={sagsbehandlingChatScrollPos}
                setScrollPos={setSagsbehandlingChatScrollPos}
                sagsbehandlingApi={sagsbehandlingApi}
              />
            )}
          {activePaneState === "hoeringssag" &&
            hoeringsansvarligmappeListe &&
            hoeringsansvarligmappeListe.length > 0 &&
            ![
              RessourceRolleIdEnum.indberetter,
              RessourceRolleIdEnum.gæst,
            ].includes(currentUser.rolleId) && (
              /*<HoeringssagChat
                indberetning={indberetning}
                setIndberetning={indberetningStateHandler}
              />*/

              <Chat
                currentUser={currentUser}
                indberetning={indberetning}
                setIndberetning={indberetningStateHandler}
                isHoeringssagChat={true}
                isStednavn={false}
                newFile={newFile}
                setNewFile={setNewFile}
                cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                scrollPos={høringsChatScrollPos}
                setScrollPos={setHøringsChatScrollPos}
                sagsbehandlingApi={sagsbehandlingApi}
              />
            )}
          {hoeringspartmappeListe && hoeringspartmappeListe.length > 0 && (
            <div
              className="tab-pane fade"
              id="sags-horingspart"
              role="tabpanel"
              aria-labelledby="sags-horingspart-tab"
            >
              <Section>
                <h2>Høringspartmappe</h2>
                <p>asd</p>
              </Section>
            </div>
          )}
          {/* {sagsbehandler && ( */}
          {/* Skal KUN ses af sagsbehandler */}
          {/* <> */}
          {sagsbehandlermappeListe &&
            sagsbehandlermappeListe.length > 0 &&
            ![
              RessourceRolleIdEnum.indberetter,
              RessourceRolleIdEnum.høringspart,
            ].includes(currentUser.rolleId) && (
              <Sagsbehandlerfunktioner
                currentUser={currentUser}
                indberetning={indberetning}
                setIndberetning={indberetningStateHandler}
                cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
                sagsbehandlingApi={sagsbehandlingApi}
              />
            )}

          {/* Skal KUN ses af Høringspart */}
          {/* <> */}
          {hoeringspartmappeListe &&
            hoeringspartmappeListe.length > 0 &&
            currentUser.rolleId === RessourceRolleIdEnum.høringspart && (
              <Hoeringspartfunktioner
                currentUser={currentUser}
                indberetning={indberetning}
                setIndberetning={indberetningStateHandler}
                cancelDrawingAndUploadFile={cancelDrawingAndUploadFile}
              />
            )}
          {/* </> */}
          {/* )} */}
        </div>
      </div>
    );
  }
  return (
    <div className="mt-5">
      <h1>Noget gik galt ...</h1>
    </div>
  );
}
