import Search from "components/common/Map/Search";
import { SearchResult } from "interfaces/IGeoSearch";
import { Map } from "ol";

export default function FindStednavn(props: {
  map: Map | undefined;
  searchResult: SearchResult | undefined;
  setSearchResult: React.Dispatch<
    React.SetStateAction<SearchResult | undefined>
  >;
  ændringstype: "ændre" | "nedlæg";
}) {
  const { searchResult, setSearchResult, ændringstype, map } = props;
  if (!map) return null;
  return (
    <section className="l-section">
      {/* section head */}
      <h2>Find stednavn</h2>
      <p>
        Søg herunder for at vælge stednavn der skal{" "}
        {ændringstype === "ændre" && "ændres. "}
        {ændringstype === "nedlæg" && "nedlægges. "}
        Hvis stednavnet består af flere ord, skrives de uden mellemrum.
      </p>

      {/* section body */}
      <div className="row g-3 mb-3">
        {!searchResult && (
          <div className="col-md-12">
            <label htmlFor="searchStednavn" className="form-label">
              Find stednavn
            </label>
            <Search
              inputId={"searchStednavn"}
              map={map}
              resources={["stednavne_v3"]}
              placeholder={"Søg stednavn"}
              onResult={(result) => {
                setSearchResult(result);
              }}
              containerClassName={"c-search"}
              inputClassName={"form-control"}
              inputStyle={{ marginBottom: 4 }}
              containerStyle={{
                boxShadow: "unset",
                position: "relative",
              }}
              listItemStyle={{ width: "100%" }}
              listStyle={{
                right: 2,
                left: 2,
              }}
            />
            <span className="small text-muted">
              Fremsøg stednavn der skal{" "}
              {ændringstype === "nedlæg" ? "nedlægges" : "ændres"}
            </span>
          </div>
        )}
        {searchResult && (
          <div className="col-md-12 mt-2">
            <div>
              <div
                className="alert alert-primary bg-transparent border-2 mt-2 mb-1"
                role="alert"
              >
                <p className="text-dark mb-1">
                  <strong>
                    Stednavn der ønskes{" "}
                    {ændringstype === "nedlæg" ? "nedlagt" : "ændret"}:
                  </strong>
                </p>
                <p className="h5 mb-0">{searchResult?.presentationString}</p>
                {/* <button  onClick={() => setSearchResult(undefined)} type="button" className="btn btn-danger text-white mt-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="me-2 rotate-45 bi" width="16" height="16" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path></svg>
                  Fjern valgte
                </button> */}
                <button
                  onClick={() => setSearchResult(undefined)}
                  type="button"
                  className="btn btn-primary text-white mt-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="me-2 bi"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path>
                  </svg>
                  Vælg andet stednavn
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
