import { RessourceRolleIdEnum } from "interfaces/IIndberetning";
import { createContext, useEffect, useState } from "react";
import authApi from "api/auth";
import { useLocation } from "react-router-dom";
export interface AuthState {
  state: "IDLE" | "LOADING" | "ERROR" | "UNAUTHORIZED" | "FORBIDDEN";
  currentUser: Bruger;
}

export interface Bruger {
  navn: string;
  personId: number;
  rolleId: RessourceRolleIdEnum;
  isAuthenticated?: boolean;
  autentifikationId?: any;
  email?: string;
  organisationId?: number;
  organisationNavn?: string;
  senesteLogin?: string;
  authenticationMode?: any;
  loaTooLow?: boolean;
}

export const fipDemoUsers: Bruger[] = [
  {
    personId: 0,
    rolleId: 0,
    navn: "Gæst",
  },
  {
    personId: 0,
    rolleId: 1,
    navn: "Bruger ikke oprettet i FIP",
  },
  {
    personId: 4,
    rolleId: 1,
    navn: "Indb. (Peter Sørensen)",
  },
  {
    personId: 165,
    rolleId: 1,
    navn: "Indb. (Bente Svendsen)",
    loaTooLow: false,
  },
  {
    personId: 1,
    rolleId: 2,
    navn: "Sagsb. (Hans Peter Hansen)",
  },
  {
    personId: 2,
    rolleId: 2,
    navn: "Sagsb. (Sussi Jensen)",
  },
  {
    personId: 3,
    rolleId: 2,
    navn: "Sagsb. (Knud Børge Kvist)",
  },
  {
    personId: 45,
    rolleId: 4,
    navn: "Hør.part (Ib Skov, Brøndby)",
  },
  {
    personId: 155,
    rolleId: 4,
    navn: "Hør.part (Niels Drewes, Brøndby)",
  },
  {
    personId: 115,
    rolleId: 4,
    navn: "Hør.part (Karina Nielsen, Næstved)",
  },
];
export const stednavnDemoUsers: Bruger[] = [
  {
    personId: 0,
    rolleId: 0,
    navn: "Gæst",
  },
  {
    personId: 1,
    rolleId: 2,
    navn: "Sagsb. (Hans Peter Hansen)",
  },
  {
    personId: 2,
    rolleId: 2,
    navn: "Sagsb. (Sussi Jensen)",
  },
  {
    personId: 165,
    rolleId: 1,
    navn: "Indb. (Bente Svendsen)",
  },
  {
    personId: 33,
    rolleId: 1,
    navn: "Indb. (Kurt Hansen)",
  },
  {
    personId: 34,
    rolleId: 1,
    navn: "Indb. aut. (Vera Thomsen)",
  },
];

export const AuthContext = createContext<{
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  setCurrentUserEmail: (email: string) => void;
}>({
  authState: {
    state: "LOADING",
    currentUser: fipDemoUsers[0],
  },
  setAuthState: () => {},
  setCurrentUserEmail: () => {},
});

const AuthProvider = (props: any) => {
  const [authState, setAuthState] = useState<AuthState>({
    state: "LOADING",
    currentUser: fipDemoUsers[0],
  });
  const setCurrentUserEmail = (email: string) => {
    setAuthState({
      ...authState,
      currentUser: { ...authState.currentUser, email },
    });
  };
  const location = useLocation();
  useEffect(() => {
    let stillMounted = true;
    const fetchData = async () => {
      let prefix_path = "";

      if (location.pathname.toLowerCase().includes("/stednavn")) {
        prefix_path = "stednavn/";
      }
      const bruger = await authApi.getMe(
        authState.currentUser.personId,
        prefix_path
      );

      if (stillMounted) {
        setAuthState({
          state: "IDLE",
          currentUser: bruger,
        });
      }
    };
    fetchData();
    // console.log("auth effect", authState.currentUser);
    return () => {
      stillMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ authState, setAuthState, setCurrentUserEmail }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
