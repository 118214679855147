import { format } from "date-fns";
import { da } from "date-fns/locale";

export function formatDateString(
  dateString: string,
  dateformat?: string
): string {
  return format(
    new Date(dateString),
    dateformat ? dateformat : "dd.MM.yyyy HH:mm",
    { locale: da }
  );
}

/**
 *
 * @param dateString string that can be converted to Date using new Date(dateString)
 * @returns dotted datestring on the format dd.MM.yyyy
 */
export function formatDottedDate(dateString: string): string {
  return format(new Date(dateString), "dd.MM.yyyy", { locale: da });
}

/**
 *
 * @param dateString string that can be converted to Date using new Date(dateString)
 * @returns dashed datestring on the format dd-MM-yyyy
 */
export function formatDashedDate(dateString: string): string {
  return format(new Date(dateString), "dd-MM-yyyy", { locale: da });
}
