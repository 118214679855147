import { uniqueId } from "lodash";
import { createContext, useCallback, useReducer } from "react";

export interface Toast {
  content: { header?: string; message: string };
  type?: "default" | "danger";
}
interface IToast extends Toast {
  id: string;
}
interface ToastState {
  toasts: IToast[];
}
type ToastAction =
  | { type: "ADD_TOAST"; toast: IToast }
  | { type: "REMOVE_TOAST"; toast: IToast };
const initialState: ToastState = {
  toasts: [],
};
export const ToastContext = createContext<(t: Toast) => void>(() => {});
const ToastProvider = ({ children }) => {
  const reducer = (state: ToastState, action: ToastAction): ToastState => {
    switch (action.type) {
      case "ADD_TOAST":
        if (
          state.toasts.length &&
          state.toasts[state.toasts.length - 1].content.message ===
            action.toast.content.message
        )
          return state;
        return { ...state, toasts: [...state.toasts, action.toast] };
      case "REMOVE_TOAST":
        return {
          ...state,
          toasts: state.toasts.filter((t) => t !== action.toast),
        };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const toast = useCallback(
    function (t: Toast) {
      const toast = createToastWithId(t);
      dispatch({ type: "ADD_TOAST", toast });
      setTimeout(() => {
        dispatch({ type: "REMOVE_TOAST", toast });
      }, 10000);
    },
    [dispatch]
  );
  return (
    <ToastContext.Provider value={toast}>
      <div className="l-toast" aria-live="assertive">
        {state.toasts.map((toast) => {
          return (
            <ToastComponent
              key={toast.id}
              toast={toast}
              removeToast={() => dispatch({ type: "REMOVE_TOAST", toast })}
            />
          );
        })}
      </div>
      {children}
    </ToastContext.Provider>
  );
};
export default ToastProvider;

const ToastComponent = (props: {
  toast: IToast;
  removeToast: (toast: IToast) => void;
}) => {
  const { toast, removeToast } = props;
  const { content, type } = toast;
  const bgClass = () => {
    switch (type) {
      case "danger":
        return "bg-danger";
      default:
        return "bg-primary";
    }
  };

  return (
    <div
      className={`toast align-items-center text-white border-0 show ${bgClass()}`}
      role="alert"
      aria-atomic="true"
    >
      <div className="d-flex">
        <div className="toast-body">
          {content.header && (
            <strong className="d-block">{content.header}</strong>
          )}
          {content.message}
        </div>
        <button
          type="button"
          className="btn-close btn-close-white me-2 mt-2 ms-auto"
          aria-label="Close"
          onClick={() => {
            removeToast(toast);
          }}
        ></button>
      </div>
    </div>
  );
};

const createToastWithId = (t: Toast): IToast => {
  const toast: IToast = {
    content: t.content,
    type: t.type,
    id: uniqueId("toast"),
  };
  return toast;
};
