export default function DrawPolygon(props: { class?: string }) {
  const classNames = props.class ? `${props.class} bi` : "bi bi-draw-polygon";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="currentColor"
      className={classNames}
    >
      <g
        id="Draw_geometry"
        data-name="Draw geometry"
        transform="translate(2.377 2)"
      >
        <path
          id="Path_10"
          data-name="Path 10"
          d="M206.981,459.889l1.66,17.4,15.579-2.217V461.417Z"
          transform="translate(-205.325 -458.074)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M206.981,459.889l1.66,17.4,15.579-2.217V461.417Z"
          transform="translate(-205.325 -458.074)"
          opacity="0.15"
          stroke="currentColor"
          strokeWidth="2"
        />
        <g
          id="Ellipse_327"
          data-name="Ellipse 327"
          transform="translate(1.023 16.8)"
        >
          <circle cx="2.1" cy="2.1" r="2.1" stroke="none" />
          <circle
            cx="2.1"
            cy="2.1"
            r="3.1"
            fill="none"
            stroke="white"
            opacity="0.15"
            strokeWidth="3"
          />
        </g>
        <g
          id="Ellipse_328"
          data-name="Ellipse 328"
          transform="translate(16.423 14.933)"
        >
          <circle cx="2.1" cy="2.1" r="2.1" stroke="none" />
          <circle
            cx="2.1"
            cy="2.1"
            r="3.1"
            fill="none"
            stroke="white"
            opacity="0.15"
            strokeWidth="3"
          />
        </g>
        <g
          id="Ellipse_329"
          data-name="Ellipse 329"
          transform="translate(16.423 1.4)"
        >
          <circle cx="2.1" cy="2.1" r="2.1" stroke="none" />
          <circle
            cx="2.1"
            cy="2.1"
            r="3.1"
            fill="none"
            stroke="white"
            opacity="0.15"
            strokeWidth="3"
          />
        </g>
        <g
          id="Ellipse_330"
          data-name="Ellipse 330"
          transform="translate(-0.377)"
        >
          <circle cx="1.867" cy="1.867" r="1.867" stroke="none" />
          <circle
            cx="1.867"
            cy="1.867"
            r="2.867"
            fill="none"
            stroke="white"
            opacity="0.15"
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  );
}
