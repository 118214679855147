import { Feature } from "ol";
import Geometry from "ol/geom/Geometry";
import { Drawing } from "routes/Fip/FipIndberet";
import IconDrawPolygon from "components/icons/DrawPolygon";
import Trash from "components/icons/Trash";
import ThreeDotsVertical from "components/icons/ThreeDotsVertical";
import { formatDashedDate, formatDateString } from "util/date";
import Download from "components/icons/Download";
import GeometryAvatar from "../GeometryAvatar";
import { translateGeometryType } from "util/ol";
import { uniqueId } from "lodash";

export default function DrawingListItem(props: {
  type: "compressed" | "normal";
  index?: number;
  hovered: boolean;
  geometri: Drawing;
  hideDeleteOption?: boolean;
  hideEditOptions?: boolean;
  hideOptions?: boolean;
  downloadLink?: string;
  setHoveredFeatures: (ids: string[]) => void;
  zoomToDrawing: (drawing: Feature<Geometry>) => void;
  onEditGeometryClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
}) {
  const {
    hovered,
    geometri,
    hideDeleteOption,
    hideEditOptions,
    hideOptions,
    downloadLink,
    setHoveredFeatures,
    zoomToDrawing,
    onEditClick,
    onDeleteClick,
    onEditGeometryClick,
    index,
    type,
  } = props;
  const id = geometri.drawing.get("id");
  const geometrytype = translateGeometryType(
    geometri.drawing.getGeometry().getType()
  );
  const dropdownMenuButtonId = uniqueId("dropdownMenuButton");

  const renderTexts = () => {
    if (type === "normal") {
      return (
        <>
          <span className="h4 mb-0 name">
            {index !== undefined && (
              <>
                <span aria-label="Id" title="Id">
                  #{index + 1}
                </span>{" "}
              </>
            )}
            {geometri.featureType ? geometri.featureType : ""}
            {geometri.aendringType && (
              <span
                aria-label="Ændringstype"
                title="Ændringstype"
                className="fw-normal text-muted small"
              >
                {" "}
                – {geometri.aendringType}
              </span>
            )}
          </span>
          <p className="small mb-0 fw-semibold text-dark">
            <span aria-hidden>{formatDateString(geometri.dato)}</span>
            <span aria-label="Dato" title="Dato" className="visually-hidden">
              {formatDashedDate(geometri.dato)}
            </span>
          </p>

          <p className="mb-0 ">
            {geometri.beskrivelse.split("\n").map((item, i) => {
              // beskrivelser kommer fra textarea, så kan have linjeskift
              return (
                <span key={i}>
                  {item}
                  <br />
                </span>
              );
            })}
          </p>
        </>
      );
    }
    return (
      <>
        <span className="h4 mb-0 name">{geometrytype}</span>
        {geometri.featureType && geometri.aendringType && (
          <div>
            <p className="mb-0 small fw-semibold text-dark">
              <span title="Type">{geometri.featureType}</span>
              <span aria-hidden="true"> – </span>
              <span title="Ændringstype">{geometri.aendringType}</span>
            </p>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      {geometri.beskrivelse && type === "compressed" && (
        <p className="mb-0 pb-1 mt-1">
          {geometri.beskrivelse.split("\n").map((item, i) => {
            // beskrivelser kommer fra textarea, så kan have linjeskift
            return (
              <span key={i}>
                {item}
                <br />
              </span>
            );
          })}
        </p>
      )}
      <Wrapper
        hovered={hovered}
        type={type === "compressed" ? "div" : "li"}
        onMouseEnter={() => setHoveredFeatures([id])}
        onMouseLeave={() => setHoveredFeatures([])}
      >
        <button
          type="button"
          className={`list-group-item__action ${hovered ? "hover" : ""}`}
          aria-label="Vis i kort"
          title=""
          onClick={() => {
            zoomToDrawing(geometri.drawing);
          }}
        ></button>
        <div className="row align-items-top">
          <div className="col-auto d-none d-md-flex">
            <GeometryAvatar feature={geometri.drawing} />
          </div>
          <div className="col ms-md-n2">{renderTexts()}</div>
          <div className="col-auto">
            {!hideOptions && (
              <div className="dropdown">
                <button
                  className="btn btn-icon btn-outline-primary"
                  aria-label="Funktioner"
                  title="Funktioner"
                  type="button"
                  id={dropdownMenuButtonId}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <ThreeDotsVertical />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby={dropdownMenuButtonId}
                >
                  {!hideEditOptions && (
                    <>
                      <li>
                        <button
                          type="button"
                          className="dropdown-item"
                          aria-label="Rediger"
                          title="Rediger"
                          onClick={onEditClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-fill me-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>
                          Ret oplysninger
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          className="dropdown-item"
                          aria-label="Ret geometri"
                          title="Ret geometri"
                          onClick={onEditGeometryClick}
                        >
                          <IconDrawPolygon class="me-2" />
                          Ret geometri
                        </button>
                      </li>
                    </>
                  )}
                  {!hideDeleteOption && (
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        aria-label="Slet"
                        title="Slet"
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteClick();
                        }}
                      >
                        <Trash class="me-2" />
                        Slet
                      </button>
                    </li>
                  )}
                  {downloadLink !== undefined && (
                    <li>
                      <a
                        href={downloadLink}
                        rel="noreferrer"
                        className="dropdown-item"
                        download
                      >
                        <Download class="me-2" />
                        Gem geometri
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = (props: {
  children: any;
  type: "li" | "div";
  hovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  const { type, children, hovered, onMouseEnter, onMouseLeave } = props;
  const classNames = `list-group-item list-group-item--card p-3 ${
    hovered ? "hover" : ""
  }`;
  if (type === "li") {
    return (
      <li
        className={classNames}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </li>
    );
  }
  return (
    <div
      className={classNames}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
};
