import {
  DomaeneTypeIdEnum,
  Hoeringsmappe,
  Hoeringssag,
  isHoeringssag,
  Ressource,
  RessourceRolleIdEnum,
  statusIdEnum,
} from "interfaces/IIndberetning";
import Section from "../../Section";
import { Fragment, useContext } from "react";
import { Bruger } from "context/AuthProvider";
import { isIndberetningMine } from "routes/Fip/FipIndberetning";
import { ModalContext } from "../../Modal/Modal";
import PromptModal from "../PromptModal";
import RedigerIndberetningModal from "./RedigerIndberetningModal";
import VideresendIndberetningModal from "./VideresendIndberetningModal";
import VideresendIndberetningTilHoeringModal from "./VideresendIndberetningTilHoeringModal";
import RedigerHoeringsFristModal from "./RedigerHoeringsFristModal";
import { baseUrl } from "api";
import { getDownloadGeometryLink } from "routes/Fip/fipUtil";
// import AfslutIndberetning from "./AfslutIndberetningModal";
import AfslutIndberetningModal from "../AfslutIndberetningModal";
import { SagsbehandlingApi } from "api/interfaces";

interface ISagsbehandlerfunktionerProps {
  currentUser: Bruger;
  indberetning: Hoeringssag;
  setIndberetning: Function;
  cancelDrawingAndUploadFile: () => void;
  sagsbehandlingApi: SagsbehandlingApi;
}

export default function Sagsbehandlerfunktioner(
  props: ISagsbehandlerfunktionerProps
) {
  const {
    currentUser,
    indberetning,
    setIndberetning,
    cancelDrawingAndUploadFile,
    sagsbehandlingApi,
  } = props;
  const { showModal } = useContext(ModalContext);
  const { statusId } = indberetning;
  const {
    afbrydHoering,
    afslutIndberetning,
    getIndberetning,
    opretHoeringssag,
    redigerIndberetning,
    tagIndberetning,
    tagIndberetningFraSagsbehandler,
  } = sagsbehandlingApi;
  const indberetningIsAfsluttet =
    statusId === statusIdEnum.afsluttet ? true : false;
  //const indberetningIsHoeringssag =
  //  statusId === statusIdEnum.iHoering ? true : false;
  return (
    <Section className="mt-5">
      <div className="card card--sdfe mt-3 mb-4">
        <div className="card-body">
          <span className="card-title">Sagsbehandlerfunktioner</span>
          <p>Foretag ændringer eller tilføjelser til indberetningen.</p>
          <ul className="list-arrows">
            <li>
              <button
                className="btn btn-link"
                disabled={
                  isIndberetningMine(currentUser, indberetning, false) ||
                  indberetningIsAfsluttet
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Tag indberetning",
                    content: (
                      <PromptModal
                        message={"Ønsker du at påtage dig indberetningen?"}
                        acceptPrompt={() => {
                          // Do stuff that makes you take the indberetning

                          const daværendeRessource =
                            indberetning.ressource?.find(
                              (x) =>
                                x.rolleId === RessourceRolleIdEnum.sagsbehandler
                            );
                          if (daværendeRessource) {
                            daværendeRessource.personId = currentUser.personId;
                            // Prøv kun at sende personId som payload
                            tagIndberetningFraSagsbehandler(
                              currentUser,
                              daværendeRessource
                            ).then((nyRessource: Ressource) => {
                              // Ny sagsbehandler
                              const index = indberetning.ressource.findIndex(
                                (x) =>
                                  x.rolleId ===
                                  RessourceRolleIdEnum.sagsbehandler
                              );
                              let nyIndberetning = indberetning;
                              nyIndberetning.ressource[index] = nyRessource;
                              setIndberetning(nyIndberetning);
                            });
                          } else {
                            tagIndberetning(
                              currentUser,
                              indberetning,
                              statusIdEnum.underBehandling
                            ).then((nyRessource: Ressource) => {
                              // update state with new indberetning
                              getIndberetning(
                                indberetning.id,
                                currentUser.personId
                              ).then((nyIndberetning) => {
                                setIndberetning(nyIndberetning);
                              });
                            });
                          }
                        }}
                      />
                    ),
                  });
                }}
              >
                Tag indberetning
              </button>
            </li>
            <li>
              {
                //TODO: Fix modal callback
              }
              <button
                className="btn btn-link"
                disabled={
                  !isIndberetningMine(currentUser, indberetning, false) ||
                  indberetningIsAfsluttet
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Rediger sagsoplysninger",
                    content: (
                      <RedigerIndberetningModal
                        message={"Rediger sagsoplysninger"}
                        indberetning={indberetning}
                        setIndberetning={setIndberetning}
                        redigerIndberetning={redigerIndberetning}
                      />
                    ),
                  });
                }}
              >
                Rediger indberetning
              </button>
            </li>
            <li>
              {
                //TODO: Fix modal callback
              }
              <button
                className="btn btn-link"
                disabled={
                  !isIndberetningMine(currentUser, indberetning, false) ||
                  indberetningIsAfsluttet
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Videresend sag",
                    content: (
                      <VideresendIndberetningModal
                        message={"Videresend sag"}
                        indberetning={indberetning}
                        setIndberetning={setIndberetning}
                        sagsbehandlingApi={sagsbehandlingApi}
                      />
                    ),
                  });
                }}
              >
                Videresend indberetning
              </button>
            </li>

            {/* Only if sag is a hoeringssag (a geodanmark case) */}
            {/* Also check that the sag is not already a hoeringssag */}
            {indberetning.domaeneTypeId === DomaeneTypeIdEnum.geoDanmark && (
              <Fragment>
                <li>
                  <button
                    className="btn btn-link"
                    disabled={
                      !isIndberetningMine(currentUser, indberetning, false) ||
                      indberetningIsAfsluttet ||
                      isHoeringssag(indberetning)
                    }
                    onClick={() => {
                      cancelDrawingAndUploadFile();
                      showModal({
                        title: "Opret høringssag",
                        content: (
                          <PromptModal
                            message={
                              "Ønsker du at ophøje indberetningen til høringssag?"
                            }
                            acceptPrompt={() => {
                              opretHoeringssag(currentUser, indberetning).then(
                                (nyIndberetning: Hoeringssag) => {
                                  setIndberetning(nyIndberetning);
                                }
                              );
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    Opret høringssag
                  </button>
                </li>
                {indberetning.statusId === statusIdEnum.iHoering && (
                  <li>
                    <button
                      disabled={
                        !isIndberetningMine(currentUser, indberetning, false) ||
                        indberetningIsAfsluttet
                      }
                      className="btn btn-link"
                      onClick={() => {
                        cancelDrawingAndUploadFile();
                        showModal({
                          title: "Afbryd høring",
                          content: (
                            <PromptModal
                              message={"Ønsker du at afbryde høringen?"}
                              acceptPrompt={() => {
                                // Do stuff when you press accept
                                const daværendeRessource =
                                  indberetning.ressource.find(
                                    (x) =>
                                      x.rolleId ===
                                      RessourceRolleIdEnum.høringspart
                                  );
                                if (daværendeRessource) {
                                  // Prøv kun at sende personId som payload
                                  afbrydHoering(
                                    currentUser,
                                    daværendeRessource,
                                    indberetning,
                                    statusIdEnum.underBehandling
                                  ).then(() => {
                                    // Ny indberetning fordi side-effects er svære at regne ud her..
                                    getIndberetning(
                                      indberetning.id,
                                      currentUser.personId
                                    ).then((nyIndberetning) => {
                                      setIndberetning(nyIndberetning);
                                    });
                                  });
                                }
                              }}
                            />
                          ),
                        });
                      }}
                    >
                      Afbryd høring
                    </button>
                  </li>
                )}
                {indberetning.statusId !== statusIdEnum.iHoering && (
                  <li>
                    <button
                      disabled={
                        !isIndberetningMine(currentUser, indberetning, false) ||
                        indberetningIsAfsluttet ||
                        !isHoeringssag(indberetning)
                      }
                      className="btn btn-link"
                      onClick={() => {
                        cancelDrawingAndUploadFile();
                        showModal({
                          title: "Videresend sag til høringspart",
                          content: (
                            <VideresendIndberetningTilHoeringModal
                              currentUser={currentUser}
                              message={"Videresend sag"}
                              indberetning={indberetning}
                              setIndberetning={setIndberetning}
                            />
                          ),
                        });
                      }}
                    >
                      Videresend sag til høringspart
                    </button>
                  </li>
                )}
                {indberetning.domaeneTypeId ===
                  DomaeneTypeIdEnum.geoDanmark && (
                  <li>
                    <button
                      disabled={
                        !isIndberetningMine(currentUser, indberetning, false) ||
                        indberetning.statusId !== statusIdEnum.iHoering ||
                        indberetningIsAfsluttet
                      }
                      className="btn btn-link"
                      onClick={() => {
                        cancelDrawingAndUploadFile();
                        showModal({
                          title: "Rediger høringsoplysninger",
                          content: (
                            <RedigerHoeringsFristModal
                              message={"Rediger høringsoplysninger"}
                              indberetning={indberetning}
                              hoeringspartmappe={
                                indberetning.hoeringspartmappe
                                  ? indberetning.hoeringspartmappe[0]
                                  : ({} as Hoeringsmappe) // TODO: Fix magic typing
                              }
                              setIndberetning={setIndberetning}
                            />
                          ),
                        });
                      }}
                    >
                      Opret/Rediger høringsfrist
                    </button>
                  </li>
                )}
              </Fragment>
            )}

            <li>
              <a
                href={getDownloadGeometryLink(indberetning.id, 0)}
                target="_blank"
                rel="noreferrer"
                className="btn btn-link"
              >
                Download geometrier
                <svg className="btn-link-icon ms-1" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M11.7,15.7c0.2,0.2,0.5,0.2,0.7,0l5.8-5.8C18.5,9.5,18.3,9,17.8,9h-2.4C15.2,9,15,8.8,15,8.5v-5C15,3.2,14.8,3,14.5,3h-5
                    C9.2,3,9,3.2,9,3.5v5C9,8.8,8.8,9,8.5,9H6.2C5.7,9,5.5,9.5,5.8,9.8L11.7,15.7z"/>
                  <path d="M18.7,18C18.7,18,18.7,18,18.7,18l-6.5,0l-6.8,0c0,0,0,0,0,0C5.1,18,5,18.2,5,18.3v1.3C5,19.8,5.2,20,5.4,20l6.8,0h6.5
                    c0.2,0,0.4-0.2,0.4-0.4v-1.3C19,18.2,18.9,18,18.7,18z"/>
                </svg>
              </a>
            </li>

            {/*
              <button
                className="btn btn-link"
                onClick={() => {
                  downloadFiles(currentUser, indberetning).then((data) => {
                    const downloadUrl = window.URL.createObjectURL(
                      new Blob([data])
                    );
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.setAttribute("download", "FIP.zip"); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                  });
                }}
              >
                Download filer
              </button> */}
            <li>
              <a
                href={
                  baseUrl +
                  `download/zip/?sagId=${indberetning.id}&personId=${currentUser.personId}`
                }
                target="_blank"
                rel="noreferrer"
                className="btn btn-link"
              >
                Download filer
                <svg className="btn-link-icon ms-1" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M11.7,15.7c0.2,0.2,0.5,0.2,0.7,0l5.8-5.8C18.5,9.5,18.3,9,17.8,9h-2.4C15.2,9,15,8.8,15,8.5v-5C15,3.2,14.8,3,14.5,3h-5
                    C9.2,3,9,3.2,9,3.5v5C9,8.8,8.8,9,8.5,9H6.2C5.7,9,5.5,9.5,5.8,9.8L11.7,15.7z"/>
                  <path d="M18.7,18C18.7,18,18.7,18,18.7,18l-6.5,0l-6.8,0c0,0,0,0,0,0C5.1,18,5,18.2,5,18.3v1.3C5,19.8,5.2,20,5.4,20l6.8,0h6.5
                    c0.2,0,0.4-0.2,0.4-0.4v-1.3C19,18.2,18.9,18,18.7,18z"/>
                </svg>
              </a>
            </li>
            <li>
              <button
                className="btn btn-link"
                disabled={
                  !isIndberetningMine(currentUser, indberetning, false) ||
                  indberetningIsAfsluttet
                }
                onClick={() => {
                  cancelDrawingAndUploadFile();
                  showModal({
                    title: "Afslut indberetning",
                    content: (
                      <AfslutIndberetningModal
                        afslutIndberetning={(kommentar) => {
                          afslutIndberetning(
                            kommentar,
                            currentUser,
                            indberetning.id,
                            indberetning
                          ).then(() => {
                            // After vi afslutter indberetningen, er det nemmere at hente den ned igen.
                            getIndberetning(
                              indberetning.id,
                              currentUser.personId
                            ).then((nyIndberetning) => {
                              setIndberetning(nyIndberetning);
                            });
                          });
                        }}
                      />
                    ),
                  });
                }}
              >
                Afslut indberetning
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
}
