import ValidationErrorMessage from "components/common/FormElements/ValidationErrorMessage";
import { uniqueId } from "lodash";

export default function Kommentar(props: {
  showValidationWarning: boolean;
  validationMessage: string;
  kommentar: string;
  setKommentar: (string) => void;
  notify: boolean;
  resetNotify: () => void;
}) {
  const {
    showValidationWarning,
    validationMessage,
    kommentar,
    setKommentar,
    notify,
    resetNotify,
  } = props;
  const id = uniqueId("kommentar-input");
  return (
    <div className="c-textarea mb-3">
      <label htmlFor={id} className="form-label">
        Kommentar <span className="text-danger ms-1" aria-label="(Skal udfyldes)" title="Skal udfyldes">
            <svg className="mt-n1" xmlns="http://www.w3.org/2000/svg" width="8" height="8" aria-hidden="true" focusable="false" fill="currentColor" viewBox="0 0 16 16">
              <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"></path>
            </svg>
          </span>
      </label>
      <textarea
        aria-required="true"
        // disabled={disableForm}
        className={
          showValidationWarning ? "form-control is-invalid" : "form-control"
        }
        id={id}
        value={kommentar}
        onChange={(e) => {
          setKommentar(e.target.value);
        }}
      ></textarea>
      <ValidationErrorMessage
        show={showValidationWarning}
        message={validationMessage}
        notify={notify}
        resetNotify={resetNotify}
      />
    </div>
  );
}
