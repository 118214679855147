import { AuthContext } from "context/AuthProvider";
import { Hoeringssag, RessourceRolleIdEnum } from "interfaces/IIndberetning";
import { useContext } from "react";

interface IIndberetningNavProps {
  indberetning: Hoeringssag;
  setActivePaneState: Function;
  activePaneState: "sagsdetaljer" | "sagsbehandling" | "hoeringssag";
}
export default function IndberetningNav(props: IIndberetningNavProps) {
  const { authState } = useContext(AuthContext);
  const { indberetning, setActivePaneState, activePaneState } = props;
  const { currentUser } = authState;
  const {
    hoeringsansvarligmappe: hoeringsansvarligmappeListe,
    indberetningsmappe: indberetningsmappeListe,
    sagsbehandlermappe: sagsbehandlermappeListe,
  } = indberetning;

  const showSagsbehandlingButton =
    ((sagsbehandlermappeListe && sagsbehandlermappeListe.length > 0) ||
      (indberetningsmappeListe && indberetningsmappeListe.length > 0)) &&
    currentUser.rolleId !== RessourceRolleIdEnum.gæst;
  const showHøringssagButton =
    currentUser.rolleId !== RessourceRolleIdEnum.indberetter &&
    hoeringsansvarligmappeListe &&
    hoeringsansvarligmappeListe.length > 0 &&
    ![RessourceRolleIdEnum.indberetter, RessourceRolleIdEnum.gæst].includes(
      currentUser.rolleId
    );
  const showSagsdetaljerButton =
    indberetningsmappeListe &&
    indberetningsmappeListe.length > 0 &&
    showSagsbehandlingButton;
  if (!showSagsdetaljerButton) return null;
  return (
    <nav>
      <div
        className="nav nav--scroll btn-group mb-2"
        id="sags-tab"
        role="tablist"
      >
        <button
          className={`btn btn-outline-primary${
            activePaneState === "sagsdetaljer" ? " active" : ""
          }`}
          type="button"
          role="tab"
          id="sags-indberetningsmappe-tab"
          aria-controls="sags-indberetningsmappe"
          aria-selected="true"
          onClick={() => {
            setActivePaneState("sagsdetaljer");
          }}
        >
          Sagsdetaljer
        </button>
        {showSagsbehandlingButton && (
          <button
            className={`btn btn-outline-primary${
              activePaneState === "sagsbehandling" ? " active" : ""
            }`}
            type="button"
            role="tab"
            id="sags-sagsbehandling-tab"
            aria-controls="sags-sagsbehandling"
            aria-selected="false"
            onClick={() => {
              setActivePaneState("sagsbehandling");
            }}
          >
            Sagsbehandling
          </button>
        )}
        {showHøringssagButton && (
          <button
            className={`btn btn-outline-primary${
              activePaneState === "hoeringssag" ? " active" : ""
            }`}
            type="button"
            role="tab"
            data-bs-toggle="tab"
            id="sags-horing-tab"
            data-bs-target="#sags-horing"
            aria-controls="sags-horing"
            aria-selected="false"
            onClick={() => {
              setActivePaneState("hoeringssag");
            }}
          >
            Høringssag
          </button>
        )}
      </div>
    </nav>
  );
}
