export default function DrawLine(props: { class?: string }) {
  const classNames = props.class ? `${props.class} bi` : "bi bi-draw-line";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.784"
      height="22.338"
      viewBox="0 0 26.784 22.338"
      fill="currentColor"
      stroke="currentColor"
      className={classNames}
    >
      <g id="Draw_line" data-name="Draw line" transform="translate(2.14 -3)">
        <line
          id="Line_4"
          data-name="Line 4"
          y1="13.337"
          x2="17.783"
          transform="translate(2.438 7.501)"
          fill="none"
          strokeWidth="2"
        />
        <g
          id="Ellipse_327"
          data-name="Ellipse 327"
          transform="translate(-0.14 18.337)"
        >
          <circle cx="2.501" cy="2.501" r="2.501" stroke="none" />
          <circle
            cx="2.501"
            cy="2.501"
            r="3.501"
            fill="none"
            stroke="white"
            opacity="0.15"
            strokeWidth="2"
          />
        </g>
        <g
          id="Ellipse_329"
          data-name="Ellipse 329"
          transform="translate(17.642 5)"
        >
          <circle cx="2.501" cy="2.501" r="2.501" stroke="none" />
          <circle
            cx="2.501"
            cy="2.501"
            r="3.501"
            fill="none"
            stroke="white"
            opacity="0.15"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
}
