import { Kategori, KategoriServiceMapning } from "config/StednavnConfig";
import { Hoeringssag } from "interfaces/IIndberetning";
import { convertFeatureToWkt } from "util/ol";
import { NyIndberetningState } from "./StednavnIndberet";
import { CreateStednavnState } from "./StednavnIndberet/CreateStednavn";
import { UpdateStednavnState } from "./StednavnIndberet/UpdateStednavn";

export const mapKategori = (type: string) => {
  const mappedType = KategoriServiceMapning.find((k) => k.label === type);
  const mappedKategori = mappedType
    ? Kategori.find((k) => k.value === mappedType.value)
    : undefined;
  const newKategori = mappedKategori
    ? mappedKategori
    : Kategori[Kategori.length - 1];
  return newKategori;
};

export function prepareCreateStednavnSag(
  indberetning: CreateStednavnState
): Hoeringssag {
  const { beskrivelse, emne, fil, geometri, kategori } = indberetning;
  const requestDTO: Hoeringssag = {
    bbox: "",
    beskrivelse: beskrivelse,
    domaeneType: "",
    domaeneTypeId: 5,
    emne: emne,
    esdhJournalId: "",
    esdhJournalNummer: "",
    fraTs: null,
    frist: null,
    id: 0,
    indberetningsmappe: [
      {
        beskrivelse: "",
        domaeneType: "",
        domaeneTypeId: 5,
        emne: "",
        fejlSet: "",
        fil: fil
          ? [
              {
                beskrivelse: fil.beskrivelse,
                dato: fil.dato,
                filId: fil.filId,
                filreference: "",
                geometri: convertFeatureToWkt(fil.geometri),
                geometriType: fil.geometri.getGeometry().getType(),
                personId: 0,
                personNavn: "",
                id: 0,
                kortforsyningId: "",
                mimetype: "",
                nr: 1,
                openLayersId: "",
                originalFilNavn: "",
                supplInfo: "{}",
                udpeget: false,
                url: "",
              },
            ]
          : [],
        geometri: geometri
          ? [
              {
                aendringType: "Oprettet",
                aendringTypeId: 1,
                beskrivelse: "Stednavn Geometri",
                dato: new Date().toISOString(),
                featureType: kategori?.label ?? "",
                featureTypeId: kategori?.value ?? 0,
                filId: 0,
                filreference: "",
                geometri: convertFeatureToWkt(geometri),
                geometriType: geometri.getGeometry().getType(),
                personId: 0,
                personNavn: "",
                id: 0,
                kortforsyningId: "",
                mimetype: "",
                nr: 1,
                openLayersId: "", // g.drawing.getId().toString(),
                originalFilNavn: "",
                supplInfo: "{}",
                udpeget: false,
                url: "",
              },
            ]
          : [],
        id: 0,
        korttemaId: 1,
      },
    ],
    korrespondance: [],
    oprettetTs: null,
    prioritet: 0,
    ressource: [],
    sagsbehandlermappe: [],
    sagsid: 0,
    sagsnummer: "",
    statusId: 10,
    tilTs: null,
  };
  return requestDTO;
}
export function prepareUpdateStednavnSag(
  indberetning: UpdateStednavnState
): Hoeringssag {
  const { beskrivelse, emne, fil, geometri, kategori } = indberetning;
  const requestDTO: Hoeringssag = {
    bbox: "",
    beskrivelse,
    domaeneType: "",
    domaeneTypeId: 5,
    emne,
    esdhJournalId: "",
    esdhJournalNummer: "",
    fraTs: null,
    frist: null,
    id: 0,
    indberetningsmappe: [
      {
        beskrivelse: "",
        domaeneType: "",
        domaeneTypeId: 5,
        emne: "",
        fejlSet: "",
        fil: fil
          ? [
              {
                beskrivelse: fil.beskrivelse,
                dato: fil.dato,
                filId: fil.filId,
                filreference: "",
                geometri: convertFeatureToWkt(fil.geometri),
                geometriType: fil.geometri.getGeometry().getType(),
                personId: 0,
                personNavn: "",
                id: 0,
                kortforsyningId: "",
                mimetype: "",
                nr: 1,
                openLayersId: "",
                originalFilNavn: "",
                supplInfo: "{}",
                udpeget: false,
                url: "",
              },
            ]
          : [],
        geometri: geometri
          ? [
              {
                aendringType: "Ændret",
                aendringTypeId: 2,
                beskrivelse: "Stednavn Geometri",
                dato: new Date().toISOString(),
                featureType: kategori?.label ?? "",
                featureTypeId: kategori?.value ?? 0,
                filId: 0,
                filreference: "",
                geometri: convertFeatureToWkt(geometri),
                geometriType: geometri.getGeometry().getType(),
                personId: 0,
                personNavn: "",
                id: 0,
                kortforsyningId: "",
                mimetype: "",
                nr: 1,
                openLayersId: "", // g.drawing.getId().toString(),
                originalFilNavn: "",
                supplInfo: "{}",
                udpeget: false,
                url: "",
              },
            ]
          : [],
        id: 0,
        korttemaId: 1,
      },
    ],
    korrespondance: [],
    oprettetTs: null,
    prioritet: 0,
    ressource: [],
    sagsbehandlermappe: [],
    sagsid: 0,
    sagsnummer: "",
    statusId: 10,
    tilTs: null,
  };
  return requestDTO;
}
export function prepareDeleteStednavnSag(
  indberetning: NyIndberetningState
): Hoeringssag {
  const { beskrivelse, emne, fil, geometri } = indberetning;

  const type = geometri?.get("type");
  const kategori = mapKategori(type);
  const requestDTO: Hoeringssag = {
    bbox: "",
    beskrivelse: beskrivelse,
    domaeneType: "",
    domaeneTypeId: 5,
    emne: emne,
    esdhJournalId: "",
    esdhJournalNummer: "",
    fraTs: null,
    frist: null,
    id: 0,
    indberetningsmappe: [
      {
        beskrivelse: "",
        domaeneType: "",
        domaeneTypeId: 5,
        emne: "",
        fejlSet: "",
        fil: fil
          ? [
              {
                beskrivelse: fil.beskrivelse,
                dato: fil.dato,
                filId: fil.filId,
                filreference: "",
                geometri: convertFeatureToWkt(fil.geometri),
                geometriType: fil.geometri.getGeometry().getType(),
                personId: 0,
                personNavn: "",
                id: 0,
                kortforsyningId: "",
                mimetype: "",
                nr: 1,
                openLayersId: "",
                originalFilNavn: "",
                supplInfo: "{}",
                udpeget: false,
                url: "",
              },
            ]
          : [],
        geometri: geometri
          ? [
              {
                aendringType: "Slettet",
                aendringTypeId: 3,
                beskrivelse: "Stednavn Geometri",
                dato: new Date().toISOString(),
                featureType: kategori.label,
                featureTypeId: kategori.value,
                filId: 0,
                filreference: "",
                geometri: convertFeatureToWkt(geometri),
                geometriType: geometri.getGeometry().getType(),
                personId: 0,
                personNavn: "",
                id: 0,
                kortforsyningId: "",
                mimetype: "",
                nr: 1,
                openLayersId: "", // g.drawing.getId().toString(),
                originalFilNavn: "",
                supplInfo: "{}",
                udpeget: false,
                url: "",
              },
            ]
          : [],
        id: 0,
        korttemaId: 1,
      },
    ],
    korrespondance: [],
    oprettetTs: null,
    prioritet: 0,
    ressource: [],
    sagsbehandlermappe: [],
    sagsid: 0,
    sagsnummer: "",
    statusId: 10,
    tilTs: null,
  };
  return requestDTO;
}
