import { get } from "api";
import { Feature } from "ol";
import { gml32Format, wfsFormat } from "util/ol";

export const getGeoDkVectorFeaturesForExtent = async (
  extent: number[],
  type: "Bygning" | "Soe" | "Vandloebsmidte" | "Vejkant" | "Vejmidte"
): Promise<Feature<any>[]> => {
  const queryParams: any = {
    username: process.env.REACT_APP_DAFUSERNAME,
    password: process.env.REACT_APP_DAFPASSWORD,
    service: "WFS",
    request: "getfeature",
    typenames: type,
    Version: "2.0.0",
    BBOX: extent.join(","),
    maxFeatures: 50,
  };
  const features = await get<string>({
    apiPrefix:
      "https://services.datafordeler.dk/GeoDanmarkVektor/GeoDanmark60_NOHIST_GML3/1.0.0/WFS?",
    query: new URLSearchParams(queryParams).toString(),
  });
  return wfsFormat.readFeatures(features);
};

export const getStednavnFeaturesForExtent = async (
  extent: number[]
): Promise<Feature<any>[]> => {
  const queryParams: any = {
    username: process.env.REACT_APP_DAFUSERNAME,
    password: process.env.REACT_APP_DAFPASSWORD,
    service: "WFS",
    request: "getfeature",
    typenames:
      "Bygning,Campingplads,Fortidsminde,Naturareal,Sevaerdighed,Soe,Vandloeb,Vej,Begravelsesplads,FaergeruteLinje,Idraetsanlaeg,Friluftsbad,Lufthavn,Navigationsanlaeg",
    Version: "2.0.0",
    BBOX: extent.join(","),
    maxFeatures: 50,
  };
  const features = await get<string>({
    apiPrefix:
      "https://services.datafordeler.dk/Stednavn/STEDNAVNE_BEARBEJDET_GML3SFP/1/WFS?",
    query: new URLSearchParams(queryParams).toString(),
  });
  return gml32Format.readFeatures(features);
};
